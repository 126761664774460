define("cropster-origin-app/services/origin-settings", ["exports", "cropster-origin-app/services/store", "cropster-origin-app/services/synchronization", "cropster-origin-app/utils/enums", "cropster-origin-app/utils/type-guards"], function (_exports, _store, _synchronization, _enums, _typeGuards) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.batchDivisionTypes = _exports.FORMAT_RATIO = _exports.FORMAT_PERCENTAGE = _exports.DEFAULT_BATCH_DIVISION_WEIGHT = _exports.DEFAULT_BATCH_DIVISION_PERCENTAGE = _exports.DEFAULT_BATCH_DIVISION_NONE = _exports.DEFAULT_BATCH_DIVISION_AUTO = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const FORMAT_RATIO = 'ratio';
  _exports.FORMAT_RATIO = FORMAT_RATIO;
  const FORMAT_PERCENTAGE = 'percentage';
  _exports.FORMAT_PERCENTAGE = FORMAT_PERCENTAGE;
  const DEFAULT_BATCH_DIVISION_NONE = 'none';
  _exports.DEFAULT_BATCH_DIVISION_NONE = DEFAULT_BATCH_DIVISION_NONE;
  const DEFAULT_BATCH_DIVISION_WEIGHT = 'weight';
  _exports.DEFAULT_BATCH_DIVISION_WEIGHT = DEFAULT_BATCH_DIVISION_WEIGHT;
  const DEFAULT_BATCH_DIVISION_PERCENTAGE = 'percentage';
  _exports.DEFAULT_BATCH_DIVISION_PERCENTAGE = DEFAULT_BATCH_DIVISION_PERCENTAGE;
  const DEFAULT_BATCH_DIVISION_AUTO = 'autocalculated';
  _exports.DEFAULT_BATCH_DIVISION_AUTO = DEFAULT_BATCH_DIVISION_AUTO;
  const batchDivisionTypes = [DEFAULT_BATCH_DIVISION_WEIGHT, DEFAULT_BATCH_DIVISION_PERCENTAGE, DEFAULT_BATCH_DIVISION_AUTO];
  _exports.batchDivisionTypes = batchDivisionTypes;
  const GROUP_PROPERTY_KEY = 'group.originSettings';
  const WEIGHT_UNIT_KEY = 'group.weightunit';
  const CURRENCY_KEY = 'group.currency';
  const STORAGE_KEY_USER_SETTINGS = '__coa_usk__';
  const DEFAULT_WEIGHT_UNIT = _enums.WEIGHT_UNIT_KG;
  const DEFAULT_CURRENCY = _enums.CURRENCY_USD;
  const DEFAULT_USER_SETTINGS = {
    sorting: {
      field: _enums.FIELD_IDTAG,
      direction: _store.SORT_DIRECTION_DESC
    },
    viewFields: Object.assign({}, ..._enums.FIELD_OPTIONS.map(_ref => {
      let {
        value
      } = _ref;
      return {
        [value]: true
      };
    })),
    editFields: Object.assign({}, ..._enums.FIELD_OPTIONS.map(_ref2 => {
      let {
        value
      } = _ref2;
      return {
        [value]: true
      };
    })),
    defaultValues: {
      contactRoleName: _enums.CONTACT_ROLE_COMMUNITY,
      coffeeForm: _enums.COFFEE_FORM_CHERRY,
      weightUnit: DEFAULT_WEIGHT_UNIT
    }
  };
  const CURRENT_YEAR = new Date().getFullYear();
  const DEFAULT_GROUP_PROPERTY = {
    type: 'property',
    attributes: {
      name: GROUP_PROPERTY_KEY,
      value: {
        cropYear: `${CURRENT_YEAR}/${CURRENT_YEAR + 1}`,
        yieldFormat: FORMAT_RATIO,
        errorTolerance: 5
      }
    },
    relationships: {
      group: {
        data: {
          type: 'group',
          id: null
        }
      }
    }
  };

  /*
   * Stateful service holding current origin settings.
   *
   * - Includes group's origin property `group.originSettings` (remote)
   * - Includes group's weight property `group.weightunit` (remote)
   * - Includes group's currency property `group.currency` (remote)
   * - Includes user settings for origin app (local):
   *     - batch count for batch creation
   *     - sort settings for batch list
   *     - view fields for batch list
   *     - edit fields for batch form
   *     - defaults for batch form
   *
   * Only user settings are writable, properties are read only.
   */
  let OriginSettingsService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.computed('_groupProperty.value.cropYear'), _dec8 = Ember.computed('_groupProperty.value.defaultBatchDivisions', 'defaultCurrencyUnit'), _dec9 = Ember.computed('_groupProperty.value.isBatchDividedPerSupplier'), _dec10 = Ember.computed('_groupProperty.value.isBatchDivided'), _dec11 = Ember.computed('_groupProperty.value.defaultBatchDivisionType'), _dec12 = Ember.computed('_groupProperty.value.defaultReceivedUnit'), _dec13 = Ember.computed('_groupProperty.value.defaultCurrency'), _dec14 = Ember.computed.readOnly('_groupProperty.value.yieldFormat'), _dec15 = Ember.computed.readOnly('_groupProperty.value.errorTolerance'), _dec16 = Ember.computed('_userSettings.viewFields'), _dec17 = Ember.computed('_userSettings.editFields'), _dec18 = Ember.computed('_userSettings.defaultValues'), _dec19 = Ember.computed('_userSettings.sorting.field'), _dec20 = Ember.computed('_userSettings.sorting.direction'), _dec21 = Ember.computed.readOnly('_weightUnit'), _dec22 = Ember.computed.readOnly('_currency'), (_class = class OriginSettingsService extends Ember.Service {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "storage", _descriptor2, this);
      _initializerDefineProperty(this, "activeUser", _descriptor3, this);
      _initializerDefineProperty(this, "activeGroup", _descriptor4, this);
      _initializerDefineProperty(this, "memorySource", _descriptor5, this);
      _initializerDefineProperty(this, "synchronization", _descriptor6, this);
      _initializerDefineProperty(this, "yieldFormat", _descriptor7, this);
      _initializerDefineProperty(this, "errorTolerance", _descriptor8, this);
      _initializerDefineProperty(this, "weightUnit", _descriptor9, this);
      _initializerDefineProperty(this, "currency", _descriptor10, this);
      _defineProperty(this, "_groupProperty", DEFAULT_GROUP_PROPERTY);
      _defineProperty(this, "_userSettings", DEFAULT_USER_SETTINGS);
      _defineProperty(this, "_weightUnit", DEFAULT_WEIGHT_UNIT);
      _defineProperty(this, "_currency", DEFAULT_CURRENCY);
    }
    //
    // Properties
    //
    get cropYear() {
      const cropYear = Ember.get(this, '_groupProperty.value.cropYear');
      if ((0, _typeGuards.isString)(cropYear)) {
        return cropYear;
      }
      const date = new Date();
      return `${date.getFullYear()}/${date.getFullYear() + 1}`;
    }
    get defaultBatchDivisions() {
      const defaultBatchDivisions = Ember.get(this, '_groupProperty.value.defaultBatchDivisions');
      /**
       * Unfortunately some customers have very old cached versions of origin-settings
       * Wherein the defaultBatchDivisions array could contain undefined values
       * It was also possible that defaultBatchDivisions.@each.pricePerUnit.unit could have been a
       * measure object, when a string is needed.
       */
      return (defaultBatchDivisions || []).filter(defaultDivision => defaultDivision).map(division => ({
        ...division,
        percentage: {
          unit: 'PERCENT',
          amount: division.percentage?.amount ?? 0
        },
        pricePerUnit: (0, _typeGuards.isMeasure)(division.pricePerUnit) ? division.pricePerUnit : {
          amount: 0,
          unit: this.defaultCurrencyUnit
        }
      }));
    }
    get isBatchDividedPerSupplier() {
      const isBatchDividedPerSupplier = Ember.get(this, '_groupProperty.value.isBatchDividedPerSupplier');
      return isBatchDividedPerSupplier ?? false;
    }
    get isBatchDivided() {
      const isBatchDivided = Ember.get(this, '_groupPropertyValue.isBatchDivided');
      const defaultBatchDivisionType = Ember.get(this, '_groupPropertyValue.defaultBatchDivisionType');
      if (defaultBatchDivisionType !== DEFAULT_BATCH_DIVISION_NONE) {
        return true;
      }
      return isBatchDivided ?? false;
    }
    get defaultBatchDivisionType() {
      const defaultBatchDivisionType = Ember.get(this, '_groupProperty.value.defaultBatchDivisionType');
      return batchDivisionTypes.includes(defaultBatchDivisionType) ? defaultBatchDivisionType : DEFAULT_BATCH_DIVISION_WEIGHT;
    }
    get defaultReceivedUnit() {
      const defaultReceivedUnit = Ember.get(this, '_groupProperty.value.defaultReceivedUnit');
      return defaultReceivedUnit?.unit ?? this.weightUnit;
    }
    get defaultCurrencyUnit() {
      const defaultCurrency = Ember.get(this, '_groupProperty.value.defaultCurrency');
      return defaultCurrency?.unit ?? this.currency;
    }
    get viewFields() {
      return this._userSettings.viewFields || {};
    }
    set viewFields(value) {
      (false && !(typeof value === 'object' && value !== null) && Ember.assert(`Invalid 'viewFields' set: ${value}`, typeof value === 'object' && value !== null));
      return Ember.set(this, '_userSettings.viewFields', {
        ...value
      });
    }
    get editFields() {
      return this._userSettings.editFields || {};
    }
    set editFields(value) {
      (false && !(typeof value === 'object' && value !== null) && Ember.assert(`Invalid 'editFields' set: ${value}`, typeof value === 'object' && value !== null));
      return Ember.set(this, '_userSettings.editFields', {
        ...value
      });
    }
    get defaultValues() {
      return this._userSettings.defaultValues || {};
    }
    set defaultValues(value) {
      (false && !(typeof value === 'object' && value !== null) && Ember.assert(`Invalid 'defaultValues' set: ${value}`, typeof value === 'object' && value !== null));
      return Ember.set(this, '_userSettings.defaultValues', {
        ...value
      });
    }
    get sortField() {
      return this._userSettings.sorting.field;
    }
    set sortField(value) {
      (false && !(typeof value === 'string') && Ember.assert(`Invalid 'sortField' set: ${value}`, typeof value === 'string'));
      return Ember.set(this, '_userSettings.sorting.field', value);
    }
    get sortDirection() {
      return this._userSettings.sorting.direction;
    }
    set sortDirection(value) {
      (false && !(typeof value === 'string') && Ember.assert(`Invalid 'sortDirection' set: ${value}`, typeof value === 'string'));
      return Ember.set(this, '_userSettings.sorting.direction', value);
    }
    //
    // Methods
    //
    async activate() {
      Ember.addListener(this.synchronization, _synchronization.EVENT_PULLED_GROUP_DATA, this, this.load);
    }
    async deactivate() {
      try {
        Ember.removeListener(this.synchronization, _synchronization.EVENT_PULLED_GROUP_DATA, this, this.load);
      } catch (e) {
        // noop
      }
    }
    async load() {
      if (this.activeGroup.id !== null) {
        await this._initGroupProperty();
        await this._initWeightUnit();
        await this._initCurrency();
      }
      await this._initUserSettings();
    }
    async saveUserSettings() {
      await this.storage.setItem(STORAGE_KEY_USER_SETTINGS, this._userSettings);
    }
    async _initUserSettings() {
      const settings = await this.storage.getItem(STORAGE_KEY_USER_SETTINGS);
      if (typeof settings !== 'object' || settings === null) {
        return;
      }
      Ember.set(this, '_userSettings', {
        ...settings
      });
    }
    async _initGroupProperty() {
      const [groupProperty] = await this._filterProperty(GROUP_PROPERTY_KEY);
      Ember.set(this, '_groupProperty', groupProperty);
    }
    async _initWeightUnit() {
      const [{
        value: weightUnit = DEFAULT_WEIGHT_UNIT
      } = {}] = await this._filterProperty(WEIGHT_UNIT_KEY);
      Ember.set(this, '_weightUnit', weightUnit);
    }
    async _initCurrency() {
      const [{
        value: currency = DEFAULT_CURRENCY
      } = {}] = await this._filterProperty(CURRENCY_KEY);
      Ember.set(this, '_currency', currency);
    }
    async _filterProperty(name) {
      const {
        id: groupId
      } = this.activeGroup;
      return await this.store.findRecords(this.memorySource, 'property', {
        filter: [{
          attribute: 'name',
          value: name
        }, {
          relation: 'group',
          record: {
            type: 'group',
            id: groupId
          }
        }]
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "storage", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "activeUser", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "activeGroup", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "memorySource", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "synchronization", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "cropYear", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "cropYear"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "defaultBatchDivisions", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "defaultBatchDivisions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isBatchDividedPerSupplier", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "isBatchDividedPerSupplier"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isBatchDivided", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "isBatchDivided"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "defaultBatchDivisionType", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "defaultBatchDivisionType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "defaultReceivedUnit", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "defaultReceivedUnit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "defaultCurrencyUnit", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "defaultCurrencyUnit"), _class.prototype), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "yieldFormat", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "errorTolerance", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "viewFields", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "viewFields"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editFields", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "editFields"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "defaultValues", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "defaultValues"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortField", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "sortField"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortDirection", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "sortDirection"), _class.prototype), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "weightUnit", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "currency", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = OriginSettingsService;
});