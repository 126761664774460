define("cropster-origin-app/orbit/sources/remote/json-api-url-builder", ["exports", "@orbit/jsonapi"], function (_exports, _jsonapi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //
  // Custom url builder changing the following default behavior:
  //
  // - build special `filter` params for custom filter handling on API (f.e. `?filter[owner][relation][attr]=value`)
  // - build special `sort` params for custom sort handling on API (f.e. `?sort[owner][relation][attr]=value`)
  // - build special `page` params for custom page handling on API (f.e. `?page[number]=value`)
  // - build special `include` params for custom include handling on API (f.e. `?include[owner]=value`)
  //
  // To provide parameters like `filter`, `include`, `sort` and `page` there's some customization needed:
  //
  // - Property paths or custom filter names: To resolve a path required by API use ':' to split path:
  //
  // `filter({'groups:userName': 'user@domain.com' })` -> `filter[groups][userName]=user@domain.com`
  //
  // - Includes: To provide path and assign include correctly use '=' to split include:
  //
  // `include: ['groups=projects']` -> `include[groups]=projects`
  //
  // @see https://github.com/orbitjs/orbit/blob/master/packages/@orbit/jsonapi/src/jsonapi-url-builder.ts
  //
  class CustomJSONAPIURLBuilder extends _jsonapi.JSONAPIURLBuilder {
    appendQueryParams(url, params) {
      if (params) {
        let result = [];
        for (let key in params) {
          switch (key) {
            case 'filter':
              {
                // f.e. `[{'path:to:prop': 'value'}]` -> ['filter[path][to][prop]=value']
                let items = this._transformArrayToArrayOfParams(params[key]).map(param => `${this._encodeQueryParamString(key)}${param}`);
                result.push(...items);
                break;
              }
            case 'include':
              {
                // f.e. `owner=relation1,owner=relation2` -> ['include[owner]=relation1', 'include[owner]=relation2']
                let array = this._transformStringToArrayOfObjects(params[key]);
                let items = this._transformArrayToArrayOfParams(array).map(param => `${this._encodeQueryParamString(key)}${param}`);
                result.push(...items);
                break;
              }
            case 'page':
              {
                // f.e. `{ offset: 1, limit: 9999 }` -> ['page[number]=1', 'page[size]=9999']
                let {
                  offset: number = 0,
                  limit: size = 50
                } = typeof params[key] === 'object' && params[key] !== null ? params[key] : {};
                let items = this._transformArrayToArrayOfParams([{
                  number,
                  size
                }]).map(param => `${this._encodeQueryParamString(key)}${param}`);
                result.push(...items);
                break;
              }
            case 'sort':
              {
                // f.e. `[{'path:to:prop': 'descending'}]` -> ['sort[path][to][prop]=desc']
                let array = this._transformStringToArrayOfObjects(params[key], key => {
                  // 1. remove starting dash `-` for `descending` order
                  // 2. map order to our internal API representation:
                  // `descending` -> `desc` // `ascending` -> `asc`
                  const regex = /^-/;
                  return [key.replace(regex, ''), key.match(regex) ? 'desc' : 'asc'];
                });
                let items = this._transformArrayToArrayOfParams(array).map(param => `${this._encodeQueryParamString(key)}${param}`);
                result.push(...items);
                break;
              }
            default:
              {
                throw new Error(`json-api-url-builder.js: Unknown param type: ${key}!`);
              }
          }
        }
        url = `${url}?${result.join('&')}`;
      }
      return url;
    }
    _transformStringToArrayOfObjects(string, preProcessKeyValue) {
      // f.e. `path1=include1,path2=include1` -> [{ path1: `include1`}, { path2: `include2`}]
      let result = [];
      for (let item of string.split(',')) {
        let parts = item.split('=');
        let [key, value] = typeof preProcessKeyValue === 'function' ? preProcessKeyValue.apply(null, parts) : parts;
        result.push({
          [key]: value
        });
      }
      return result;
    }
    _transformArrayToArrayOfParams(array, preProcessKeyValue) {
      // f.e. `path:to:property=val` -> [path][to][property]=val
      let result = [];
      for (let item of array) {
        for (let key in item) {
          let [name, value] = typeof preProcessKeyValue === 'function' ? preProcessKeyValue.apply(null, [key, item[key]]) : [key, item[key]];
          name = this._encodeQueryParamString(`[${key.split(':').join('][')}]`);
          value = this._encodeQueryParamString(item[key]);
          result.push(`${name}=${value}`);
        }
      }
      return result;
    }
    _encodeQueryParamString(string) {
      return encodeURIComponent(string);
    }
  }
  var _default = CustomJSONAPIURLBuilder;
  _exports.default = _default;
});