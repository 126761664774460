define("cropster-origin-app/components/logs/log/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class Log extends Ember.Component {
    constructor() {
      super(...arguments);
      //
      // Arguments
      //
      _defineProperty(this, "log", null);
      _defineProperty(this, "isOpen", false);
      _defineProperty(this, "onToggle", ( /* log */) => {});
    }
    //
    // Hooks
    //
    init() {
      super.init(...arguments);
      (false && !(typeof this.log === 'object' && this.log !== null && typeof this.log.meta === 'object' && this.log.meta !== null) && Ember.assert('`log` must be a log object!', typeof this.log === 'object' && this.log !== null && typeof this.log.meta === 'object' && this.log.meta !== null));
    }

    //
    // Actions
    //
    async click() {
      this.onToggle(this.log);
    }
  }
  _exports.default = Log;
});