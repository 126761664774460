define("cropster-origin-app/orbit/strategies/sync-memory-to-backup", ["exports", "@orbit/coordinator"], function (_exports, _coordinator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const syncMemoryToBackupStrategy = new _coordinator.SyncStrategy({
    source: 'memory',
    target: 'backup',
    blocking: true
  });
  var _default = syncMemoryToBackupStrategy;
  _exports.default = _default;
});