define("cropster-origin-app/error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "YSn9TWpt",
    "block": "{\"symbols\":[],\"statements\":[[5,\"layout/header\",[],[[\"@title\",\"@onGoBack\"],[[28,\"t\",[\"Error\"],null],[28,\"action\",[[23,0,[]],[23,0,[\"goToIndex\"]]],null]]]],[0,\"\\n\\n\"],[5,\"layout/wrapper\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"margin-bottom-3\"],[8],[0,\"\\n    \"],[5,\"info-message\",[],[[\"@type\",\"@title\"],[\"error\",[28,\"t\",[\"An error occured\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,0,[\"isError\"]]],null,{\"statements\":[[0,\"        \"],[1,[23,0,[\"error\",\"message\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[28,\"t\",[\"Sorry, but this page does not exist.\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[5,\"c-button\",[],[[\"@type\",\"@label\",\"@action\"],[\"plain\",[28,\"t\",[\"Go back\"],null],[28,\"action\",[[23,0,[]],[23,0,[\"goToIndex\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-origin-app/error/template.hbs"
    }
  });
  _exports.default = _default;
});