define("cropster-origin-app/application/route", ["exports", "cropster-origin-app/services/auth"], function (_exports, _auth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const EVENT_ROUTE_DID_CHANGE = 'routeDidChange';

  //
  // Application route takes care of wiring up services (orbit, keen, raven) and handles all
  // authentication. It holds current user token as its model as an indicator if there's an
  // active user session. You may also use auth's `getToken()` method for this.
  //
  let ApplicationRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, (_class = class ApplicationRoute extends Ember.Route {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "auth", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "orbitFacade", _descriptor3, this);
      _initializerDefineProperty(this, "datadogLogger", _descriptor4, this);
      _initializerDefineProperty(this, "serviceWorkerUpdateNotify", _descriptor5, this);
    }
    //
    // Hooks
    //
    async activate() {
      super.activate();

      // we need to trigger model hooks again after successful authentication
      // or invalidation of user session to instruct orbit.js with token data
      Ember.addListener(this.auth, _auth.EVENT_INVALIDATED, this, this.refresh);
      Ember.addListener(this.auth, _auth.EVENT_AUTHENTICATED, this, this.refresh);

      // listen for a new application version from the service worker service
      // note: this service is provided by `ember-service-worker-update-notify`
      Ember.addListener(this.serviceWorkerUpdateNotify, 'update', this, this._serviceWorkerUpdateNotifyHandler);

      // scroll back to top after user has transitioned to a new page
      Ember.addListener(this.router, EVENT_ROUTE_DID_CHANGE, this, this._routeDidChangeHandler);
    }
    async beforeModel() {
      // check if we can connect to IndexedDB (may not work in private mode
      // when using FF: https://bugzilla.mozilla.org/show_bug.cgi?id=781982)
      const isSupported = await this.orbitFacade.isSupported();
      if (!isSupported) {
        this.transitionTo('unsupported');
      }
    }
    async model() {
      // if there's an auth token in storage, user has signed in once
      // looking up auth token DOES NOT invoke a network request, but
      // subroutes can work with app application model to check status
      return await this.auth.getToken();
    }
    async afterModel(token) {
      // if there's token information available, enable data layer and
      // all corresponding work necessary by insturmenting the facade
      if (typeof token === 'string') {
        return await this.orbitFacade.activate();
      } else {
        return await this.orbitFacade.deactivate();
      }
    }
    deactivate() {
      super.deactivate();
      Ember.removeListener(this.auth, _auth.EVENT_INVALIDATED, this, this.refresh);
      Ember.removeListener(this.auth, _auth.EVENT_AUTHENTICATED, this, this.refresh);
      Ember.removeListener(this.router, EVENT_ROUTE_DID_CHANGE, this, this._routeDidChangeHandler);
      Ember.removeListener(this.serviceWorkerUpdateNotify, 'update', this, this._serviceWorkerUpdateNotifyHandler);
    }

    //
    // Methods
    //
    _serviceWorkerUpdateNotifyHandler() {
      window.location.reload();
    }
    _routeDidChangeHandler() {
      window.scrollTo(0, 0);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "auth", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "orbitFacade", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "datadogLogger", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "serviceWorkerUpdateNotify", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ApplicationRoute;
});