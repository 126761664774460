define("cropster-origin-app/orbit/schemas/reception", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    keys: {
      remoteId: {}
    },
    attributes: {
      receptionDate: {
        type: 'date'
      },
      coffeeForm: {
        type: 'string'
      }
    },
    relationships: {
      group: {
        type: 'hasOne',
        model: 'group'
      },
      facility: {
        type: 'hasOne',
        model: 'facility'
      },
      batches: {
        type: 'hasMany',
        model: 'batch',
        inverse: 'reception',
        serializationOptions: {
          ignore: true
        }
      },
      receptionItems: {
        type: 'hasMany',
        model: 'receptionItem',
        inverse: 'reception',
        serializationOptions: {
          ignore: true
        }
      }
    }
  };
  _exports.default = _default;
});