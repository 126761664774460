define("cropster-origin-app/components/batches/batch/component", ["exports", "cropster-origin-app/utils/enums", "cropster-origin-app/models/abstract", "cropster-origin-app/services/store"], function (_exports, _enums, _abstract, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let Batch = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed.readOnly('originSettings.viewFields'), _dec5 = Ember.computed('batch', 'batch.reception'), _dec6 = Ember.computed.mapBy('batch.varieties', 'name'), _dec7 = Ember.computed.mapBy('batch.reception.receptionItems', 'source'), _dec8 = Ember.computed.mapBy('mappedSources', 'name'), _dec9 = Ember.computed('batch'), _dec10 = Ember.computed('fields'), _dec11 = Ember.computed('fields'), _dec12 = Ember.computed('fields'), _dec13 = Ember.computed('fields'), _dec14 = Ember.computed('fields'), _dec15 = Ember.computed('fields'), _dec16 = Ember.computed('fields'), _dec17 = Ember.computed('fields'), _dec18 = Ember.computed('fields'), _dec19 = Ember.computed('fields'), (_class = class Batch extends Ember.Component {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "originSettings", _descriptor2, this);
      _initializerDefineProperty(this, "synchronization", _descriptor3, this);
      //
      // Arguments
      //
      _defineProperty(this, "batch", null);
      //
      // Properties
      //
      _defineProperty(this, "dateFormat", 'L');
      _initializerDefineProperty(this, "fields", _descriptor4, this);
      _initializerDefineProperty(this, "mappedVarieties", _descriptor5, this);
      _initializerDefineProperty(this, "mappedSources", _descriptor6, this);
      _initializerDefineProperty(this, "mappedSourceNames", _descriptor7, this);
    }
    get canEdit() {
      return (0, _store.isModel)(this.batch) && (0, _store.isModel)(this.batch.reception) && !this.synchronization.isModelSynced(this.batch) && !this.synchronization.isModelSynced(this.batch.reception);
    }
    get isNotSynced() {
      return !this.synchronization.isModelSynced(this.batch);
    }
    get showReceptionDate() {
      return this.fields[_enums.FIELD_RECEPTION_DATE];
    }
    get showStorageDate() {
      return this.fields[_enums.FIELD_STORED_DATE];
    }
    get showProcess() {
      return this.fields[_enums.FIELD_PROCESS];
    }
    get showReceivedWeight() {
      return this.fields[_enums.FIELD_RECEIVED_WEIGHT];
    }
    get showStoredWeight() {
      return this.fields[_enums.FIELD_STORED_WEIGHT];
    }
    get showVarieties() {
      return this.fields[_enums.FIELD_VARIETY];
    }
    get showPicker() {
      return this.fields[_enums.FIELD_PICKER];
    }
    get showCommunity() {
      return this.fields[_enums.FIELD_COMMUNITY];
    }
    get showFarm() {
      return this.fields[_enums.FIELD_FARM];
    }
    get showField() {
      return this.fields[_enums.FIELD_FIELD];
    }

    //
    // Hooks
    //
    init() {
      super.init(...arguments);
      (false && !(this.batch instanceof _abstract.default) && Ember.assert('`batch` must be an instance of `Model`!', this.batch instanceof _abstract.default));
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "originSettings", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "synchronization", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "fields", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "canEdit", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "canEdit"), _class.prototype), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "mappedVarieties", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "mappedSources", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "mappedSourceNames", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isNotSynced", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "isNotSynced"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showReceptionDate", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "showReceptionDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showStorageDate", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "showStorageDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showProcess", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "showProcess"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showReceivedWeight", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "showReceivedWeight"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showStoredWeight", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "showStoredWeight"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showVarieties", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "showVarieties"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showPicker", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "showPicker"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showCommunity", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "showCommunity"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showFarm", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "showFarm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showField", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "showField"), _class.prototype)), _class));
  _exports.default = Batch;
});