define("cropster-origin-app/components/form/radio-group/component", ["exports", "cropster-origin-app/components/form/abstract/select/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let RadioGroup = (_dec = Ember.computed('elementId'), _dec2 = Ember._action, (_class = class RadioGroup extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "classNameBindings", ['isVertical::display-flex']);
      _defineProperty(this, "classNames", ['simple-gap-2']);
      //
      // Arguments
      //
      _defineProperty(this, "disabled", false);
      _defineProperty(this, "isVertical", true);
    }
    onChange() {}

    //
    // Properties
    //
    get name() {
      return `radio-group-${this.elementId}`;
    }

    //
    // Actions
    //
    changeHandler(_ref) {
      let {
        value
      } = _ref;
      value = this._optionsMap[value];
      value = this.processOption(value);
      this.onChange(value);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "name", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "name"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeHandler", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "changeHandler"), _class.prototype)), _class));
  _exports.default = RadioGroup;
});