define("cropster-origin-app/components/batches/reception-form/select-or-create-contact/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "EvKhyeAR",
    "block": "{\"symbols\":[\"SelectOrCreateContact\",\"&default\",\"@contactRoleName\",\"@selectedContact\",\"@label\"],\"statements\":[[4,\"let\",[[28,\"hash\",null,[[\"newContact\",\"Component\"],[[23,0,[\"newContact\"]],[28,\"component\",[\"form/field\"],[[\"type\",\"label\",\"proxy\",\"info\"],[\"datalist\",[28,\"or\",[[23,5,[]],[28,\"t\",[\"Name\"],null]],null],[28,\"hash\",null,[[\"valuePath\",\"labelPath\",\"options\",\"value\",\"onChange\",\"disabled\"],[\"id\",\"name\",[23,0,[\"options\"]],[23,4,[\"companyName\"]],[28,\"action\",[[23,0,[]],[23,0,[\"changeHandler\"]]],null],[23,0,[\"disabled\"]]]]],[28,\"if\",[[23,0,[\"newContact\"]],[28,\"t\",[\"Note: A new {{contactRoleName}} will be created!\"],[[\"contactRoleName\"],[[23,3,[]]]]]],null]]]]]]]],null,{\"statements\":[[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"    \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,[23,1,[\"Component\"]],[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-origin-app/components/batches/reception-form/select-or-create-contact/template.hbs"
    }
  });
  _exports.default = _default;
});