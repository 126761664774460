define("cropster-origin-app/orbit/utils/generate-id", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const cache = {};
  const generate = function () {
    let length = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 10;
    let alphabet = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let id = '';
    for (var i = 0; i < length; i++) {
      id += alphabet.charAt(~~(Math.random() * alphabet.length));
    }
    return id;
  };
  var _default = () => {
    let id;
    do {
      id = generate();
    } while (id in cache);
    cache[id] = true;
    return id;
  };
  _exports.default = _default;
});