define("cropster-origin-app/services/store", ["exports", "@orbit/memory", "@orbit/jsonapi", "@orbit/indexeddb", "@orbit/local-storage", "@orbit/data", "cropster-origin-app/models/abstract"], function (_exports, _memory, _jsonapi, _indexeddb, _localStorage, _data, _abstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isSource = _exports.isSchema = _exports.isRecordIdentity = _exports.isRecord = _exports.isPromise = _exports.isModel = _exports.isMemorySource = _exports.isLocalStorageSource = _exports.isJSONAPISource = _exports.isIndexedDBSource = _exports.default = _exports.UnknownPropertyError = _exports.SORT_DIRECTION_DESC = _exports.SORT_DIRECTION_ASC = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const SORT_DIRECTION_ASC = 'ascending';
  _exports.SORT_DIRECTION_ASC = SORT_DIRECTION_ASC;
  const SORT_DIRECTION_DESC = 'descending';
  _exports.SORT_DIRECTION_DESC = SORT_DIRECTION_DESC;
  const isRecord = record => record !== null && typeof record === 'object' && typeof record.type === 'string';
  _exports.isRecord = isRecord;
  const isRecordIdentity = record => isRecord(record) && typeof record.id === 'string';
  _exports.isRecordIdentity = isRecordIdentity;
  const isModel = model => model instanceof _abstract.default;
  _exports.isModel = isModel;
  const isSource = source => source instanceof _data.Source;
  _exports.isSource = isSource;
  const isSchema = schema => schema instanceof _data.Schema;
  _exports.isSchema = isSchema;
  const isPromise = promise => promise instanceof Promise;
  _exports.isPromise = isPromise;
  const isMemorySource = source => source instanceof _memory.default;
  _exports.isMemorySource = isMemorySource;
  const isJSONAPISource = source => source instanceof _jsonapi.default;
  _exports.isJSONAPISource = isJSONAPISource;
  const isIndexedDBSource = source => source instanceof _indexeddb.default;
  _exports.isIndexedDBSource = isIndexedDBSource;
  const isLocalStorageSource = source => source instanceof _localStorage.default;
  _exports.isLocalStorageSource = isLocalStorageSource;
  class UnknownPropertyError extends Error {
    constructor() {
      super(...arguments);
      if (Error.captureStackTrace) {
        Error.captureStackTrace(this, UnknownPropertyError);
      }
      this.name = 'UnknownPropertyError';
    }
  }
  _exports.UnknownPropertyError = UnknownPropertyError;
  const identityMap = new Map();

  /**
   * Abstract store for handling querying and updating sources of Orbit.js
   */
  let Store = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class Store extends Ember.Service {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "schema", _descriptor, this);
      _initializerDefineProperty(this, "modelFactory", _descriptor2, this);
      _initializerDefineProperty(this, "memorySource", _descriptor3, this);
      _initializerDefineProperty(this, "remoteSource", _descriptor4, this);
    }
    //
    // Updating
    //
    async addRecord(source, record) {
      (false && !(isSource(source)) && Ember.assert('`source` must be a source!', isSource(source)));
      (false && !(isRecord(record)) && Ember.assert('`record` must be a record!', isRecord(record)));
      const method = this._getUpdateMethod(source);
      const {
        type,
        id
      } = await source[method](transform => transform.addRecord(record));
      return this.findRecord(source, type, id);
    }
    async updateRecord(source, modelOrRecord) {
      (false && !(isSource(source)) && Ember.assert('`source` must be a source!', isSource(source)));
      (false && !(isModel(modelOrRecord) || isRecordIdentity(modelOrRecord)) && Ember.assert('`modelOrRecord` must be a model or record identity!', isModel(modelOrRecord) || isRecordIdentity(modelOrRecord)));
      const method = this._getUpdateMethod(source);
      const record = this._getRecord(modelOrRecord);
      const {
        type,
        id
      } = await source[method](transform => transform.updateRecord(record));
      return this.findRecord(source, type, id);
    }
    async removeRecord(source, modelOrRecord) {
      (false && !(isSource(source)) && Ember.assert('`source` must be a source!', isSource(source)));
      (false && !(isModel(modelOrRecord) || isRecordIdentity(modelOrRecord)) && Ember.assert('`modelOrRecord` must be a model or record identity!', isModel(modelOrRecord) || isRecordIdentity(modelOrRecord)));
      const method = this._getUpdateMethod(source);
      const record = this._getRecord(modelOrRecord);
      await source[method](transform => transform.removeRecord(record));
    }
    async replaceKey(source, modelOrRecord, attribute, value) {
      (false && !(isSource(source)) && Ember.assert('`source` must be a source!', isSource(source)));
      (false && !(isModel(modelOrRecord) || isRecordIdentity(modelOrRecord)) && Ember.assert('`modelOrRecord` must be a model or record identity!', isModel(modelOrRecord) || isRecordIdentity(modelOrRecord)));
      const method = this._getUpdateMethod(source);
      const record = this._getRecord(modelOrRecord);
      await source[method](transform => transform.replaceKey(record, attribute, value));
      if (isModel(modelOrRecord)) {
        Ember.notifyPropertyChange(modelOrRecord, attribute);
      }
    }
    async replaceAttribute(source, modelOrRecord, attribute, value) {
      (false && !(isSource(source)) && Ember.assert('`source` must be a source!', isSource(source)));
      (false && !(isModel(modelOrRecord) || isRecordIdentity(modelOrRecord)) && Ember.assert('`modelOrRecord` must be a model or record identity!', isModel(modelOrRecord) || isRecordIdentity(modelOrRecord)));
      (false && !(this.schema.hasAttribute(modelOrRecord.type, attribute)) && Ember.assert('`attribute must be a an existing attribute', this.schema.hasAttribute(modelOrRecord.type, attribute)));
      const method = this._getUpdateMethod(source);
      const record = this._getRecord(modelOrRecord);
      await source[method](transform => transform.replaceAttribute(record, attribute, value));
      if (isModel(modelOrRecord)) {
        Ember.notifyPropertyChange(modelOrRecord, attribute);
      }
    }
    async addToRelatedRecords(source, modelOrRecord, relationship, relatedModelOrRecord) {
      (false && !(isSource(source)) && Ember.assert('`source` must be a source!', isSource(source)));
      (false && !(isModel(modelOrRecord) || isRecordIdentity(modelOrRecord)) && Ember.assert('`modelOrRecord` must be a model or record identity!', isModel(modelOrRecord) || isRecordIdentity(modelOrRecord)));
      (false && !(this.schema.hasRelationship(modelOrRecord.type, relationship)) && Ember.assert('`relationship must be a an existing relationship', this.schema.hasRelationship(modelOrRecord.type, relationship)));
      (false && !(isModel(relatedModelOrRecord) || isRecordIdentity(relatedModelOrRecord)) && Ember.assert('`relatedModelOrRecord` must be a model or record!', isModel(relatedModelOrRecord) || isRecordIdentity(relatedModelOrRecord)));
      const method = this._getUpdateMethod(source);
      const record = this._getRecord(modelOrRecord);
      const relatedRecord = this._getRecord(relatedModelOrRecord);
      await source[method](transform => transform.addToRelatedRecords(record, relationship, relatedRecord));
      if (isModel(modelOrRecord)) {
        Ember.notifyPropertyChange(modelOrRecord, relationship);
      }
    }
    async removeFromRelatedRecords(source, modelOrRecord, relationship, relatedModelOrRecord) {
      (false && !(isSource(source)) && Ember.assert('`source` must be a source!', isSource(source)));
      (false && !(isModel(modelOrRecord) || isRecordIdentity(modelOrRecord)) && Ember.assert('`modelOrRecord` must be a model or record identity!', isModel(modelOrRecord) || isRecordIdentity(modelOrRecord)));
      (false && !(this.schema.hasRelationship(modelOrRecord.type, relationship)) && Ember.assert('`relationship must be a an existing relationship', this.schema.hasRelationship(modelOrRecord.type, relationship)));
      (false && !(isModel(relatedModelOrRecord) || isRecordIdentity(relatedModelOrRecord)) && Ember.assert('`relatedModelOrRecord` must be a model or record!', isModel(relatedModelOrRecord) || isRecordIdentity(relatedModelOrRecord)));
      const method = this._getUpdateMethod(source);
      const record = this._getRecord(modelOrRecord);
      const relatedRecord = this._getRecord(relatedModelOrRecord);
      await source[method](transform => transform.removeFromRelatedRecords(record, relationship, relatedRecord));
      if (isModel(modelOrRecord)) {
        Ember.notifyPropertyChange(modelOrRecord, relationship);
      }
    }
    async replaceRelatedRecords(source, modelOrRecord, relationship, relatedModelsOrRecords) {
      (false && !(isSource(source)) && Ember.assert('`source` must be a source!', isSource(source)));
      (false && !(isModel(modelOrRecord) || isRecordIdentity(modelOrRecord)) && Ember.assert('`modelOrRecord` must be a model or record identity!', isModel(modelOrRecord) || isRecordIdentity(modelOrRecord)));
      (false && !(this.schema.hasRelationship(modelOrRecord.type, relationship)) && Ember.assert('`relationship must be a an existing relationship', this.schema.hasRelationship(modelOrRecord.type, relationship)));
      (false && !(Array.isArray(relatedModelsOrRecords) && relatedModelsOrRecords.every(relatedModelOrRecord => isModel(relatedModelOrRecord) || isRecordIdentity(relatedModelOrRecord))) && Ember.assert('`relatedRecords` must be an array of models or record identities!', Array.isArray(relatedModelsOrRecords) && relatedModelsOrRecords.every(relatedModelOrRecord => isModel(relatedModelOrRecord) || isRecordIdentity(relatedModelOrRecord))));
      const relatedRecords = relatedModelsOrRecords.map(modelOrRecord => this._getRecord(modelOrRecord));
      const method = this._getUpdateMethod(source);
      const record = this._getRecord(modelOrRecord);
      await source[method](transform => transform.replaceRelatedRecords(record, relationship, relatedRecords));
      if (isModel(modelOrRecord)) {
        Ember.notifyPropertyChange(modelOrRecord, relationship);
      }
    }
    async replaceRelatedRecord(source, modelOrRecord, relationship, relatedModelOrRecord) {
      (false && !(isSource(source)) && Ember.assert('`source` must be a source!', isSource(source)));
      (false && !(isModel(modelOrRecord) || isRecordIdentity(modelOrRecord)) && Ember.assert('`modelOrRecord` must be a model or record identity!', isModel(modelOrRecord) || isRecordIdentity(modelOrRecord)));
      (false && !(this.schema.hasRelationship(modelOrRecord.type, relationship)) && Ember.assert('`relationship must be a an existing relationship', this.schema.hasRelationship(modelOrRecord.type, relationship)));
      (false && !(isModel(relatedModelOrRecord) || isRecordIdentity(relatedModelOrRecord) || relatedModelOrRecord === null) && Ember.assert('`relatedModelOrRecord` must be a model or record or null!', isModel(relatedModelOrRecord) || isRecordIdentity(relatedModelOrRecord) || relatedModelOrRecord === null));
      const relatedRecord = relatedModelOrRecord !== null ? this._getRecord(relatedModelOrRecord) : null;
      const method = this._getUpdateMethod(source);
      const record = this._getRecord(modelOrRecord);
      const result = await source[method](transform => transform.replaceRelatedRecord(record, relationship, relatedRecord));
      if (isModel(modelOrRecord)) {
        Ember.notifyPropertyChange(modelOrRecord, relationship);
      }
      return result;
    }

    //
    // Querying
    //
    findRecord(source, type, id) {
      let queryOptions = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      (false && !(isSource(source)) && Ember.assert('`source` must be a source!', isSource(source)));
      (false && !(this.isValidModelType(type)) && Ember.assert('`type` must be a valid model!', this.isValidModelType(type)));
      (false && !(typeof id === 'string') && Ember.assert('`id` must be a string!', typeof id === 'string'));
      const method = this._getQueryMethod(source);
      const target = this._getCacheOrUseSource(source);
      const result = target[method](queryBuilder => queryBuilder.findRecord({
        type,
        id
      }), queryOptions);
      return this._handleResult(source, result);
    }
    findRecords(source, type) {
      let expressionOptions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      let queryOptions = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      (false && !(isSource(source)) && Ember.assert('`source` must be a memory source!', isSource(source)));
      const method = this._getQueryMethod(source);
      const target = this._getCacheOrUseSource(source);
      const result = target[method](queryBuilder => {
        const query = this.isValidModelType(type) ? queryBuilder.findRecords(type) : queryBuilder.findRecords();
        this._decorateQuery(query, expressionOptions);
        return query;
      }, queryOptions);
      return this._handleResult(source, result);
    }
    findRelatedRecord(source, modelOrRecord, relationship) {
      let queryOptions = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      (false && !(isSource(source)) && Ember.assert('`source` must be a memory source!', isSource(source)));
      (false && !(isModel(modelOrRecord) || isRecordIdentity(modelOrRecord)) && Ember.assert('`modelOrRecord` must be a model or record identity!', isModel(modelOrRecord) || isRecordIdentity(modelOrRecord)));
      (false && !(this.schema.hasRelationship(modelOrRecord.type, relationship)) && Ember.assert('`relationship must be a an existing relationship', this.schema.hasRelationship(modelOrRecord.type, relationship)));
      const method = this._getQueryMethod(source);
      const target = this._getCacheOrUseSource(source);
      const record = this._getRecord(modelOrRecord);
      const result = target[method](queryBuilder => queryBuilder.findRelatedRecord(record, relationship), queryOptions);
      return this._handleResult(source, result);
    }
    findRelatedRecords(source, modelOrRecord, relationship) {
      let expressionOptions = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      let queryOptions = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
      (false && !(isSource(source)) && Ember.assert('`source` must be a memory source!', isSource(source)));
      (false && !(isModel(modelOrRecord) || isRecordIdentity(modelOrRecord)) && Ember.assert('`modelOrRecord` must be a model or record identity!', isModel(modelOrRecord) || isRecordIdentity(modelOrRecord)));
      (false && !(this.schema.hasRelationship(modelOrRecord.type, relationship)) && Ember.assert('`relationship must be a an existing relationship', this.schema.hasRelationship(modelOrRecord.type, relationship)));
      const method = this._getQueryMethod(source);
      const target = this._getCacheOrUseSource(source);
      const record = this._getRecord(modelOrRecord);
      const result = target[method](queryBuilder => {
        let query = queryBuilder.findRelatedRecords(record, relationship);
        this._decorateQuery(query, expressionOptions);
        return query;
      }, queryOptions);
      return this._handleResult(source, result);
    }

    //
    // Mutations
    //

    forkSource(source) {
      (false && !(isMemorySource(source)) && Ember.assert('`source` must be a memory source!', isMemorySource(source)));
      const key = this._getSourceIdentity(source);
      identityMap.set(key, new Map());
      return source.fork();
    }
    dropSource(forked) {
      (false && !(isMemorySource(forked)) && Ember.assert('`forked` must be a memory source!', isMemorySource(forked)));
      identityMap.delete(this._getSourceIdentity(forked));
    }
    async mergeSource(forked, source) {
      (false && !(isMemorySource(source)) && Ember.assert('`source` must be a memory source!', isMemorySource(source)));
      (false && !(isMemorySource(forked)) && Ember.assert('`forked` must be a memory source!', isMemorySource(forked)));
      this.dropSource(forked);
      return await source.merge(forked);
    }
    async resetSource(source) {
      (false && !(isSource(source)) && Ember.assert('`source` must be a source!', isSource(source)));
      (false && !(!isJSONAPISource(source)) && Ember.assert('`source` must not be a json api source!', !isJSONAPISource(source)));
      switch (true) {
        // note: instead of deleting whole database drop all
        // all records for all schema entries to avoid block
        // due to concurrency when being opened in more tabs
        case isIndexedDBSource(source):
          {
            const {
              models
            } = this.schema;

            // assert a db connection
            await source.cache.openDB();
            for (const model of Object.keys(models)) {
              try {
                await source.cache.clearRecords(model);
              } catch (e) {
                // model's object store may not exist
              }
            }
            break;
          }
        default:
          // all other sources can instrument default reset
          source.cache.reset();
      }
    }
    async syncFromSourceToTarget(source, target) {
      (false && !(isSource(source)) && Ember.assert('`source` must be a source!', isSource(source)));
      (false && !(isSource(target)) && Ember.assert('`target` must be a source!', isSource(target)));
      (false && !(!isJSONAPISource(source)) && Ember.assert('`source` must not be a json api source!', !isJSONAPISource(source)));
      (false && !(!isJSONAPISource(target)) && Ember.assert('`target` must not be a json api source!', !isJSONAPISource(target)));
      (false && !(isMemorySource(source) || (0, _data.isPullable)(source)) && Ember.assert('`source` must be a memory source or implement pullable interface!', isMemorySource(source) || (0, _data.isPullable)(source)));
      (false && !((0, _data.isSyncable)(target)) && Ember.assert('`target` must implement the syncable interface!', (0, _data.isSyncable)(target)));
      const transforms = !isMemorySource(source) ? await source.pull(queryBuilder => queryBuilder.findRecords()) : source.allTransforms();
      await target.sync(transforms);
    }

    //
    // Misc
    //
    async hasRecord(source, modelOrRecord) {
      (false && !(isSource(source)) && Ember.assert('`source` must be a memory source!', isSource(source)));
      (false && !(isModel(modelOrRecord) || isRecordIdentity(modelOrRecord)) && Ember.assert('`modelOrRecord` must be a model or record identity!', isModel(modelOrRecord) || isRecordIdentity(modelOrRecord)));
      const {
        type,
        id
      } = modelOrRecord;
      try {
        await this.findRecord(source, type, id);
      } catch (e) {
        // note: don't keep requests in queue to be performed
        // automatically with `autoProcess: true` by default!
        source.requestQueue.skip();
        return false;
      }
      return true;
    }
    resolvePath(source, modelOrRecord, path) {
      (false && !(isMemorySource(source)) && Ember.assert('`source` must be a memory source!', isMemorySource(source)));
      (false && !(isModel(modelOrRecord) || isRecordIdentity(modelOrRecord)) && Ember.assert('`modelOrRecord` must be a model or record identity!', isModel(modelOrRecord) || isRecordIdentity(modelOrRecord)));
      (false && !(typeof path === 'string') && Ember.assert('`path` must be a string!', typeof path === 'string'));
      const record = this._getRecord(modelOrRecord);
      const parts = path.split('.');
      let key;
      let result;
      let {
        type,
        id
      } = record;
      while (key = parts.shift()) {
        // a) attributes: stop immediately, there's no more recursion to be done
        if (this.schema.hasAttribute(type, key)) {
          const read = (model, key) => {
            const {
              attributes
            } = model.getRecord();
            return attributes[key];
          };
          switch (true) {
            case result === undefined:
              // general entry point
              try {
                return read(this.findRecord(source, type, id), key);
              } catch (e) {
                // model not found (aka. deleted)
                return result;
              }
            case Array.isArray(result):
              // toMany models
              return result.map(model => read(model, key));
            case result !== null:
              // toOne model
              return read(result, key);
            default:
              return null;
          }
        }

        // b) relationships: check how to resolve the current relationship type
        if (this.schema.hasRelationship(type, key)) {
          const meta = this.schema.getRelationship(type, key);
          const find = (meta.type === 'hasMany' ? this.findRelatedRecords : this.findRelatedRecord).bind(this, source);

          // a) result is not defined yet, resolve from the current model
          // b) result could be an array from resolving previous `hasMany`,
          // be prepared for that and flatMap new result with existing ones
          switch (true) {
            case result === undefined:
              try {
                result = find(this.findRecord(source, type, id), key);
              } catch (e) {
                // model not found (aka. deleted)
                result = undefined;
              }
              break;
            case Array.isArray(result):
              result = result.flatMap(model => find(model, key));
              break;
            case result !== null:
              result = find(result, key);
              break;
            default:
              result = null;
          }

          // upadte `type` from the `model` meta information,
          // as this has not to be the same as relation name:
          // `source: { type: 'hasOne', model: 'contact'}`
          type = meta.model;
          continue;
        }

        // c) error: user provided a wrong path information for this record
        throw new UnknownPropertyError(`${type} has neither attribute nor relationship: ${key}!`);
      }
      return result;
    }
    isValidModelType(type) {
      return !!this.schema.models[type];
    }

    //
    // Misc
    //
    _getCacheOrUseSource(source) {
      (false && !(isSource(source)) && Ember.assert('`source` must be a source!', isSource(source)));
      return isMemorySource(source) ? source.cache : source;
    }
    _getQueryMethod(source) {
      (false && !(isSource(source)) && Ember.assert('`source` must be a source!', isSource(source)));
      return (0, _data.isQueryable)(source) ? 'query' : 'pull';
    }
    _getUpdateMethod(source) {
      (false && !(isSource(source)) && Ember.assert('`source` must be a source!', isSource(source)));
      return (0, _data.isUpdatable)(source) ? 'update' : 'push';
    }
    _getRecord(modelOrRecord) {
      (false && !(isModel(modelOrRecord) || isRecord(modelOrRecord)) && Ember.assert('`record` must be a model or record!', isModel(modelOrRecord) || isRecord(modelOrRecord)));
      return isModel(modelOrRecord) ? modelOrRecord.getRecord() : modelOrRecord;
    }
    _getSourceIdentity(source) {
      (false && !(isSource(source)) && Ember.assert('`source` must be a source!', isSource(source)));
      return isMemorySource(source) ? source.forkPoint || 'memory' : source.name;
    }
    _getRecordIdentity(record) {
      (false && !(isRecord(record)) && Ember.assert('`source` must be a record!', isRecord(record)));
      return `${record.type}:${record.id}`;
    }
    _decorateQuery(query) {
      let expressionOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      if (typeof expressionOptions === 'object' && expressionOptions !== null) {
        const {
          page,
          filter,
          sort
        } = expressionOptions;
        if (typeof page === 'object' && page !== null) {
          query = query.page(page);
        }
        if (Array.isArray(filter)) {
          query = query.filter(...filter);
        }
        if (Array.isArray(sort)) {
          query = query.sort(...sort);
        }
      }
      return query;
    }
    _handleResult(source, result) {
      const handler = (source, result) => {
        const record = Array.isArray(result) ? result[0] : result;
        return isRecord(record) ? this._mapResult(source, result) : result;
      };
      return !isPromise(result) ? handler(source, result) : result.then(result => handler(source, result));
    }
    _mapResult(source, result) {
      const handleResult = result => Array.isArray(result) ? result.map(record => this._mapRecord(source, record)) : result !== null ? this._mapRecord(source, result) : null;
      return result instanceof Promise ? result.then(handleResult) : handleResult(result);
    }
    _mapRecord(source, record) {
      const sourceIdentity = this._getSourceIdentity(source);
      const recordIdentity = this._getRecordIdentity(record);
      if (!identityMap.has(sourceIdentity)) {
        identityMap.set(sourceIdentity, new Map());
      }
      const sourceMap = identityMap.get(sourceIdentity);
      if (!sourceMap.has(recordIdentity)) {
        sourceMap.set(recordIdentity, this.modelFactory.create(source, record));
      }
      const model = sourceMap.get(recordIdentity);
      model.fromJSON(record);
      return model;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "schema", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modelFactory", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "memorySource", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "remoteSource", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Store;
});