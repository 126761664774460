define("cropster-origin-app/orbit/strategies/sync-remote-to-memory", ["exports", "@orbit/coordinator"], function (_exports, _coordinator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const syncRemoteToMemoryStrategy = new _coordinator.SyncStrategy({
    source: 'remote',
    target: 'memory',
    blocking: true
  });
  var _default = syncRemoteToMemoryStrategy;
  _exports.default = _default;
});