define("cropster-origin-app/utils/converter", ["exports", "cropster-origin-app/utils/enums", "cropster-origin-app/utils/parser"], function (_exports, _enums, _parser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ozToLitre = _exports.ozToLbs = _exports.ozToKg = _exports.ozToG = _exports.lbsToOz = _exports.lbsToLitre = _exports.lbsToKg = _exports.lbsToG = _exports.kgToOz = _exports.kgToLitre = _exports.kgToLbs = _exports.kgToG = _exports.gToOz = _exports.gToLitre = _exports.gToLbs = _exports.gToKg = _exports.convertWeight = void 0;
  const kgToLbs = kg => kg * 2.2046;
  _exports.kgToLbs = kgToLbs;
  const kgToOz = kg => kg * 35.274;
  _exports.kgToOz = kgToOz;
  const kgToG = kg => kg * 1000;
  _exports.kgToG = kgToG;
  const kgToLitre = kg => kg * 1;
  _exports.kgToLitre = kgToLitre;
  const lbsToKg = lbs => lbs / 2.2046;
  _exports.lbsToKg = lbsToKg;
  const lbsToOz = lbs => lbs * 16;
  _exports.lbsToOz = lbsToOz;
  const lbsToG = lbs => lbs / 0.0022046;
  _exports.lbsToG = lbsToG;
  const lbsToLitre = lbs => lbs / 2.2046;
  _exports.lbsToLitre = lbsToLitre;
  const ozToLbs = oz => oz * 0.0625;
  _exports.ozToLbs = ozToLbs;
  const ozToKg = oz => oz / 35.274;
  _exports.ozToKg = ozToKg;
  const ozToG = oz => oz / 0.035274;
  _exports.ozToG = ozToG;
  const ozToLitre = oz => oz / 35.274;
  _exports.ozToLitre = ozToLitre;
  const gToLbs = g => g * 0.0022046;
  _exports.gToLbs = gToLbs;
  const gToKg = g => g / 1000;
  _exports.gToKg = gToKg;
  const gToOz = g => g * 0.035274;
  _exports.gToOz = gToOz;
  const gToLitre = g => g / 1000;
  _exports.gToLitre = gToLitre;
  const CONVERSION_MAP = {
    [_enums.WEIGHT_UNIT_G]: {
      [_enums.WEIGHT_UNIT_KG]: gToKg,
      [_enums.WEIGHT_UNIT_LBS]: gToLbs,
      [_enums.WEIGHT_UNIT_OZ]: gToOz,
      [_enums.WEIGHT_UNIT_LITRE]: gToLitre,
      [_enums.WEIGHT_UNIT_LITER]: gToLitre
    },
    [_enums.WEIGHT_UNIT_KG]: {
      [_enums.WEIGHT_UNIT_G]: kgToG,
      [_enums.WEIGHT_UNIT_LBS]: kgToLbs,
      [_enums.WEIGHT_UNIT_OZ]: kgToOz,
      [_enums.WEIGHT_UNIT_LITRE]: kgToLitre,
      [_enums.WEIGHT_UNIT_LITER]: kgToLitre
    },
    [_enums.WEIGHT_UNIT_LITRE]: {
      [_enums.WEIGHT_UNIT_G]: kgToG,
      [_enums.WEIGHT_UNIT_LBS]: kgToLbs,
      [_enums.WEIGHT_UNIT_OZ]: kgToOz,
      [_enums.WEIGHT_UNIT_KG]: kgToLitre
    },
    [_enums.WEIGHT_UNIT_LITER]: {
      [_enums.WEIGHT_UNIT_G]: kgToG,
      [_enums.WEIGHT_UNIT_LBS]: kgToLbs,
      [_enums.WEIGHT_UNIT_OZ]: kgToOz,
      [_enums.WEIGHT_UNIT_KG]: kgToLitre
    },
    [_enums.WEIGHT_UNIT_LBS]: {
      [_enums.WEIGHT_UNIT_G]: lbsToG,
      [_enums.WEIGHT_UNIT_KG]: lbsToKg,
      [_enums.WEIGHT_UNIT_OZ]: lbsToOz,
      [_enums.WEIGHT_UNIT_LITRE]: lbsToLitre,
      [_enums.WEIGHT_UNIT_LITER]: lbsToLitre
    },
    [_enums.WEIGHT_UNIT_OZ]: {
      [_enums.WEIGHT_UNIT_G]: ozToG,
      [_enums.WEIGHT_UNIT_KG]: ozToKg,
      [_enums.WEIGHT_UNIT_LBS]: ozToLbs,
      [_enums.WEIGHT_UNIT_LITRE]: ozToLitre,
      [_enums.WEIGHT_UNIT_LITER]: ozToLitre
    }
  };
  const convertWeight = function (amount) {
    let sourceUnit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _enums.WEIGHT_UNIT_KG;
    let targetUnit = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _enums.WEIGHT_UNIT_LBS;
    let accuracy = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 3;
    if (typeof amount !== 'number') {
      throw new Error(`'amount' has to be a number!`);
    }

    // parse weight unit enums to their parts including `type`, `size` and `unit`
    // input: 'BAG30KG' -> output: { type: 'BAG', size: 30, unit: 'KG' }
    const source = (0, _parser.parseWeightUnit)(sourceUnit);
    const target = (0, _parser.parseWeightUnit)(targetUnit);

    // normalize input amount with size, f.e. 10 x BAG30KG -> 10 x 30KG -> 300KG
    if (!isNaN(source.size)) {
      amount *= source.size;
    } else {
      amount *= 1;
    }

    // apply conversion only if source and target units are not the same
    if (source.unit !== target.unit) {
      const convertMap = CONVERSION_MAP[source.unit];
      if (typeof convertMap === 'undefined') {
        throw new Error(`'${source.unit}' is not a valid source unit!`);
      }
      const convert = convertMap[target.unit];
      if (typeof convert !== 'function') {
        throw new Error(`Converting '${source.unit}' to '${target.unit}' is not supported!`);
      }
      amount = convert(amount);
    }

    //
    // normalize target size to the specified amount
    //
    // amount: 5
    // source: BAG30KG
    // target: BAG10KG
    // result: 5 x 30 / 10 = 15 x BAG 10KG
    //

    if (!isNaN(target.size)) {
      amount /= target.size;
    } else {
      amount /= 1;
    }

    // finally round to given accuracy
    return +amount.toFixed(accuracy);
  };
  _exports.convertWeight = convertWeight;
});