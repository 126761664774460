define("cropster-origin-app/helpers/format-weight", ["exports", "cropster-origin-app/utils/parser", "cropster-origin-app/utils/enums", "cropster-origin-app/utils/formatter"], function (_exports, _parser, _enums, _formatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let FormatWeightHelper = (_dec = Ember.inject.service, (_class = class FormatWeightHelper extends Ember.Helper {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "l10n", _descriptor, this);
    }
    compute(_ref, _ref2) {
      let [weight] = _ref;
      let {
        minimumFractionDigits = 0,
        maximumFractionDigits = 2
      } = _ref2;
      let {
        amount,
        unit
      } = (0, _parser.parseMeasure)(weight);
      return Ember.String.htmlSafe((0, _formatter.formatWeight)(amount, unit, {
        minimumFractionDigits,
        maximumFractionDigits,
        translateUnit: () => this.l10n.tVar(_enums.TRANSLATIONS_MAP[unit])
      }));
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "l10n", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = FormatWeightHelper;
});