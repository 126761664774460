define("cropster-origin-app/orbit/schemas/property", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    attributes: {
      name: {
        type: 'string'
      },
      value: {
        type: 'json'
      }
    },
    relationships: {
      group: {
        type: 'hasOne',
        model: 'group'
      },
      user: {
        type: 'hasOne',
        model: 'user'
      }
    }
  };
  _exports.default = _default;
});