define("cropster-origin-app/services/datadog-logger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LOGGER_LEVEL_WARN = _exports.LOGGER_LEVEL_INFO = _exports.LOGGER_LEVEL_ERROR = _exports.LOGGER_LEVEL_DEBUG = _exports.LOGGER_HANDLER_SILENT = _exports.LOGGER_HANDLER_HTTP = _exports.LOGGER_HANDLER_CONSOLE = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  const DATADOG_CDN_URL = 'https://www.datadoghq-browser-agent.com/datadog-logs-us.js';
  const DEFAULT_CONFIG = {
    forwardErrorToLogs: true
  };
  const LOGGER_HANDLER_HTTP = 'http';
  _exports.LOGGER_HANDLER_HTTP = LOGGER_HANDLER_HTTP;
  const LOGGER_HANDLER_CONSOLE = 'console';
  _exports.LOGGER_HANDLER_CONSOLE = LOGGER_HANDLER_CONSOLE;
  const LOGGER_HANDLER_SILENT = 'silent';
  _exports.LOGGER_HANDLER_SILENT = LOGGER_HANDLER_SILENT;
  const LOGGER_LEVEL_DEBUG = 'debug';
  _exports.LOGGER_LEVEL_DEBUG = LOGGER_LEVEL_DEBUG;
  const LOGGER_LEVEL_INFO = 'info';
  _exports.LOGGER_LEVEL_INFO = LOGGER_LEVEL_INFO;
  const LOGGER_LEVEL_WARN = 'warn';
  _exports.LOGGER_LEVEL_WARN = LOGGER_LEVEL_WARN;
  const LOGGER_LEVEL_ERROR = 'error';

  /**
   * A simple adapter for datadog logger browser implementation.
   * It also sends errors from javascript to datadog application.
   *
   * You need to provide `clientToken` for the DataDog JS SDK:
   *
   * @see https://docs.datadoghq.com/account_management/api-app-keys/#client-tokens
   * @see https://docs.datadoghq.com/logs/log_collection/javascript/
   */
  _exports.LOGGER_LEVEL_ERROR = LOGGER_LEVEL_ERROR;
  class DatadogLoggerAdapter extends Ember.Service {
    constructor() {
      super(...arguments);
      _defineProperty(this, "_client", null);
    }
    async load(clientToken) {
      let cdnURL = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DATADOG_CDN_URL;
      if (typeof window === 'undefined') {
        // eslint-disable-next-line no-console
        console.warn('DatadogLoggerAdapter: Requires browser environment!');
        return;
      }
      if (typeof clientToken !== 'string' || !clientToken.length) {
        // eslint-disable-next-line no-console
        console.warn('DatadogLoggerAdapter: Requires a client token!');
        return;
      }
      if (this._client !== null) {
        // eslint-disable-next-line no-console
        console.warn('DatadogLoggerAdapter: API was already loaded!');
        return;
      }
      try {
        await this._loadScript(cdnURL);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('DatadogLoggerAdapter: API could not be loaded!');
        return;
      }
      this._client = window.DD_LOGS;
      this._client.init({
        ...DEFAULT_CONFIG,
        ...{
          clientToken
        }
      });
    }
    log(message) {
      let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let level = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : LOGGER_LEVEL_DEBUG;
      (false && !(typeof name === 'string') && Ember.assert('`name` must be a string!', typeof name === 'string'));
      (false && !(typeof data === 'object' && data !== null) && Ember.assert('`data` must be an object!', typeof data === 'object' && data !== null));
      this._assertLevel(level);
      this._callOnClient(`logger.${level}`, ...arguments);
    }
    setGlobalLevel(level) {
      this._assertLevel(level);
      return this._callOnClient('setLevel', ...arguments);
    }
    setGlobalHandler(handler) {
      this._assertHandler(handler);
      return this._callOnClient('setHandler', ...arguments);
    }
    setGlobalContext() {
      let context = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      (false && !(typeof context === 'object' && context !== null) && Ember.assert('`context` must be an object!', typeof context === 'object' && context !== null));
      return this._callOnClient('setLoggerGlobalContext', ...arguments);
    }
    createLogger(name) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      (false && !(typeof name === 'string') && Ember.assert('`name` must be a string!', typeof name === 'string'));
      (false && !(typeof options === 'object' && options !== null) && Ember.assert('`options` must be an object!', typeof options === 'object' && options !== null));
      return this._callOnClient('createLogger', ...arguments);
    }
    _loadScript(url) {
      (false && !(typeof path === 'string') && Ember.assert('`url` must be a string!', typeof path === 'string'));
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.addEventListener('load', resolve, {
          once: true
        });
        script.addEventListener('error', reject, {
          once: true
        });
        script.src = url;
        document.head.appendChild(script);
      });
    }
    _callOnClient(path) {
      (false && !(typeof path === 'string') && Ember.assert('`path` must be a string!', typeof path === 'string'));
      if (this._client === null) {
        // eslint-disable-next-line no-console
        console.error('DatadogLoggerAdapter: API has not been loaded!');
        return;
      }
      const handler = Ember.get(this._client, path);
      if (typeof handler !== 'function') {
        // eslint-disable-next-line no-console
        console.error(`DatadogLoggerAdapter: API has no method/target ${path}!`);
        return;
      }

      // remove last part of path: `logger.log` -> `logger`
      path = path.split('.').slice(0, -1).join('.');

      // see if there's a scope within client or use client
      // as scope for the method to be invoked from parent:
      // a) f.e. `logger.log` -> scope = this._client.logger
      // b) f.e. `setContext` -> scope = this._client
      const context = Ember.get(this._client, path);
      const scope = context || this._client;
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }
      return handler.call(scope, ...args);
    }
    _assertHandler(handler) {
      (false && !(handler === LOGGER_HANDLER_HTTP || handler === LOGGER_HANDLER_CONSOLE || handler === LOGGER_HANDLER_SILENT) && Ember.assert(`\`handler\` must be one of: ${LOGGER_HANDLER_HTTP}, ${LOGGER_HANDLER_CONSOLE}, or ${LOGGER_HANDLER_SILENT}`, handler === LOGGER_HANDLER_HTTP || handler === LOGGER_HANDLER_CONSOLE || handler === LOGGER_HANDLER_SILENT));
    }
    _assertLevel(level) {
      (false && !(level === LOGGER_LEVEL_DEBUG || level === LOGGER_LEVEL_INFO || level === LOGGER_LEVEL_WARN || level === LOGGER_LEVEL_ERROR) && Ember.assert(`\`level\` must be one of: ${LOGGER_LEVEL_DEBUG}, ${LOGGER_LEVEL_INFO}, ${LOGGER_LEVEL_WARN} or ${LOGGER_LEVEL_ERROR}`, level === LOGGER_LEVEL_DEBUG || level === LOGGER_LEVEL_INFO || level === LOGGER_LEVEL_WARN || level === LOGGER_LEVEL_ERROR));
    }
  }
  _exports.default = DatadogLoggerAdapter;
});