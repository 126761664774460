define("cropster-origin-app/initializers/remote-source", ["exports", "cropster-origin-app/orbit/sources/remote"], function (_exports, _remote) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    application.register('service:remote-source', _remote.default, {
      instantiate: false
    });
  }
  var _default = {
    name: 'remote-source',
    initialize: initialize
  };
  _exports.default = _default;
});