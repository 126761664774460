define("cropster-origin-app/components/batches/reception-form/component", ["exports", "cropster-origin-app/services/store", "cropster-origin-app/models/reception", "cropster-origin-app/utils/enums"], function (_exports, _store, _reception, _enums) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _dec39, _dec40, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const VALID_SUPPLIER_ROLES = {
    [_enums.CONTACT_ROLE_COMMUNITY]: true,
    [_enums.CONTACT_ROLE_PICKER]: true,
    [_enums.CONTACT_ROLE_FIELD]: true,
    [_enums.CONTACT_ROLE_FARM]: true
  };
  let ReceptionForm = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.computed.alias('reception.receptionItems'), _dec8 = Ember.computed.readOnly('activeGroup.id'), _dec9 = Ember.computed.readOnly('originSettings.weightUnit'), _dec10 = Ember.computed.readOnly('originSettings.currency'), _dec11 = Ember.computed.readOnly('originSettings.defaultValues'), _dec12 = Ember.computed.not('isNew'), _dec13 = Ember.computed.gt('facitilies.length', 1), _dec14 = Ember.computed.gt('receptionItems.length', 0), _dec15 = Ember.computed('receptionItems.length'), _dec16 = Ember.computed('source'), _dec17 = Ember.computed('isNew', 'reception.receptionItems.[]', 'contactRoles.[]'), _dec18 = Ember.computed.or('reception.totalWeight', 'reception.totalPrice'), _dec19 = Ember.computed.equal('selectedContactRole.name', _enums.CONTACT_ROLE_COMMUNITY), _dec20 = Ember.computed.equal('selectedContactRole.name', _enums.CONTACT_ROLE_FARM), _dec21 = Ember.computed.equal('selectedContactRole.name', _enums.CONTACT_ROLE_FIELD), _dec22 = Ember.computed.equal('selectedContactRole.name', _enums.CONTACT_ROLE_PICKER), _dec23 = Ember.computed.or('isContactTypeFarm', 'isContactTypeField', 'isContactTypePicker'), _dec24 = Ember.computed.or('isContactTypeField', 'isContactTypePicker'), _dec25 = Ember.computed.readOnly('isContactTypePicker'), _dec26 = Ember.computed('source'), _dec27 = Ember.computed('source', 'groupId'), _dec28 = Ember.computed.equal('processes.length', 0), _dec29 = Ember._action, _dec30 = Ember._action, _dec31 = Ember._action, _dec32 = Ember._action, _dec33 = Ember._action, _dec34 = Ember._action, _dec35 = Ember._action, _dec36 = Ember._action, _dec37 = Ember._action, _dec38 = Ember._action, _dec39 = Ember._action, _dec40 = Ember._action, (_class = class ReceptionForm extends Ember.Component {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "l10n", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "schema", _descriptor3, this);
      _initializerDefineProperty(this, "activeGroup", _descriptor4, this);
      _initializerDefineProperty(this, "originSettings", _descriptor5, this);
      _initializerDefineProperty(this, "receptionFactory", _descriptor6, this);
      _defineProperty(this, "classNames", ['max-width-100p', 'width-8', 'margin-auto']);
      //
      // Arguments
      //
      _defineProperty(this, "reception", null);
      _defineProperty(this, "source", null);
      _defineProperty(this, "isNew", true);
      //
      // Properties
      //
      _defineProperty(this, "_removeHandlers", []);
      _initializerDefineProperty(this, "receptionItems", _descriptor7, this);
      _initializerDefineProperty(this, "groupId", _descriptor8, this);
      _initializerDefineProperty(this, "weightUnit", _descriptor9, this);
      _initializerDefineProperty(this, "currency", _descriptor10, this);
      _initializerDefineProperty(this, "defaultValues", _descriptor11, this);
      _initializerDefineProperty(this, "isEdit", _descriptor12, this);
      _initializerDefineProperty(this, "showFacilities", _descriptor13, this);
      _initializerDefineProperty(this, "hasReceptionItems", _descriptor14, this);
      _defineProperty(this, "_selectedContactRole", null);
      _initializerDefineProperty(this, "showTotals", _descriptor15, this);
      _initializerDefineProperty(this, "isContactTypeCommunity", _descriptor16, this);
      _initializerDefineProperty(this, "isContactTypeFarm", _descriptor17, this);
      _initializerDefineProperty(this, "isContactTypeField", _descriptor18, this);
      _initializerDefineProperty(this, "isContactTypePicker", _descriptor19, this);
      _initializerDefineProperty(this, "showGroupByCommunity", _descriptor20, this);
      _initializerDefineProperty(this, "showGroupByFarm", _descriptor21, this);
      _initializerDefineProperty(this, "showGroupByField", _descriptor22, this);
      _defineProperty(this, "coffeeForms", _enums.COFFEE_FORM_OPTIONS);
      _initializerDefineProperty(this, "hasNoProcesses", _descriptor23, this);
    }
    onDelete( /* reception */) {}
    onCancel( /* reception */) {}
    onSave( /* reception */) {}
    addReceptionItem( /* selectedContactRole */) {}
    focusReceptionItems( /* receptionItems */) {}
    updateBatchReceivedWeightByFactor( /*batch, factor*/) {}
    get receptionItemSources() {
      const receptionItems = Ember.get(this, 'reception.receptionItems');
      let sources = [];
      let addedSourcesHash = {};
      receptionItems.map((receptionItem, index) => {
        let name = Ember.get(receptionItem, 'source.name');
        let idTag = Ember.get(receptionItem, 'source.idTag');
        idTag = idTag ? idTag : '--';
        if (index === 0 || !addedSourcesHash[name]) {
          sources.push({
            name,
            idTag
          });
          addedSourcesHash[name] = true;
        }
      });
      return sources;
    }
    get contactRoles() {
      let contactRoles = [];
      for (let contactRoleName in VALID_SUPPLIER_ROLES) {
        contactRoles = [...contactRoles, ...this.store.findRecords(this.source, 'contactRole', {
          filter: [{
            attribute: 'name',
            value: contactRoleName
          }]
        })];
      }
      return contactRoles;
    }
    get selectedContactRole() {
      if (this._selectedContactRole === null) {
        if (!this.isNew) {
          const [receptionItem] = this.reception.receptionItems;
          if (!receptionItem || !receptionItem.sourceRole) {
            return null;
          }
          return receptionItem.sourceRole;
        }
        const {
          contactRoleName
        } = this.defaultValues;
        const contactRole = this.contactRoles.find(_ref => {
          let {
            name
          } = _ref;
          return name === contactRoleName;
        });
        return contactRole || this.contactRoles[0];
      }
      return this._selectedContactRole;
    }
    set selectedContactRole(contactRole) {
      Ember.set(this, '_selectedContactRole', contactRole);
      return contactRole;
    }
    get varieties() {
      return this.store.findRecords(this.source, 'variety');
    }
    get processes() {
      if (this.groupId !== null) {
        return this.store.findRecords(this.source, 'process', {
          filter: [{
            relation: 'group',
            record: {
              type: 'group',
              id: this.groupId
            }
          }]
        });
      }
      return [];
    }
    //
    // Hooks
    //
    async init() {
      super.init(...arguments);
      (false && !((0, _store.isSource)(this.source)) && Ember.assert('`source` must be a source instance!', (0, _store.isSource)(this.source)));
      (false && !(this.reception instanceof _reception.default) && Ember.assert('`reception` must be a reception model!', this.reception instanceof _reception.default));
      const beforeUnloadHandler = event => {
        const confirmLeaveMessage = this.isNew ? this.l10n.t('You are creating a batch, do you really want to leave this page?') : this.l10n.t('You are editing a batch, do you really want to leave this page?');
        event.returnValue = confirmLeaveMessage;
        return confirmLeaveMessage;
      };
      window.addEventListener('beforeunload', beforeUnloadHandler);
      this._removeHandlers.push(() => window.removeEventListener('beforeunload', beforeUnloadHandler));
    }
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      this._removeHandlers.forEach(handler => handler());
      this._removeHandlers.length = 0;
    }

    //
    // Actions
    //
    getLabelForContactRole(_ref2) {
      let {
        name
      } = _ref2;
      return this.l10n.tVar(_enums.TRANSLATIONS_MAP[name]);
    }
    async selectReceptionDate(receptionDate) {
      for (let batch of this.reception.batches) {
        await this.store.replaceAttribute(this.source, batch, 'receptionDate', receptionDate);
      }
      await this.store.replaceAttribute(this.source, this.reception, 'receptionDate', receptionDate);
    }
    async selectContactRole(contactRole) {
      const {
        name: contactRoleName
      } = contactRole;
      Ember.set(this, 'originSettings.defaultValues', {
        ...this.defaultValues,
        ...{
          contactRoleName
        }
      });

      // reset all group by fields peventing to save values which would probably
      // disappear with the new contact role, f.e. from `picke` -> `community``
      for (let batch of this.reception.batches) {
        await this.store.replaceRelatedRecord(this.source, batch, 'community', null);
        await this.store.replaceRelatedRecord(this.source, batch, 'field', null);
        await this.store.replaceRelatedRecord(this.source, batch, 'farm', null);
      }
      await this.originSettings.saveUserSettings();
      Ember.set(this, 'selectedContactRole', contactRole);
    }
    async updateBatchesReceivedWeight() {
      for (const batch of this.reception.batches) {
        const {
          factor
        } = batch; // use existing factor from batch!
        await this.updateBatchReceivedWeightByFactor(batch, factor);
      }
    }
    async changeVarieties(varieties) {
      for (let batch of this.reception.batches) {
        await this.store.replaceRelatedRecords(this.source, batch, 'varieties', varieties);
      }
    }
    async changeCoffeeForm(coffeeForm) {
      Ember.set(this, 'originSettings.defaultValues', {
        ...this.defaultValues,
        ...{
          coffeeForm
        }
      });
      await this.originSettings.saveUserSettings();
      await this.store.replaceAttribute(this.source, this.reception, 'coffeeForm', coffeeForm);
    }
    async changeNotes(notes) {
      for (let batch of this.reception.batches) {
        await this.store.replaceAttribute(this.source, batch, 'notes', notes);
      }
    }
    cancel() {
      return this.onCancel(this.reception);
    }
    delete() {
      return this.onDelete(this.reception);
    }
    save() {
      return this.onSave(this.reception);
    }
    async handleAddReceptionItem() {
      await this.addReceptionItem(this.selectedContactRole.getRecord());
      await this.updateBatchesReceivedWeight();
    }
    goToSupplier(receptionItemSource) {
      const receptionItems = Ember.get(this, 'receptionItems');
      const focusedReceptionItems = receptionItems.filter(receptionItem => {
        const sourceName = Ember.get(receptionItem, 'source.name');
        return sourceName === receptionItemSource.name;
      });
      this.focusReceptionItems(focusedReceptionItems);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "l10n", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "schema", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "activeGroup", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "originSettings", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "receptionFactory", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "receptionItems", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "groupId", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "weightUnit", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "currency", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "defaultValues", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isEdit", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "showFacilities", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "hasReceptionItems", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "receptionItemSources", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "receptionItemSources"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "contactRoles", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "contactRoles"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectedContactRole", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "selectedContactRole"), _class.prototype), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "showTotals", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "isContactTypeCommunity", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "isContactTypeFarm", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "isContactTypeField", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "isContactTypePicker", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "showGroupByCommunity", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "showGroupByFarm", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "showGroupByField", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "varieties", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "varieties"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "processes", [_dec27], Object.getOwnPropertyDescriptor(_class.prototype, "processes"), _class.prototype), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "hasNoProcesses", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getLabelForContactRole", [_dec29], Object.getOwnPropertyDescriptor(_class.prototype, "getLabelForContactRole"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectReceptionDate", [_dec30], Object.getOwnPropertyDescriptor(_class.prototype, "selectReceptionDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectContactRole", [_dec31], Object.getOwnPropertyDescriptor(_class.prototype, "selectContactRole"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateBatchesReceivedWeight", [_dec32], Object.getOwnPropertyDescriptor(_class.prototype, "updateBatchesReceivedWeight"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeVarieties", [_dec33], Object.getOwnPropertyDescriptor(_class.prototype, "changeVarieties"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeCoffeeForm", [_dec34], Object.getOwnPropertyDescriptor(_class.prototype, "changeCoffeeForm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeNotes", [_dec35], Object.getOwnPropertyDescriptor(_class.prototype, "changeNotes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec36], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "delete", [_dec37], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_dec38], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleAddReceptionItem", [_dec39], Object.getOwnPropertyDescriptor(_class.prototype, "handleAddReceptionItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goToSupplier", [_dec40], Object.getOwnPropertyDescriptor(_class.prototype, "goToSupplier"), _class.prototype)), _class));
  _exports.default = ReceptionForm;
});