define("cropster-origin-app/logs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "pf5fVB15",
    "block": "{\"symbols\":[\"log\"],\"statements\":[[5,\"layout/header\",[],[[\"@title\",\"@onGoBack\"],[[28,\"t\",[\"Logs\"],null],[28,\"action\",[[23,0,[]],[23,0,[\"goToIndex\"]]],null]]]],[0,\"\\n\\n\"],[5,\"layout/wrapper\",[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,0,[\"logs\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"margin-bottom-2 border-bottom\"],[8],[0,\"\\n\"],[0,\"      \"],[5,\"logs/log\",[],[[\"@log\",\"@onToggle\",\"@isOpen\"],[[23,1,[]],[28,\"action\",[[23,0,[]],[23,0,[\"toggleLog\"]]],null],[28,\"eq\",[[23,0,[\"uuid\"]],[23,1,[\"meta\",\"uuid\"]]],null]]]],[0,\"\\n\"],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"    \"],[5,\"info-message\",[],[[\"@type\"],[\"info\"]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"There are currently no logs available.\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[23,0,[\"hasLogs\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"margin-top-4 text-right\"],[8],[0,\"\\n      \"],[5,\"c-button\",[],[[\"@type\",\"@label\",\"@action\"],[\"secondary\",[28,\"t\",[\"Clear all logs\"],null],[28,\"action\",[[23,0,[]],[23,0,[\"clearLogs\"]]],null]]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-origin-app/logs/template.hbs"
    }
  });
  _exports.default = _default;
});