define("cropster-origin-app/initializers/iphone-browser", ["exports", "ember-cropster-common/initializers/iphone-browser"], function (_exports, _iphoneBrowser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _iphoneBrowser.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function () {
      return _iphoneBrowser.initialize;
    }
  });
});