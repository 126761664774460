define("cropster-origin-app/models/reception", ["exports", "ember-cp-validations", "cropster-origin-app/models/abstract", "cropster-origin-app/services/origin-settings"], function (_exports, _emberCpValidations, _abstract, _originSettings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Validations = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const Validations = (0, _emberCpValidations.buildValidations)({
    receptionDate: {
      description: Ember.computed(function () {
        return this.model.l10n.pt('The reception date', 'Filled in as {{description}} for validations');
      }),
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    coffeeForm: {
      description: Ember.computed(function () {
        return this.model.l10n.pt('The received state', 'Filled in as {{description}} for validations');
      }),
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    receptionItems: {
      description: Ember.computed(function () {
        return this.model.l10n.pt('The reception items', 'Filled in as {{description}} for validations');
      }),
      validators: [(0, _emberCpValidations.validator)('inline', {
        validate(receptionItems) {
          if (receptionItems.length === 0) {
            return this.model.l10n.t('At least one supplier is required');
          }
          return true;
        },
        dependentKeys: ['model.receptionItems.length']
      }), (0, _emberCpValidations.validator)('has-many')]
    },
    batches: {
      description: Ember.computed(function () {
        return this.model.l10n.pt('The batches', 'Filled in as {{description}} for validations');
      }),
      validators: [(0, _emberCpValidations.validator)('inline', {
        validate(batches) {
          if (batches.length === 0) {
            return this.model.l10n.t('At least one batch is required');
          }
          return true;
        },
        dependentKeys: ['model.batches.length']
      }), (0, _emberCpValidations.validator)('inline', {
        validate(batches) {
          const {
            defaultBatchDivisionType
          } = this.model.get('originSettings');
          if (defaultBatchDivisionType === _originSettings.DEFAULT_BATCH_DIVISION_AUTO) {
            return true;
          }
          if (batches.every(batch => batch.receivedWeight && !!batch.receivedWeight.unit)) {
            return true;
          }
          return this.model.l10n.t('Each batch division must have a value!');
        },
        disabled: Ember.computed('model.hasBatchDivision', function () {
          return !this.model.get('hasBatchDivision');
        }),
        dependentKeys: ['model.hasBatchDivision', 'model.batches.@each.receivedWeight']
      }), (0, _emberCpValidations.validator)('has-many')]
    },
    group: {
      description: Ember.computed(function () {
        return this.model.l10n.pt('The group', 'Filled in as {{description}} for validations');
      }),
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    facility: {
      description: Ember.computed(function () {
        return this.model.l10n.pt('The facility', 'Filled in as {{description}} for validations');
      }),
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });
  _exports.Validations = Validations;
  let Reception = (_dec = Ember.inject.service, _dec2 = Ember.computed.readOnly('originSettings.weightUnit'), _dec3 = Ember.computed.readOnly('originSettings.currency'), _dec4 = Ember.computed.readOnly('batches.firstObject'), _dec5 = Ember.computed.gt('batches.length', 1), _dec6 = Ember.computed('receptionItems.@each.total'), _dec7 = Ember.computed('receptionItems.@each.normalizedWeight'), _dec8 = Ember.computed('batches.@each.normalizedReceivedWeight'), (_class = class Reception extends _abstract.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "originSettings", _descriptor, this);
      _initializerDefineProperty(this, "weightUnit", _descriptor2, this);
      _initializerDefineProperty(this, "currency", _descriptor3, this);
      _initializerDefineProperty(this, "referenceBatch", _descriptor4, this);
      _initializerDefineProperty(this, "hasBatchDivision", _descriptor5, this);
    }
    get totalPrice() {
      return this._calculateTotal(this.receptionItems, 'total');
    }
    get totalWeight() {
      return this._calculateTotal(this.receptionItems, 'normalizedWeight');
    }
    get receivedWeight() {
      return this._calculateTotal(this.batches, 'normalizedReceivedWeight');
    }
    _calculateTotal(collection, property) {
      let totalAmount = 0;
      let totalUnit = null;
      for (let {
        [property]: {
          amount,
          unit
        }
      } of collection) {
        totalAmount += amount;
        totalUnit = unit;
      }
      if (totalAmount > 0) {
        return {
          amount: totalAmount,
          unit: totalUnit
        };
      }
      return null;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "originSettings", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "weightUnit", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currency", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "referenceBatch", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "hasBatchDivision", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "totalPrice", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "totalPrice"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "totalWeight", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "totalWeight"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "receivedWeight", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "receivedWeight"), _class.prototype)), _class));
  _exports.default = Reception;
});