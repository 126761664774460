define("cropster-origin-app/initializers/sync-remote-to-memory-strategy", ["exports", "cropster-origin-app/orbit/strategies/sync-remote-to-memory"], function (_exports, _syncRemoteToMemory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    application.register('service:sync-remote-to-memory', _syncRemoteToMemory.default, {
      instantiate: false
    });
  }
  var _default = {
    name: 'sync-remote-to-memory',
    initialize: initialize
  };
  _exports.default = _default;
});