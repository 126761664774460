define("cropster-origin-app/services/indexeddb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  /**
   * Indexeddb service for importing and exporting data.
   */
  let IndexeddbService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember.inject.service, _dec10 = Ember.inject.service, _dec11 = Ember.inject.service, _dec12 = Ember.inject.service, _dec13 = Ember.inject.service, (_class = class IndexeddbService extends Ember.Service {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "auth", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "storage", _descriptor3, this);
      _initializerDefineProperty(this, "activeUser", _descriptor4, this);
      _initializerDefineProperty(this, "activeGroup", _descriptor5, this);
      _initializerDefineProperty(this, "coordinator", _descriptor6, this);
      _initializerDefineProperty(this, "backupSource", _descriptor7, this);
      _initializerDefineProperty(this, "memorySource", _descriptor8, this);
      _initializerDefineProperty(this, "remoteSource", _descriptor9, this);
      _initializerDefineProperty(this, "networkStatus", _descriptor10, this);
      _initializerDefineProperty(this, "originSettings", _descriptor11, this);
      _initializerDefineProperty(this, "synchronization", _descriptor12, this);
      _initializerDefineProperty(this, "receptionFactory", _descriptor13, this);
    }
    async export(db) {
      (false && !(db instanceof IDBDatabase) && Ember.assert('`db` must be an instance of IDBDatabase!', db instanceof IDBDatabase));
      const promiseCallback = (resolve, reject) => {
        const json = {};
        const {
          objectStoreNames
        } = db;
        const {
          length: storeTotal
        } = objectStoreNames;
        if (storeTotal === 0) {
          return json;
        }
        const transaction = db.transaction(objectStoreNames, 'readonly');
        transaction.onerror = reject;
        for (const storeName of objectStoreNames) {
          const table = {};
          const onSuccess = _ref => {
            let {
              target: {
                result: cursor
              }
            } = _ref;
            if (cursor) {
              const {
                key,
                value
              } = cursor;
              table[key] = value;
              cursor.continue();
              return;
            }
            json[storeName] = table;
            const {
              length: storeCount
            } = Object.keys(json);
            if (storeCount === storeTotal) {
              resolve(json);
            }
          };
          const store = transaction.objectStore(storeName);
          const cursor = store.openCursor();
          cursor.onsuccess = onSuccess;
        }
      };
      return new Promise(promiseCallback);
    }
    async import(db) {
      let json = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      (false && !(db instanceof IDBDatabase) && Ember.assert('`db` must be an instance of IDBDatabase!', db instanceof IDBDatabase));
      (false && !(typeof json === 'object' && json !== null) && Ember.assert('`json` must be a valid json object!', typeof json === 'object' && json !== null));
      const promiseCallback = (resolve, reject) => {
        const {
          objectStoreNames
        } = db;
        const {
          length: storeTotal
        } = objectStoreNames;
        const transaction = db.transaction(objectStoreNames, 'readwrite');
        transaction.onerror = reject;
        for (const storeName of objectStoreNames) {
          (false && !(typeof json[storeName] === 'object' && json[storeName] !== null) && Ember.assert('`json` must contain valid object store names!', typeof json[storeName] === 'object' && json[storeName] !== null));
          let valueCount = 0;
          let storeCount = 0;
          const table = json[storeName];
          const {
            length: valueTotal
          } = Object.keys(json[storeName]);
          for (const key in table) {
            const onSuccess = () => {
              valueCount++;
              if (valueCount === valueTotal) {
                storeCount++;
                if (storeCount === storeTotal) {
                  resolve();
                }
              }
            };
            const store = transaction.objectStore(storeName);
            const request = !store.keyPath ? store.put(table[key], key) : store.put(table[key]);
            request.onsuccess = onSuccess;
          }
        }
      };
      return new Promise(promiseCallback);
    }
    async clear(db) {
      (false && !(db instanceof IDBDatabase) && Ember.assert('`db` must be an instance of IDBDatabase!', db instanceof IDBDatabase));
      const promiseCallback = (resolve, reject) => {
        const {
          objectStoreNames
        } = db;
        const {
          length: storeTotal
        } = objectStoreNames;
        const transaction = db.transaction(objectStoreNames, 'readwrite');
        transaction.onerror = reject;
        let storeCount = 0;
        for (const storeName of objectStoreNames) {
          const onSuccess = () => {
            storeCount++;
            if (storeCount === storeTotal) {
              resolve();
            }
          };
          const store = transaction.objectStore(storeName);
          const request = store.clear();
          request.onsuccess = onSuccess;
        }
      };
      return new Promise(promiseCallback);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "auth", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "storage", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "activeUser", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "activeGroup", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "coordinator", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "backupSource", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "memorySource", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "remoteSource", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "networkStatus", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "originSettings", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "synchronization", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "receptionFactory", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = IndexeddbService;
});