define("cropster-origin-app/components/batches/reception-form/batch-division-form/component", ["exports", "cropster-origin-app/services/store", "cropster-origin-app/models/reception", "cropster-origin-app/utils/enums", "cropster-origin-app/services/origin-settings"], function (_exports, _store, _reception, _enums, _originSettings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let BatchDivisionForm = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed.alias('reception.batches'), _dec6 = Ember.computed.gt('batches.length', 1), _dec7 = Ember.computed.readOnly('originSettings.defaultBatchDivisions'), _dec8 = Ember.computed('processes.[]', 'reception.coffeeForm'), _dec9 = Ember.computed.bool('reception.coffeeForm'), _dec10 = Ember.computed.not('hasCoffeeForm'), _dec11 = Ember.computed.equal('processesByCoffeeForm.length', 0), _dec12 = Ember.computed.or('hasNoCoffeeForm', 'hasNoProcessesByCoffeeForm'), _dec13 = Ember.computed.readOnly('originSettings.defaultValues'), _dec14 = Ember.computed.readOnly('originSettings.defaultReceivedUnit'), _dec15 = Ember.computed.readOnly('originSettings.defaultBatchDivisionType'), _dec16 = Ember.computed('defaultBatchDivisions.@each.isDividedBySupplier'), _dec17 = Ember.computed('originSettings.defaultBatchDivisionType'), _dec18 = Ember.computed('reception.referenceBatch.receivedWeight.unit', 'defaultReceivedUnit', 'defaultBatchDivisionType'), _dec19 = Ember.computed('reception.referenceBatch.intermediateWeight.unit', 'originSettings.defaultValues.weightUnit'), _dec20 = Ember.computed('reception.referenceBatch.receivedWeight.unit', 'originSettings.defaultBatchDivisionType'), _dec21 = Ember._action, _dec22 = Ember._action, _dec23 = Ember._action, _dec24 = Ember._action, _dec25 = Ember._action, _dec26 = Ember._action, _dec27 = Ember._action, (_class = class BatchDivisionForm extends Ember.Component {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "activeGroup", _descriptor2, this);
      _initializerDefineProperty(this, "originSettings", _descriptor3, this);
      _initializerDefineProperty(this, "receptionFactory", _descriptor4, this);
      //
      // Arguments
      //
      _defineProperty(this, "source", null);
      _defineProperty(this, "reception", null);
      _defineProperty(this, "processes", []);
      //
      // Properties
      //
      _defineProperty(this, "weightUnits", _enums.WEIGHT_UNIT_OPTIONS);
      _initializerDefineProperty(this, "batches", _descriptor5, this);
      _initializerDefineProperty(this, "hasBatchDivision", _descriptor6, this);
      _initializerDefineProperty(this, "defaultBatchDivisions", _descriptor7, this);
      _initializerDefineProperty(this, "hasCoffeeForm", _descriptor8, this);
      _initializerDefineProperty(this, "hasNoCoffeeForm", _descriptor9, this);
      _initializerDefineProperty(this, "hasNoProcessesByCoffeeForm", _descriptor10, this);
      _initializerDefineProperty(this, "hasNoProcessesAvailableForCoffeeForm", _descriptor11, this);
      _initializerDefineProperty(this, "defaultValues", _descriptor12, this);
      _initializerDefineProperty(this, "defaultReceivedUnit", _descriptor13, this);
      _initializerDefineProperty(this, "defaultBatchDivisionType", _descriptor14, this);
    }
    onChangeFactor( /* batch, measure */) {}
    get processesByCoffeeForm() {
      return this.processes.filter(_ref => {
        let {
          receivedForm
        } = _ref;
        return receivedForm === this.reception.coffeeForm;
      });
    }
    get isDividedBySupplier() {
      return this.defaultBatchDivisions && this.defaultBatchDivisions.any(_ref2 => {
        let {
          isDividedBySupplier
        } = _ref2;
        return isDividedBySupplier;
      });
    }
    get isNotAutoCalc() {
      if (this.isDividedBySupplier) {
        return true;
      }
      return Ember.get(this, 'originSettings.defaultBatchDivisionType') !== _originSettings.DEFAULT_BATCH_DIVISION_AUTO;
    }
    get divisionUnit() {
      if (this.defaultBatchDivisionType === _originSettings.DEFAULT_BATCH_DIVISION_PERCENTAGE) {
        return '%';
      }
      return this.defaultReceivedUnit;
    }
    get storedWeightUnit() {
      const intermediateWeight = Ember.get(this, 'reception.referenceBatch.intermediateWeight');
      if (intermediateWeight) {
        return Ember.get(intermediateWeight, 'unit');
      }
      return Ember.get(this, 'originSettings.defaultValues.weightUnit');
    }
    get divisionUnits() {
      const divisionType = Ember.get(this, 'originSettings.defaultBatchDivisionType');
      if (divisionType === _originSettings.DEFAULT_BATCH_DIVISION_PERCENTAGE) {
        return [];
      }
      return Ember.get(this, 'weightUnits');
    }

    //
    // Hooks
    //
    init() {
      super.init(...arguments);
      (false && !((0, _store.isSource)(this.source)) && Ember.assert('`source` must be a source instance!', (0, _store.isSource)(this.source)));
      (false && !(this.reception instanceof _reception.default) && Ember.assert('`reception` must be a reception model!', this.reception instanceof _reception.default));
      (false && !(Array.isArray(this.processes)) && Ember.assert('`processes` must be an array!', Array.isArray(this.processes)));
    }

    // Actions
    //
    changeFactor(batch, amount) {
      // proxy factor to settable property
      Ember.set(batch, 'factor', amount);

      // factor must be handled in HOC:
      // @see: <Batches::ReceptionForm>
      this.onChangeFactor(batch, amount);
    }
    async selectProcess(batch, process) {
      await this.store.replaceRelatedRecord(this.source, batch, 'process', process);
    }
    async setReceivedWeight(batch, receivedWeight) {
      await this.store.replaceAttribute(this.source, batch, 'receivedWeight', receivedWeight);
    }
    async setIsPiled(batch, isPiled) {
      await this.store.replaceAttribute(this.source, batch, 'isPiled', isPiled);
    }
    async setStorageDate(batch, storageDate) {
      await this.store.replaceAttribute(this.source, batch, 'storageDate', storageDate);
    }
    async setIntermediateWeight(batch, intermediateWeight) {
      await this.store.replaceAttribute(this.source, batch, 'intermediateWeight', intermediateWeight);
    }
    async setIntermediateWeightUnit(intermediateWeightUnit) {
      Ember.set(this, 'originSettings.defaultValues', {
        ...this.defaultValues,
        ...{
          weightUnit: intermediateWeightUnit
        }
      });
      await this.originSettings.saveUserSettings();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "activeGroup", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "originSettings", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "receptionFactory", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "batches", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "hasBatchDivision", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "defaultBatchDivisions", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "processesByCoffeeForm", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "processesByCoffeeForm"), _class.prototype), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "hasCoffeeForm", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "hasNoCoffeeForm", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "hasNoProcessesByCoffeeForm", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "hasNoProcessesAvailableForCoffeeForm", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "defaultValues", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "defaultReceivedUnit", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "defaultBatchDivisionType", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isDividedBySupplier", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "isDividedBySupplier"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isNotAutoCalc", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "isNotAutoCalc"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "divisionUnit", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "divisionUnit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "storedWeightUnit", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "storedWeightUnit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "divisionUnits", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "divisionUnits"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeFactor", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "changeFactor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectProcess", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "selectProcess"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setReceivedWeight", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "setReceivedWeight"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setIsPiled", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "setIsPiled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setStorageDate", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "setStorageDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setIntermediateWeight", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "setIntermediateWeight"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setIntermediateWeightUnit", [_dec27], Object.getOwnPropertyDescriptor(_class.prototype, "setIntermediateWeightUnit"), _class.prototype)), _class));
  _exports.default = BatchDivisionForm;
});