define("cropster-origin-app/orbit/schemas/login-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    attributes: {
      userId: {
        type: 'string'
      },
      groupId: {
        type: 'string'
      },
      groupMembershipId: {
        type: 'string'
      }
    }
  };
  _exports.default = _default;
});