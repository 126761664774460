define("cropster-origin-app/utils/type-guards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isBoolean = isBoolean;
  _exports.isMeasure = isMeasure;
  _exports.isNone = isNone;
  _exports.isNull = isNull;
  _exports.isNumber = isNumber;
  _exports.isObject = isObject;
  _exports.isString = isString;
  _exports.isUndefined = isUndefined;
  function isUndefined(value) {
    return typeof value === 'undefined';
  }
  function isBoolean(value) {
    return typeof value === 'boolean';
  }
  function isNumber(value) {
    return typeof value === 'number';
  }
  function isString(value) {
    return typeof value === 'string';
  }
  function isNull(value) {
    return value === null;
  }
  function isNone(value) {
    return isUndefined(value) || isNull(value);
  }
  function isObject(value) {
    return !isNull(value) && typeof value === 'object';
  }
  function isMeasure(value) {
    return isObject(value) && !Array.isArray(value) && isNumber(value?.amount) && isString(value?.unit);
  }
});