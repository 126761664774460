define("cropster-origin-app/utils/formatter", ["exports", "cropster-origin-app/utils/enums"], function (_exports, _enums) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatWeight = _exports.formatCurrency = void 0;
  const formatWeight = (amount, unit, _ref) => {
    let {
      minimumFractionDigits = 0,
      maximumFractionDigits = 2,
      translateUnit = unit => unit
    } = _ref;
    if (typeof amount !== 'number' || typeof unit !== 'string') {
      return null;
    }
    let separator;
    switch (unit) {
      case _enums.WEIGHT_UNIT_G:
      case _enums.WEIGHT_UNIT_KG:
      case _enums.WEIGHT_UNIT_OZ:
      case _enums.WEIGHT_UNIT_LBS:
        separator = ' ';
        break;
      default:
        separator = ' x ';
    }
    amount = amount.toLocaleString(undefined, {
      minimumFractionDigits,
      maximumFractionDigits
    });
    unit = typeof translateUnit === 'function' ? translateUnit(unit) : unit;
    return `${amount}${separator}${unit}`;
  };
  _exports.formatWeight = formatWeight;
  const formatCurrency = (amount, unit, _ref2) => {
    let {
      minimumFractionDigits = 0,
      maximumFractionDigits = 2,
      translateUnit = unit => unit
    } = _ref2;
    if (typeof amount !== 'number' || typeof unit !== 'string') {
      return null;
    }
    amount = amount.toLocaleString(undefined, {
      minimumFractionDigits,
      maximumFractionDigits
    });
    unit = typeof translateUnit === 'function' ? translateUnit(unit) : unit;
    return `${amount} ${unit}`;
  };
  _exports.formatCurrency = formatCurrency;
});