define("cropster-origin-app/utils/parser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parseWeightUnit = _exports.parseMeasure = void 0;
  const WEIGHT_UNIT_REGEX = /^(?:([A-Z]+)([\d_]+))?([A-Z]+)$/;
  const parseMeasure = measure => {
    if (typeof measure !== 'object' || measure === null) {
      return null;
    }
    if (typeof measure.amount !== 'number') {
      throw new Error(`'amount' of measure must be a number!`);
    }
    if (typeof measure.unit !== 'string') {
      throw new Error(`'unit' of measure must be a string!`);
    }
    return measure;
  };
  _exports.parseMeasure = parseMeasure;
  const parseWeightUnit = weightUnit => {
    if (typeof weightUnit !== 'string') {
      throw new Error(`'weightUnit' must be a string!`);
    }
    const match = weightUnit.match(WEIGHT_UNIT_REGEX);
    if (match === null) {
      throw new Error(`'${weightUnit}' is not a valid unit!`);
    }
    let [, type = null, size = 1, unit] = match;
    size = +`${size}`.replace(/_/, '.');
    return {
      type,
      size,
      unit
    };
  };
  _exports.parseWeightUnit = parseWeightUnit;
});