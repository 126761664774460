define("cropster-origin-app/components/form/input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TYPE_TEXT = _exports.TYPE_RADIO = _exports.TYPE_PASSWORD = _exports.TYPE_NUMBER = _exports.TYPE_DATE = _exports.TYPE_CHECKBOX = void 0;
  var _dec, _dec2, _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const TYPE_TEXT = 'text';
  _exports.TYPE_TEXT = TYPE_TEXT;
  const TYPE_DATE = 'date';
  _exports.TYPE_DATE = TYPE_DATE;
  const TYPE_NUMBER = 'number';
  _exports.TYPE_NUMBER = TYPE_NUMBER;
  const TYPE_RADIO = 'radio';
  _exports.TYPE_RADIO = TYPE_RADIO;
  const TYPE_CHECKBOX = 'checkbox';
  _exports.TYPE_CHECKBOX = TYPE_CHECKBOX;
  const TYPE_PASSWORD = 'password';
  _exports.TYPE_PASSWORD = TYPE_PASSWORD;
  let Input = (_dec = Ember.computed('type'), _dec2 = Ember.computed('value', 'type'), (_class = class Input extends Ember.Component {
    constructor() {
      super(...arguments);
      _defineProperty(this, "tagName", 'input');
      _defineProperty(this, "attributeBindings", ['domId:id', 'min', 'max', 'name', 'list', 'step', 'type', 'parsedValue:value', 'checked', 'disabled', 'pattern', 'placeholder']);
      //
      // Arguments
      //
      _defineProperty(this, "value", null);
      _defineProperty(this, "type", TYPE_TEXT);
      _defineProperty(this, "disabled", false);
      _defineProperty(this, "min", 0);
      _defineProperty(this, "step", 'any');
      _defineProperty(this, "max", Number.POSITIVE_INFINITY);
    }
    onChange() {}
    onInput() {}

    //
    // Properties
    //
    get pattern() {
      switch (this.type) {
        case TYPE_DATE:
          return '\\d{4}-\\d{2}-\\d{2}';
        default:
          return null;
      }
    }
    get parsedValue() {
      const {
        value
      } = this;
      switch (this.type) {
        case TYPE_DATE:
          if (value instanceof Date) {
            const yyyy = value.getFullYear();
            const mm = `0${value.getMonth() + 1}`.slice(-2);
            const dd = `0${value.getDate()}`.slice(-2);
            return `${yyyy}-${mm}-${dd}`;
          }
          return value;
        default:
          return value;
      }
    }

    //
    // Methods
    //
    _readValue() {
      switch (this.type) {
        case TYPE_NUMBER:
          {
            const number = this.element.valueAsNumber;
            return typeof number === 'number' ? number : undefined;
          }
        case TYPE_DATE:
          {
            const date = this.element.valueAsDate;
            return date instanceof Date && date || undefined;
          }
        case TYPE_CHECKBOX:
          return this.element.checked;
        default:
          return this.element.value;
      }
    }

    //
    // Events
    //
    input() {
      switch (this.type) {
        case TYPE_CHECKBOX:
        case TYPE_RADIO:
          break;
        default:
          this.onInput(this._readValue());
      }
    }
    change() {
      this.onChange(this._readValue());
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "pattern", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "pattern"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "parsedValue", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "parsedValue"), _class.prototype)), _class));
  _exports.default = Input;
});