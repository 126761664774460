define("cropster-origin-app/components/layout/card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "1B8Bf6SI",
    "block": "{\"symbols\":[\"card\",\"&default\"],\"statements\":[[4,\"let\",[[28,\"hash\",null,[[\"header\",\"body\"],[[28,\"component\",[\"layout/card/header\"],null],[28,\"component\",[\"layout/card/body\"],null]]]]],null,{\"statements\":[[0,\"  \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-origin-app/components/layout/card/template.hbs"
    }
  });
  _exports.default = _default;
});