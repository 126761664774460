define("cropster-origin-app/groups/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let GroupsController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed.readOnly('model'), _dec6 = Ember.computed.readOnly('networkStatus.isOffline'), _dec7 = Ember._action, _dec8 = Ember._action, (_class = class GroupsController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "l10n", _descriptor, this);
      _initializerDefineProperty(this, "toast", _descriptor2, this);
      _initializerDefineProperty(this, "activeGroup", _descriptor3, this);
      _initializerDefineProperty(this, "networkStatus", _descriptor4, this);
      //
      // Properties
      //
      _initializerDefineProperty(this, "groups", _descriptor5, this);
      _initializerDefineProperty(this, "isOffline", _descriptor6, this);
    }
    //
    // Actions
    //
    goToIndex() {
      this.transitionToRoute('index');
    }
    onSelect(_ref) {
      let {
        name
      } = _ref;
      this.toast.success(this.l10n.t('Successfully changed group to: {{groupName}}', {
        groupName: name
      }));
      this.goToIndex();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "l10n", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "activeGroup", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "networkStatus", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "groups", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isOffline", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "goToIndex", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "goToIndex"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelect", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onSelect"), _class.prototype)), _class));
  _exports.default = GroupsController;
});