define("cropster-origin-app/helpers/pn", ["exports", "ember-l10n/helpers/pn"], function (_exports, _pn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _pn.default;
    }
  });
});