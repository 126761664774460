define("cropster-origin-app/transforms/timestamp", ["exports", "ember-data/transform", "moment"], function (_exports, _transform, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.deserialize = deserialize;
  _exports.serialize = serialize;
  function deserialize(timestamp) {
    if (!timestamp || typeof timestamp !== 'number') {
      return null;
    }
    let date = (0, _moment.default)(timestamp);
    return date;
  }
  function serialize(date) {
    if (!date) {
      return null;
    }
    return date.valueOf();
  }
  var _default = _transform.default.extend({
    deserialize,
    serialize
  });
  _exports.default = _default;
});