define("cropster-origin-app/batches/create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "vALCNssM",
    "block": "{\"symbols\":[],\"statements\":[[5,\"layout/header\",[],[[\"@title\",\"@onGoBack\",\"@syncDisplayed\",\"@displayMenu\",\"@displayAdd\",\"@addFunction\"],[[23,0,[\"pageTitle\"]],[28,\"action\",[[23,0,[]],[23,0,[\"cancel\"]]],null],false,false,false,[23,0,[\"addReceptionItem\"]]]]],[0,\"\\n\\n\"],[5,\"layout/wrapper\",[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"isAddingSupplier\"]]],null,{\"statements\":[[0,\"    \"],[5,\"batches/supplier-form\",[],[[\"@source\",\"@reception\",\"@receptionItems\",\"@disableSupplierAdding\",\"@deleteReceptionItems\",\"@distributeReceptionWeight\"],[[23,0,[\"source\"]],[23,0,[\"reception\"]],[23,0,[\"focusedReceptionItems\"]],[28,\"action\",[[23,0,[]],[23,0,[\"disableSupplierAdding\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"deleteReceptionItems\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"distributeReceptionWeight\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"batches/reception-form\",[],[[\"@isNew\",\"@source\",\"@reception\",\"@onSave\",\"@onCancel\",\"@addReceptionItem\",\"@focusReceptionItems\",\"@updateBatchReceivedWeightByFactor\"],[true,[23,0,[\"source\"]],[23,0,[\"reception\"]],[28,\"action\",[[23,0,[]],[23,0,[\"save\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"cancel\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"addReceptionItem\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"focusReceptionItems\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"updateBatchReceivedWeightByFactor\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-origin-app/batches/create/template.hbs"
    }
  });
  _exports.default = _default;
});