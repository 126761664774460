define("cropster-origin-app/orbit/schema", ["exports", "@orbit/data", "cropster-origin-app/orbit/schemas/contact", "cropster-origin-app/orbit/schemas/contact-role", "cropster-origin-app/orbit/schemas/group", "cropster-origin-app/orbit/schemas/group-membership", "cropster-origin-app/orbit/schemas/login-info", "cropster-origin-app/orbit/schemas/user", "cropster-origin-app/orbit/schemas/permission", "cropster-origin-app/orbit/schemas/project", "cropster-origin-app/orbit/schemas/property", "cropster-origin-app/orbit/schemas/variety", "cropster-origin-app/orbit/schemas/batch", "cropster-origin-app/orbit/schemas/facility", "cropster-origin-app/orbit/schemas/process", "cropster-origin-app/orbit/schemas/reception", "cropster-origin-app/orbit/schemas/reception-item", "cropster-origin-app/orbit/inflections", "cropster-origin-app/orbit/utils/generate-id"], function (_exports, _data, _contact, _contactRole, _group, _groupMembership, _loginInfo, _user, _permission, _project, _property, _variety, _batch, _facility, _process, _reception, _receptionItem, _inflections, _generateId) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // core

  // origin

  const schema = new _data.Schema({
    models: {
      contact: _contact.default,
      contactRole: _contactRole.default,
      user: _user.default,
      group: _group.default,
      groupMembership: _groupMembership.default,
      loginInfo: _loginInfo.default,
      permission: _permission.default,
      project: _project.default,
      property: _property.default,
      variety: _variety.default,
      batch: _batch.default,
      facility: _facility.default,
      process: _process.default,
      reception: _reception.default,
      receptionItem: _receptionItem.default
    },
    generateId: _generateId.default,
    pluralize: word => _inflections.default[word],
    singularize: word => _inflections.default[word]
  });
  var _default = schema;
  _exports.default = _default;
});