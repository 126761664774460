define("cropster-origin-app/orbit/strategies/sync-change-to-memory", ["exports", "@orbit/coordinator"], function (_exports, _coordinator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const syncChangeToMemoryStrategy = new _coordinator.SyncStrategy({
    source: 'change',
    target: 'memory',
    blocking: true
  });
  var _default = syncChangeToMemoryStrategy;
  _exports.default = _default;
});