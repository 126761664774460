define("cropster-origin-app/components/form/abstract/checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "UzGPec3v",
    "block": "{\"symbols\":[\"@label\",\"&default\",\"@name\",\"@value\",\"@checked\",\"@disabled\",\"@onChange\"],\"statements\":[[5,\"form/input\",[],[[\"@name\",\"@value\",\"@domId\",\"@checked\",\"@disabled\",\"@type\",\"@classNames\",\"@onChange\"],[[23,3,[]],[23,4,[]],[23,0,[\"id\"]],[23,5,[]],[23,6,[]],[23,0,[\"inputType\"]],[23,0,[\"inputClass\"]],[28,\"action\",[[23,0,[]],[28,\"optional-action\",[[23,7,[]]],null]],null]]]],[0,\"\\n\\n\"],[7,\"label\",true],[11,\"for\",[23,0,[\"id\"]]],[8],[0,\"\\n\"],[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"    \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-origin-app/components/form/abstract/checkbox/template.hbs"
    }
  });
  _exports.default = _default;
});