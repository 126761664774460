define("cropster-origin-app/models/abstract", ["exports", "ember-cp-validations", "cropster-origin-app/services/store"], function (_exports, _emberCpValidations, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Validator = _exports.Validations = _exports.Model = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  const Validator = Ember.ObjectProxy.extend({
    // makes l10n in `buildValidations()` when
    // using `this.model.l10n`, bound in proxy
    // @see: app/services/model-factory.js
    l10n: Ember.inject.service()
  });
  _exports.Validator = Validator;
  const Validations = (0, _emberCpValidations.buildValidations)({});

  /**
   * Abstract model class for all Orbit.js schema definitions.
   * Model holds state about source, record and custom validator.
   * It also enables DI via owner configuration to inject services.
   */
  _exports.Validations = Validations;
  class Model {
    constructor(owner, source, record, validator) {
      _defineProperty(this, "_source", null);
      _defineProperty(this, "_record", null);
      _defineProperty(this, "_validator", null);
      (false && !(owner instanceof Ember.ApplicationInstance || owner instanceof Ember.EngineInstance) && Ember.assert('`owner` must be an application or engine instance!', owner instanceof Ember.ApplicationInstance || owner instanceof Ember.EngineInstance));
      (false && !((0, _store.isSource)(source)) && Ember.assert('`source` must be a source!', (0, _store.isSource)(source)));
      (false && !((0, _store.isRecord)(record)) && Ember.assert('`record` must be a source!', (0, _store.isRecord)(record)));
      (false && !(validator instanceof Validator) && Ember.assert('`validator` must be an instance of Validator!', validator instanceof Validator)); // enable DI for models
      // @todo(apf): pattern?
      Ember.setOwner(this, owner);
      this._record = record;
      this._source = source;
      this._validator = validator;
    }
    get validations() {
      return this._validator.validations;
    }
    getSource() {
      return this._source;
    }
    getRecord() {
      return this._record;
    }
    fromJSON(record) {
      (false && !((0, _store.isRecord)(record)) && Ember.assert('`record` must be a source!', (0, _store.isRecord)(record)));
      this._record = record;
    }
    toJSON() {
      return this.getRecord();
    }
  }
  _exports.Model = Model;
  var _default = Model;
  _exports.default = _default;
});