define("cropster-origin-app/orbit/sources/change", ["exports", "@orbit/indexeddb", "@orbit/local-storage", "cropster-origin-app/orbit/schema", "cropster-origin-app/orbit/bucket", "cropster-origin-app/orbit/sources/key-map"], function (_exports, _indexeddb, _localStorage, _schema, _bucket, _keyMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SourceClass = (0, _indexeddb.supportsIndexedDB)() ? _indexeddb.default : _localStorage.default;
  const source = new SourceClass({
    schema: _schema.default,
    bucket: _bucket.default,
    keyMap: _keyMap.default,
    name: 'change',
    namespace: 'origin-app-change'
  });
  var _default = source;
  _exports.default = _default;
});