define("cropster-origin-app/helpers/get-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getValue = getValue;
  function getValue(_ref, _ref2) {
    let [obj, key] = _ref;
    let {
      nested
    } = _ref2;
    if (typeof obj !== 'object' || obj === null) {
      return undefined;
    }
    if (nested) {
      return Ember.get(obj, key);
    }
    return obj[key] || undefined;
  }
  var _default = Ember.Helper.helper(getValue);
  _exports.default = _default;
});