define("cropster-origin-app/orbit/inflections/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Inflect = {
    contact: 'contacts',
    contacts: 'contact',
    contactRole: 'contactRoles',
    contactRoles: 'contactRole',
    group: 'groups',
    groups: 'group',
    groupMembership: 'groupMemberships',
    groupMemberships: 'groupMembership',
    user: 'users',
    users: 'user',
    permission: 'permissions',
    permissions: 'permission',
    project: 'projects',
    projects: 'project',
    property: 'properties',
    properties: 'property',
    variety: 'varieties',
    varieties: 'variety',
    batch: 'batches',
    batches: 'batch',
    facility: 'facilities',
    facilities: 'facility',
    process: 'processes',
    processes: 'process',
    reception: 'receptions',
    receptions: 'reception',
    receptionItem: 'receptionItems',
    receptionItems: 'receptionItem',
    // special endpoints, no plurals
    loginInfo: 'loginInfo'
  };
  var _default = Inflect;
  _exports.default = _default;
});