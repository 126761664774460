define("cropster-origin-app/orbit/sources/memory", ["exports", "@orbit/memory", "cropster-origin-app/orbit/schema", "cropster-origin-app/orbit/bucket", "cropster-origin-app/orbit/sources/key-map"], function (_exports, _memory, _schema, _bucket, _keyMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const source = new _memory.default({
    schema: _schema.default,
    bucket: _bucket.default,
    keyMap: _keyMap.default,
    name: 'memory'
  });
  var _default = source;
  _exports.default = _default;
});