define("cropster-origin-app/orbit/schemas/contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    keys: {
      remoteId: {}
    },
    attributes: {
      name: {
        type: 'string'
      },
      idTag: {
        type: 'string'
      },
      companyName: {
        type: 'string'
      }
    },
    relationships: {
      group: {
        type: 'hasOne',
        model: 'group'
      },
      contactRoles: {
        type: 'hasMany',
        model: 'contactRole'
      }
    }
  };
  _exports.default = _default;
});