define("cropster-origin-app/help/controller", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  // @see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toISOString
  const DATE_REGEX = /^(?:[+-]\d{6}|\d{4})-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
  const Validations = (0, _emberCpValidations.buildValidations)({
    issue: {
      description: Ember.computed(function () {
        return this.model.l10n.pt('The issue text', 'Filled in as {{description}} for validations');
      }),
      validators: [(0, _emberCpValidations.validator)('presence', {
        ignoreBlank: true,
        presence: true
      })]
    }
  });
  let HelpController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember.inject.service, _dec10 = Ember.inject.service, _dec11 = Ember.inject.service, _dec12 = Ember.inject.service, _dec13 = Ember.inject.service, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, (_class = class HelpController extends Ember.Controller.extend(Validations) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "l10n", _descriptor, this);
      _initializerDefineProperty(this, "auth", _descriptor2, this);
      _initializerDefineProperty(this, "toast", _descriptor3, this);
      _initializerDefineProperty(this, "store", _descriptor4, this);
      _initializerDefineProperty(this, "dialog", _descriptor5, this);
      _initializerDefineProperty(this, "storage", _descriptor6, this);
      _initializerDefineProperty(this, "indexeddb", _descriptor7, this);
      _initializerDefineProperty(this, "activeUser", _descriptor8, this);
      _initializerDefineProperty(this, "activeGroup", _descriptor9, this);
      _initializerDefineProperty(this, "orbitFacade", _descriptor10, this);
      _initializerDefineProperty(this, "memorySource", _descriptor11, this);
      _initializerDefineProperty(this, "backupSource", _descriptor12, this);
      _initializerDefineProperty(this, "changeSource", _descriptor13, this);
      //
      // Properties
      //
      _defineProperty(this, "issue", null);
      _defineProperty(this, "dump", null);
      _defineProperty(this, "isDumpTooLarge", false);
      _defineProperty(this, "logHref", false);
      _defineProperty(this, "mail", 'support@cropster.com');
      _defineProperty(this, "mailTo", '');
    }
    //
    // Actions
    //
    goToIndex() {
      this.transitionToRoute('index');
    }
    issueHandler(value) {
      try {
        // if we detect a valid JSON dump, enable importing
        const customParser = (key, value) => {
          switch (typeof value) {
            case 'string':
              // JSON.stringify produces ISO 8601 date strings
              // which has then be parsed back to Date objects
              if (DATE_REGEX.test(value)) {
                return new Date(value);
              }
              return value;
            default:
              return value;
          }
        };
        const {
          storage,
          backup,
          change
        } = JSON.parse(value, customParser);
        if (storage && backup && change) {
          Ember.set(this, 'dump', {
            storage,
            backup,
            change
          });
        }
      } catch (e) {
        // noop
      }
      Ember.set(this, 'issue', value);
    }
    async sendDump() {
      try {
        const storageDB = await this.storage.openDB();
        const storage = await this.indexeddb.export(storageDB);
        const backup = await this.store.findRecords(this.backupSource);
        const change = await this.store.findRecords(this.changeSource);
        const data = {
          backup,
          change,
          storage
        };
        const dump = JSON.stringify(data);
        const {
          id: groupId
        } = this.activeGroup;
        const {
          id: userId
        } = this.activeUser;
        const line = '==================================================';
        const subject = encodeURIComponent(`Origin App Issue (User: ${userId}, Group: ${groupId})`);
        const body = encodeURIComponent(`${this.issue}\n\n${line}\n\n${dump}\n\n${line}`);
        const hrefString = `mailto:${this.mail}?subject=${subject}&body=${body}`;

        //tested this in chrome and ff, 2027 seems to be the limit
        if (hrefString.length >= 2027) {
          Ember.set(this, 'isDumpTooLarge', true);
          Ember.set(this, 'logHref', URL.createObjectURL(new Blob([dump], {
            type: 'application/json'
          })));
          Ember.set(this, 'mailTo', `mailto:${this.mail}?subject=${subject}`);
          return;
        }
        const dummy = document.createElement('a');
        dummy.href = hrefString;
        dummy.click();
      } catch (e) {
        const message = this.l10n.t('There was an error exporting your data.');
        this.toast.error(message);
      }
    }
    async cancelDump() {
      Ember.set(this, 'issue', null);
      Ember.set(this, 'dump', null);
    }
    async importDump() {
      try {
        const {
          storage,
          backup,
          change
        } = this.dump;

        // replace storage database (separately - no source)
        const storageDB = await this.storage.openDB();
        await this.indexeddb.clear(storageDB);
        await this.indexeddb.import(storageDB, storage);

        // replace memory, backup and change sources
        await this.store.resetSource(this.memorySource);
        await this.store.resetSource(this.backupSource);
        await this.store.resetSource(this.changeSource);
        await this.backupSource.sync(backup);
        await this.changeSource.sync(change);
        await this.store.syncFromSourceToTarget(this.changeSource, this.memorySource);
        await this.store.syncFromSourceToTarget(this.backupSource, this.memorySource);
        Ember.set(this, 'issue', null);
        Ember.set(this, 'dump', null);
      } catch (e) {
        const message = this.l10n.t('There was an error importing your data.');
        this.toast.error(message);
      }
    }
    async resetApp() {
      try {
        await this.dialog.confirmWarn('Are you sure you want to reset the app? All your unsynchronized data will be permanently deleted and cannot be restored!');
      } catch (e) {
        // dialog dismissed
        return;
      }
      try {
        // destroy ALL indexedDB data (including changes/storage)
        const backupDB = await this.backupSource.cache.openDB();
        const changeDB = await this.changeSource.cache.openDB();
        const storageDB = await this.storage.openDB();
        await this.indexeddb.clear(storageDB);
        await this.indexeddb.clear(backupDB);
        await this.indexeddb.clear(changeDB);

        // destroy user session to force user reauthentication
        await this.auth.invalidate();
      } catch (e) {
        const message = this.l10n.t('There was an error resetting your app.');
        this.toast.error(message);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "l10n", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "auth", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "dialog", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "storage", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "indexeddb", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "activeUser", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "activeGroup", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "orbitFacade", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "memorySource", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "backupSource", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "changeSource", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "goToIndex", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "goToIndex"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "issueHandler", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "issueHandler"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sendDump", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "sendDump"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelDump", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "cancelDump"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "importDump", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "importDump"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetApp", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "resetApp"), _class.prototype)), _class));
  _exports.default = HelpController;
});