define("cropster-origin-app/services/active-user", ["exports", "cropster-origin-app/models/facility", "cropster-origin-app/services/store", "cropster-origin-app/objects/active-entity-service", "cropster-origin-app/services/synchronization"], function (_exports, _facility, _store, _activeEntityService, _synchronization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.EVENT_CHANGED_USER = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const STORAGE_KEY = `__coa_auk__`;
  const EVENT_CHANGED_USER = 'active-user:changed';

  /**
   * Stateful service holding information about active user.
   *
   * It perists selected user's ID in local storage / indexed DB.
   *
   * The service sends the following events via `sendEvent()` method:
   *
   * - `active-user:changed`: after setting `selected` property via setter
   */
  _exports.EVENT_CHANGED_USER = EVENT_CHANGED_USER;
  let ActiveUserService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed('id', 'activeGroup.id'), _dec5 = Ember.computed('id', 'activeGroup.id'), _dec6 = Ember.computed('permissions.[]'), _dec7 = Ember.computed.and('permissionsMapByName.origin', 'permissionsMapByName.contactShow', 'permissionsMapByName.contactEdit'), _dec8 = Ember.computed.bool('facility'), _dec9 = Ember.computed.bool('permissionsMapByName.originAdministration'), _dec10 = Ember.computed.or('isFacilityManager', 'isGeneralManager'), _dec11 = Ember.computed.and('hasViewPermissions', 'hasFacility'), _dec12 = Ember.computed.readOnly('model.locale'), (_class = class ActiveUserService extends _activeEntityService.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "activeGroup", _descriptor2, this);
      _initializerDefineProperty(this, "synchronization", _descriptor3, this);
      _defineProperty(this, "modelName_", 'user');
      _defineProperty(this, "storageKey_", STORAGE_KEY);
      _defineProperty(this, "eventName_", EVENT_CHANGED_USER);
      _initializerDefineProperty(this, "isFacilityManager", _descriptor4, this);
      _initializerDefineProperty(this, "hasFacility", _descriptor5, this);
      _initializerDefineProperty(this, "isGeneralManager", _descriptor6, this);
      _initializerDefineProperty(this, "hasViewPermissions", _descriptor7, this);
      _initializerDefineProperty(this, "hasEditPermissions", _descriptor8, this);
      _initializerDefineProperty(this, "language", _descriptor9, this);
    }
    //
    // Properties
    //
    get facility() {
      // find all permissions by appropriate group memberships
      if (this.activeGroup.id !== null && this.id !== null) {
        const [facility] = this.store.findRecords(this.memorySource, 'facility', {
          filter: [{
            relation: 'facilityManager',
            record: {
              type: 'user',
              id: this.id
            }
          }, {
            relation: 'group',
            record: {
              type: 'group',
              id: this.activeGroup.id
            }
          }]
        });
        if (facility instanceof _facility.default) {
          return facility;
        }
      }
      return null;
    }
    get permissions() {
      // find all permissions by appropriate group memberships
      if (this.activeGroup.id !== null && this.id !== null) {
        const [groupMembership] = this.store.findRecords(this.memorySource, 'groupMembership', {
          filter: [{
            relation: 'group',
            record: {
              type: 'group',
              id: this.activeGroup.id
            }
          }, {
            relation: 'user',
            record: {
              type: 'user',
              id: this.id
            }
          }]
        });
        if (typeof groupMembership !== 'undefined') {
          return groupMembership.permissions;
        }
      }
      return [];
    }
    get permissionsMapByName() {
      return Object.assign({}, ...this.permissions.map(_ref => {
        let {
          name
        } = _ref;
        return {
          [name]: true
        };
      }));
    }
    //
    // Methods
    //
    async activate() {
      Ember.addListener(this.synchronization, _synchronization.EVENT_PULLED_GROUP_DATA, this, this._pulledGroupDataHandler);
    }
    async deactivate() {
      try {
        Ember.removeListener(this.synchronization, _synchronization.EVENT_PULLED_GROUP_DATA, this, this._pulledGroupDataHandler);
      } catch (e) {
        // noop
      }
    }
    getBatchesByUser(source) {
      (false && !((0, _store.isMemorySource)(source)) && Ember.assert('`source` must be a memory source!', (0, _store.isMemorySource)(source)));
      if (this.facility instanceof _facility.default) {
        return this.store.findRecords(source, 'batch', {
          filter: [{
            relation: 'facility',
            record: {
              type: 'facility',
              id: this.facility.id
            }
          }],
          sort: [{
            attribute: 'idTag',
            order: _store.SORT_DIRECTION_DESC
          }]
        });
      }
      return [];
    }
    _pulledGroupDataHandler() {
      this.notifyPropertyChange('facility');
      this.notifyPropertyChange('permissions');
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "activeGroup", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "synchronization", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "facility", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "facility"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "permissions", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "permissions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "permissionsMapByName", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "permissionsMapByName"), _class.prototype), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isFacilityManager", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "hasFacility", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isGeneralManager", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "hasViewPermissions", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "hasEditPermissions", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "language", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ActiveUserService;
});