define("cropster-origin-app/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "TGpuDzIF",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"Cropster Origin\"],null]],[[\"_deprecate\"],[\"/tmp/broccoli-829Ywf5BQh6mXL/out-203-broccoli_merge_trees_templates/cropster-origin-app/application/template.hbs\"]]],false],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[1,[22,\"modal-dialog\"],false],[0,\"\\n\"],[1,[22,\"toast-container\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-origin-app/application/template.hbs"
    }
  });
  _exports.default = _default;
});