define("cropster-origin-app/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "kSy8MRBd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\",false],[12,\"class\",\"max-width-100p width-7 margin-auto padding-top-3 padding-left-3 padding-right-3\"],[3,\"action\",[[23,0,[]],[28,\"optional-action\",[[23,0,[\"noop\"]]],null]],[[\"on\",\"preventDefault\"],[\"submit\",true]]],[8],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"text-center margin-top-4 margin-bottom-4\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"max-width-75p margin-auto\"],[8],[0,\"\\n      \"],[7,\"img\",true],[10,\"src\",\"/assets/img/logo.svg\"],[11,\"alt\",[28,\"t\",[\"Cropster Origin\"],null]],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"margin-bottom-3\"],[8],[0,\"\\n    \"],[5,\"form/field\",[],[[\"@type\",\"@label\",\"@proxy\"],[\"text\",[28,\"t\",[\"Username\"],null],[28,\"hash\",null,[[\"value\",\"placeholder\",\"onInput\"],[[23,0,[\"username\"]],[28,\"t\",[\"Cropster email\"],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"username\"]]],null]],null]]]]]]],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"margin-bottom-4\"],[8],[0,\"\\n    \"],[5,\"form/field\",[],[[\"@type\",\"@label\",\"@proxy\"],[\"password\",[28,\"t\",[\"Password\"],null],[28,\"hash\",null,[[\"value\",\"placeholder\",\"onInput\"],[[23,0,[\"password\"]],[28,\"t\",[\"Cropster password\"],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"password\"]]],null]],null]]]]]]],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[5,\"c-button\",[[12,\"type\",\"submit\"]],[[\"@type\",\"@action\",\"@disabled\"],[\"primary\",[28,\"action\",[[23,0,[]],[23,0,[\"signin\"]]],null],[23,0,[\"validations\",\"isInvalid\"]]]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"t\",[\"Login\"],null],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-origin-app/login/template.hbs"
    }
  });
  _exports.default = _default;
});