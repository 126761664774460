define("cropster-origin-app/components/batches/reception-form/group-by-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "tDlCBQB7",
    "block": "{\"symbols\":[\"Card\",\"@source\",\"@reception\"],\"statements\":[[5,\"layout/card\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"body\"]],[],[[\"@hasBorder\"],[true]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[28,\"if\",[[28,\"or\",[[23,0,[\"showFarm\"]],[23,0,[\"showField\"]]],null],\"margin-bottom-3\"],null]],[8],[0,\"\\n      \"],[5,\"batches/reception-form/select-or-create-contact\",[],[[\"@source\",\"@label\",\"@contactRoleName\",\"@selectedContact\",\"@onChange\",\"@disabled\"],[[23,2,[]],[28,\"t\",[\"Community\"],null],\"community\",[23,3,[\"referenceBatch\",\"community\"]],[28,\"action\",[[23,0,[]],[23,0,[\"selectOrCreateContact\"]],\"community\"],null],[28,\"not\",[[23,0,[\"isGroupByCommunityVisible\"]]],null]]]],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[28,\"if\",[[23,0,[\"showField\"]],\"margin-bottom-3\"],null]],[8],[0,\"\\n      \"],[5,\"batches/reception-form/select-or-create-contact\",[],[[\"@source\",\"@label\",\"@contactRoleName\",\"@selectedContact\",\"@onChange\",\"@disabled\"],[[23,2,[]],[28,\"t\",[\"Farm\"],null],\"farm\",[23,3,[\"referenceBatch\",\"farm\"]],[28,\"action\",[[23,0,[]],[23,0,[\"selectOrCreateContact\"]],\"farm\"],null],[28,\"not\",[[23,0,[\"isGroupByFarmVisible\"]]],null]]]],[0,\"\\n    \"],[9],[0,\"\\n    \"],[5,\"batches/reception-form/select-or-create-contact\",[],[[\"@source\",\"@label\",\"@contactRoleName\",\"@selectedContact\",\"@onChange\",\"@disabled\"],[[23,2,[]],[28,\"t\",[\"Field\"],null],\"field\",[23,3,[\"referenceBatch\",\"field\"]],[28,\"action\",[[23,0,[]],[23,0,[\"selectOrCreateContact\"]],\"field\"],null],[28,\"not\",[[23,0,[\"isGroupByFieldVisible\"]]],null]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-origin-app/components/batches/reception-form/group-by-form/template.hbs"
    }
  });
  _exports.default = _default;
});