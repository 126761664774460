define("cropster-origin-app/models/batch", ["exports", "cropster-origin-app/utils/converter", "ember-cp-validations", "cropster-origin-app/models/abstract", "cropster-origin-app/models/process", "cropster-origin-app/services/origin-settings"], function (_exports, _converter, _emberCpValidations, _abstract, _process, _originSettings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Validations = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const Validations = (0, _emberCpValidations.buildValidations)({
    receivedWeight: {
      description: Ember.computed(function () {
        return this.model.l10n.pt('The received weight', 'Filled in as {{description}} for validations');
      }),
      validators: [(0, _emberCpValidations.validator)('inline', {
        validate() {
          const {
            defaultBatchDivisionType
          } = this.model.get('originSettings');
          if (defaultBatchDivisionType === _originSettings.DEFAULT_BATCH_DIVISION_AUTO) {
            return true;
          }
          return !!this.model.get('receivedWeight');
        },
        dependentKeys: ['model.receivedWeight']
      })]
    },
    cropYear: {
      description: Ember.computed(function () {
        return this.model.l10n.pt('The crop year', 'Filled in as {{description}} for validations');
      }),
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    receptionDate: {
      description: Ember.computed(function () {
        return this.model.l10n.pt('The reception date', 'Filled in as {{description}} for validations');
      }),
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    group: {
      description: Ember.computed(function () {
        return this.model.l10n.pt('The group', 'Filled in as {{description}} for validations');
      }),
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    process: {
      description: Ember.computed(function () {
        return this.model.l10n.pt('The process', 'Filled in as {{description}} for validations');
      }),
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    reception: {
      description: Ember.computed(function () {
        return this.model.l10n.pt('The reception', 'Filled in as {{description}} for validations');
      }),
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    facility: {
      description: Ember.computed(function () {
        return this.model.l10n.pt('The facility', 'Filled in as {{description}} for validations');
      }),
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });
  _exports.Validations = Validations;
  let Batch = (_dec = Ember.inject.service, _dec2 = Ember.computed.readOnly('originSettings.defaultValues.weightUnit'), _dec3 = Ember.computed('receivedWeight.{unit,amount}', 'reception.totalWeight'), _dec4 = Ember.computed('_factor', 'receivedWeight', 'reception.{hasBatchDivision,totalAmount}'), _dec5 = Ember.computed('receivedWeightOverride', 'intermediateWeightTargetOverride', 'finalWeightOverride', 'process.{yieldFactor1,receivedForm,intermediateForm,finalForm}'), _dec6 = Ember.computed('intermediateWeightSourceOverride', 'finalWeightOverride', 'process.{yieldFactor2,intermediateForm,finalForm}'), (_class = class Batch extends _abstract.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "originSettings", _descriptor, this);
      _initializerDefineProperty(this, "weightUnit", _descriptor2, this);
      _defineProperty(this, "_factor", null);
    }
    get normalizedReceivedWeight() {
      const normalized = {
        amount: 0,
        unit: this.weightUnit
      };
      const {
        amount = 0,
        unit
      } = this.receivedWeight || {};
      if (unit === '%') {
        const totalWeight = Ember.get(this, 'reception.totalWeight');
        let calcWeight = Ember.get(totalWeight, 'amount') * amount / 100;
        normalized.amount = (0, _converter.convertWeight)(calcWeight, normalized.unit, normalized.unit);
      } else {
        normalized.amount = (0, _converter.convertWeight)(amount, unit, normalized.unit);
      }
      return normalized;
    }

    //
    // received weight as percentage in respect to reception's total weight
    //
    get factor() {
      // a) calculate factor based on received weight
      if (this._factor === null) {
        let {
          receivedWeight,
          reception: {
            hasBatchDivision,
            totalWeight
          }
        } = this;
        if (hasBatchDivision) {
          if (totalWeight !== null && receivedWeight !== null && typeof totalWeight === 'object' && typeof receivedWeight === 'object') {
            const {
              amount: receivedAmount,
              unit: receivedUnit
            } = receivedWeight;
            const {
              amount: totalAmount,
              unit: totalUnit
            } = totalWeight;
            const received = (0, _converter.convertWeight)(receivedAmount, receivedUnit, 'KG');
            const total = (0, _converter.convertWeight)(totalAmount, totalUnit, 'KG');
            return received / total * 100;
          }
          return null;
        }
        return 100;
      }

      // b) use overridden factor by user input
      return this._factor;
    }
    set factor(value) {
      Ember.set(this, '_factor', value);
      return value;
    }
    //
    // uses `receivedWeightOverride` and either `intermediateWeightTargetOverride`
    // or `finalWeightOverride` - depends on if there are 2 or 3 weighing stages,
    // defaults to process' `yieldFactor1` in case there are no overrides given.
    //
    get yieldFactor1() {
      if (this.process instanceof _process.default) {
        const inputWeight = this.receivedWeightOverride;
        const inputValue = this.process.receivedForm;
        const outputWeight = this.process.hasIntermediate ? this.intermediateWeightTargetOverride : this.finalWeightOverride;
        const outputValue = this.process.hasIntermediate ? this.process.intermediateForm : this.process.finalForm;
        if (inputWeight && outputWeight) {
          return {
            inputValue,
            inputWeight,
            outputValue,
            outputWeight
          };
        }
        return this.process.yieldFactor1;
      }
      return null;
    }

    //
    // uses `intermediateWeightSourceOverride` and `finalWeightOverride` in case
    // there are 3 weighing stages, otherwise defaults to process' `yieldFactor2`!
    //
    get yieldFactor2() {
      if (this.process instanceof _process.default) {
        const inputWeight = this.intermediateWeightSourceOverride;
        const inputValue = this.process.intermediateForm;
        const outputWeight = this.finalWeightOverride;
        const outputValue = this.process.finalForm;
        if (inputWeight && outputWeight) {
          return {
            inputValue,
            inputWeight,
            outputValue,
            outputWeight
          };
        }
        return this.process.yieldFactor2;
      }
      return null;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "originSettings", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "weightUnit", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "normalizedReceivedWeight", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "normalizedReceivedWeight"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "factor", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "factor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "yieldFactor1", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "yieldFactor1"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "yieldFactor2", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "yieldFactor2"), _class.prototype)), _class));
  _exports.default = Batch;
});