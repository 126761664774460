define("cropster-origin-app/helpers/get-array-element", ["exports", "ember-cropster-common/helpers/get-array-element"], function (_exports, _getArrayElement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _getArrayElement.default;
    }
  });
  Object.defineProperty(_exports, "getArrayElement", {
    enumerable: true,
    get: function () {
      return _getArrayElement.getArrayElement;
    }
  });
});