define("cropster-origin-app/components/form/select/option/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "b0m51zWP",
    "block": "{\"symbols\":[\"@label\",\"&default\"],\"statements\":[[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"  \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-origin-app/components/form/select/option/template.hbs"
    }
  });
  _exports.default = _default;
});