define("cropster-origin-app/models/reception-item", ["exports", "cropster-origin-app/utils/converter", "ember-cp-validations", "cropster-origin-app/models/abstract"], function (_exports, _converter, _emberCpValidations, _abstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Validations = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const Validations = (0, _emberCpValidations.buildValidations)({
    weight: {
      description: Ember.computed(function () {
        return this.model.l10n.pt('The weight', 'Filled in as {{description}} for validations');
      }),
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        value(model, attribute) {
          const {
            amount = 0
          } = model.get(attribute) || {};
          return amount;
        },
        gt: 0
      })]
    },
    source: {
      description: Ember.computed(function () {
        return this.model.l10n.pt('The source', 'Filled in as {{description}} for validations');
      }),
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    reception: {
      description: Ember.computed(function () {
        return this.model.l10n.pt('The reception', 'Filled in as {{description}} for validations');
      }),
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    sourceRole: {
      description: Ember.computed(function () {
        return this.model.l10n.pt('The source role', 'Filled in as {{description}} for validations');
      }),
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });
  _exports.Validations = Validations;
  let ReceptionItem = (_dec = Ember.inject.service, _dec2 = Ember.computed.readOnly('originSettings.defaultValues.weightUnit'), _dec3 = Ember.computed.readOnly('originSettings.defaultCurrencyUnit'), _dec4 = Ember.computed.readOnly('originSettings.defaultReceivedUnit'), _dec5 = Ember.computed('weight.{unit,amount}', 'defaultReceivedUnit'), _dec6 = Ember.computed('price.{unit,amount}', 'defaultCurrencyUnit'), _dec7 = Ember.computed('normalizedPrice', 'normalizedWeight', 'defaultCurrencyUnit'), (_class = class ReceptionItem extends _abstract.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "originSettings", _descriptor, this);
      _initializerDefineProperty(this, "weightUnit", _descriptor2, this);
      _initializerDefineProperty(this, "defaultCurrencyUnit", _descriptor3, this);
      _initializerDefineProperty(this, "defaultReceivedUnit", _descriptor4, this);
    }
    get normalizedWeight() {
      const {
        amount = 0,
        unit
      } = this.weight || {};
      return {
        amount: (0, _converter.convertWeight)(amount, unit, this.defaultReceivedUnit),
        unit: this.defaultReceivedUnit
      };
    }
    get normalizedPrice() {
      const normalized = {
        amount: 0,
        unit: this.defaultCurrencyUnit
      };
      const {
        amount = 0
      } = this.price || {};
      normalized.amount = amount;
      return normalized;
    }
    get total() {
      const {
        normalizedPrice: {
          amount: priceAmount
        },
        normalizedWeight: {
          amount: weightAmount
        }
      } = this;
      const total = priceAmount * weightAmount;
      const amount = +total.toFixed(2);
      const unit = this.defaultCurrencyUnit;
      return {
        amount,
        unit
      };
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "originSettings", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "weightUnit", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "defaultCurrencyUnit", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "defaultReceivedUnit", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "normalizedWeight", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "normalizedWeight"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "normalizedPrice", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "normalizedPrice"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "total", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "total"), _class.prototype)), _class));
  _exports.default = ReceptionItem;
});