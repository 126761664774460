define("cropster-origin-app/helpers/format-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MODE_TIME = _exports.MODE_DATE_TIME = _exports.MODE_DATE = void 0;
  _exports.formatDate = formatDate;
  const MODE_DATE = 'date';
  _exports.MODE_DATE = MODE_DATE;
  const MODE_TIME = 'time';
  _exports.MODE_TIME = MODE_TIME;
  const MODE_DATE_TIME = 'datetime';
  _exports.MODE_DATE_TIME = MODE_DATE_TIME;
  function formatDate(_ref, _ref2) {
    let [date] = _ref;
    let {
      locale,
      mode = MODE_DATE,
      options = {}
    } = _ref2;
    let method;
    switch (mode) {
      case MODE_DATE:
        method = 'toLocaleDateString';
        break;
      case MODE_TIME:
        method = 'toLocaleTimeString';
        break;
      case MODE_DATE_TIME:
        method = 'toLocaleString';
        break;
      default:
        throw new Error(`Unsupported date format mode: ${mode}`);
    }
    return date[method](locale, options);
  }
  var _default = Ember.Helper.helper(formatDate);
  _exports.default = _default;
});