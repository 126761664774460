define("cropster-origin-app/orbit/bucket", ["exports", "@orbit/indexeddb-bucket", "@orbit/local-storage-bucket"], function (_exports, _indexeddbBucket, _localStorageBucket) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const BucketClass = (0, _indexeddbBucket.supportsIndexedDB)() ? _indexeddbBucket.default : _localStorageBucket.default;
  const bucket = new BucketClass({
    namespace: 'origin-app-storage',
    name: 'storage'
  });
  var _default = bucket;
  _exports.default = _default;
});