define("cropster-origin-app/helpers/class-names", ["exports", "ember-cropster-common/helpers/class-names"], function (_exports, _classNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "classNames", {
    enumerable: true,
    get: function () {
      return _classNames.classNames;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _classNames.default;
    }
  });
});