define("cropster-origin-app/components/form/radio/component", ["exports", "cropster-origin-app/components/form/abstract/checkbox/component", "cropster-origin-app/components/form/abstract/checkbox/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class Radio extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "layout", _template.default);
      //
      // Properties
      //
      _defineProperty(this, "idPrefix", 'radio-');
      _defineProperty(this, "inputType", 'radio');
      _defineProperty(this, "inputClass", 'input--radio');
    }
  }
  _exports.default = Radio;
});