define("cropster-origin-app/index/route", ["exports", "cropster-origin-app/objects/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class IndexRoute extends _authenticatedRoute.default {
    beforeModel() {
      this.transitionTo('batches');
    }
  }
  _exports.default = IndexRoute;
});