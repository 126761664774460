define("cropster-origin-app/router", ["exports", "cropster-origin-app/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('login', function () {});
    this.route('batches', function () {
      this.route('create');
      this.route('edit', {
        path: ':batch_id/edit'
      });
    });
    this.route('groups');
    this.route('logs');
    this.route('help');
    this.route('unsupported');
    this.route('error', {
      path: '/*path'
    });
  });
  var _default = Router;
  _exports.default = _default;
});