define("cropster-origin-app/components/form/select/component", ["exports", "cropster-origin-app/components/form/abstract/select/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let Select = (_dec = Ember.computed('mappedOptions.[]', 'hasEmptyOption'), _dec2 = Ember.computed.not('isAutoWidth'), _dec3 = Ember.computed('multiple', 'isAutoWidth', '_optionsMap', 'mappedValues.[]', 'processedMappedOptions.[]'), (_class = class Select extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "tagName", 'select');
      _defineProperty(this, "classNameBindings", ['isNotAutoWidth:width-100p', 'isFlat:border-all-width-0', 'isFlat:bg-color-transparent', 'isFlat:border-all-color-transparent']);
      _defineProperty(this, "attributeBindings", ['domId:id', 'style', 'disabled', 'multiple']);
      //
      // Arguments
      //
      _defineProperty(this, "isFlat", false);
      _defineProperty(this, "multiple", false);
      _defineProperty(this, "disabled", false);
      _defineProperty(this, "placeholder", '---');
      _defineProperty(this, "isAutoWidth", false);
      _defineProperty(this, "isAutoHeight", false);
      _defineProperty(this, "hasEmptyOption", false);
      _initializerDefineProperty(this, "isNotAutoWidth", _descriptor, this);
    }
    onChange() {}

    //
    // Properties
    //
    get processedMappedOptions() {
      if (this.hasEmptyOption) {
        const option = {
          value: null,
          label: this.placeholder
        };
        return [...[option], ...this.mappedOptions];
      }
      return this.mappedOptions;
    }
    get style() {
      let style = '';
      if (this.isAutoWidth && !this.multiple) {
        // use current selection value or first option
        // as a reference for currently displayed text
        const [{
          value
        } = {}] = this.mappedValues;
        const {
          label
        } = value in this._optionsMap ? this._optionsMap[value] : this.processedMappedOptions[0];

        // then simply create a dummy select with that
        // option and measure it's size to take as base
        const option = document.createElement('option');
        option.innerText = label;
        const select = document.createElement('select');
        select.style.visibility = 'hidden';
        select.style.position = 'absolute';
        select.appendChild(option);
        document.body.appendChild(select);
        const {
          clientWidth: width
        } = select;
        document.body.removeChild(select);

        // apply the calculated with to actual element
        style += `width:${width}px !important;`;
      }
      if (this.isAutoHeight) {
        style += `height: auto !important;`;
        style += `min-height: auto !important;`;
      }
      return Ember.String.htmlSafe(style);
    }

    //
    // Events
    //
    change(_ref) {
      let {
        target: {
          value,
          options
        }
      } = _ref;
      value = this.multiple ? [...options].filter(_ref2 => {
        let {
          selected
        } = _ref2;
        return selected;
      }).map(_ref3 => {
        let {
          value
        } = _ref3;
        return this.processOption(this._optionsMap[value]);
      }) : value in this._optionsMap ? this.processOption(this._optionsMap[value]) : null;
      this.onChange(value);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "processedMappedOptions", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "processedMappedOptions"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "isNotAutoWidth", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "style", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "style"), _class.prototype)), _class));
  _exports.default = Select;
});