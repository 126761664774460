define("cropster-origin-app/initializers/backup-source", ["exports", "cropster-origin-app/orbit/sources/backup"], function (_exports, _backup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    application.register('service:backup-source', _backup.default, {
      instantiate: false
    });
  }
  var _default = {
    name: 'backup-source',
    initialize: initialize
  };
  _exports.default = _default;
});