define("cropster-origin-app/objects/authenticated-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AuthenticatedRoute extends Ember.Route {
    beforeModel() {
      super.beforeModel();

      // application route holds current token,
      // verify that the user session is valid
      let token = this.modelFor('application');
      if (typeof token !== 'string') {
        this.transitionTo('login');
      }
    }
  }
  _exports.default = AuthenticatedRoute;
});