define("cropster-origin-app/instance-initializers/datadog", ["exports", "cropster-origin-app/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(applicationInstance) {
    const logger = applicationInstance.lookup('service:datadog-logger');
    const {
      dataDog: {
        clientToken,
        cdnURL
      }
    } = _environment.default;
    logger.load(clientToken, cdnURL);
  }
  var _default = {
    initialize
  };
  _exports.default = _default;
});