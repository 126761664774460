define("cropster-origin-app/components/groups/available-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nSTMZDj9",
    "block": "{\"symbols\":[\"@group\"],\"statements\":[[1,[23,1,[\"name\"]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-origin-app/components/groups/available-group/template.hbs"
    }
  });
  _exports.default = _default;
});