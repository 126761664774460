define("cropster-origin-app/orbit/sources/remote/json-api-serializer", ["exports", "@orbit/jsonapi", "@orbit/utils"], function (_exports, _jsonapi, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //
  // Custom serializer changing the following default behavior:
  //
  // - use `remoteId` as key mapping between remote/local IDs
  // - use `camelCase` instead of `kebab-case` for all resources
  //
  // @see https://github.com/orbitjs/orbit/blob/master/packages/@orbit/jsonapi/src/jsonapi-serializer.ts
  //
  class CustomJSONAPISerializer extends _jsonapi.JSONAPISerializer {
    resourceKey(type) {
      switch (type) {
        case 'batch':
        case 'contact':
        case 'reception':
        case 'receptionItem':
          return 'remoteId';
        default:
          return 'id';
      }
    }
    resourceType( /* type */
    ) {
      const resourceType = super.resourceType(...arguments);
      return (0, _utils.camelize)(resourceType);
    }
    resourceRelationship( /* type, relationship*/
    ) {
      const resourceRelationship = super.resourceRelationship(...arguments);
      return (0, _utils.camelize)(resourceRelationship);
    }
    resourceAttribute( /* type, attribute */
    ) {
      const resourceAttribute = super.resourceAttribute(...arguments);
      return (0, _utils.camelize)(resourceAttribute);
    }
    serializeAttribute(resource, record, attribute, model) {
      const key = this.resourceAttribute(record.type, attribute);
      const {
        serializationOptions
      } = model.attributes[key] || {};
      if (typeof serializationOptions !== 'object' || serializationOptions === null || !serializationOptions.ignore) {
        super.serializeAttribute(...arguments);
      }
    }
    serializeRelationship(resource, record, relationship, model) {
      const key = this.resourceRelationship(record.type, relationship);
      const {
        serializationOptions
      } = model.relationships[key] || {};
      if (typeof serializationOptions !== 'object' || serializationOptions === null || !serializationOptions.ignore) {
        super.serializeRelationship(...arguments);
      }
    }
  }
  var _default = CustomJSONAPISerializer;
  _exports.default = _default;
});