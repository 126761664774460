define("cropster-origin-app/components/form/abstract/select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.valueAsArray = _exports.resolveProperty = _exports.optionsToMap = _exports.mapOptions = _exports.default = _exports.VALUE_PATH = _exports.LABEL_PATH = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const LABEL_PATH = 'label';
  _exports.LABEL_PATH = LABEL_PATH;
  const VALUE_PATH = 'value';
  _exports.VALUE_PATH = VALUE_PATH;
  const mapOptions = (options, valuePath, labelPath) => options.map(item => {
    switch (typeof item) {
      case 'object':
        if (item !== null) {
          return {
            value: resolveProperty(item, valuePath),
            label: resolveProperty(item, labelPath)
          };
        }
        throw new Error(`You passed 'null' as an option!`);
      case 'string':
      case 'number':
        return {
          label: item,
          value: item
        };
      default:
        throw new Error(`Unsupported option type: ${typeof item}`);
    }
  });
  _exports.mapOptions = mapOptions;
  const optionsToMap = (array, valuePath) => Object.assign({}, ...array.map(item => ({
    [resolveProperty(item, valuePath)]: item
  })));
  _exports.optionsToMap = optionsToMap;
  const valueAsArray = value => Array.isArray(value) ? value.filter(Boolean) : typeof value === 'string' || typeof value === 'number' || typeof value === 'object' && value !== null ? [value] : [];
  _exports.valueAsArray = valueAsArray;
  const resolveProperty = (item, path) => {
    switch (typeof path) {
      case 'function':
        return path(item);
      case 'string':
        if (typeof item === 'object') {
          if (item !== null) {
            return Ember.get(item, path);
          }
        }
        return item;
      // primitives
      default:
        throw new Error(`Unsupported path type: ${typeof path}`);
    }
  };
  _exports.resolveProperty = resolveProperty;
  let AbstractSelect = (_dec = Ember.computed('value.[]'), _dec2 = Ember.computed('valuePath'), _dec3 = Ember.computed('labelPath'), _dec4 = Ember.computed('valueAsArray.[]', 'actualValuePath', 'actualLabelPath'), _dec5 = Ember.computed('options.[]', 'actualValuePath', 'actualLabelPath'), _dec6 = Ember.computed('valueAsArray.[]', 'actualValuePath'), _dec7 = Ember.computed('options.[]', 'actualValuePath'), (_class = class AbstractSelect extends Ember.Component {
    constructor() {
      super(...arguments);
      //
      // Arguments
      //
      _defineProperty(this, "value", null);
      _defineProperty(this, "options", []);
      _defineProperty(this, "valuePath", VALUE_PATH);
      _defineProperty(this, "labelPath", LABEL_PATH);
      _defineProperty(this, "valueByReference", false);
    }
    //
    // Properties
    //
    get valueAsArray() {
      return valueAsArray(this.value);
    }
    get actualValuePath() {
      return typeof this.valuePath === 'string' ? this.valuePath : VALUE_PATH;
    }
    get actualLabelPath() {
      return typeof this.valuePath === 'string' ? this.labelPath : LABEL_PATH;
    }
    get mappedValues() {
      return mapOptions(this.valueAsArray, this.actualValuePath, this.actualLabelPath);
    }
    get mappedOptions() {
      const options = mapOptions(this.options, this.actualValuePath, this.actualLabelPath);
      options.sort((_ref, _ref2) => {
        let {
          label: labelA
        } = _ref;
        let {
          label: labelB
        } = _ref2;
        labelA = typeof labelA === 'string' ? labelA : '';
        labelB = typeof labelB === 'string' ? labelB : '';
        return labelA.localeCompare(labelB);
      });
      return options;
    }
    get valueMap() {
      return optionsToMap(this.valueAsArray, this.actualValuePath);
    }
    get _optionsMap() {
      return optionsToMap(this.options, this.actualValuePath);
    }

    //
    // Hooks
    //
    init() {
      super.init(...arguments);
      (false && !(Array.isArray(this.options)) && Ember.assert('`options` must be an array', Array.isArray(this.options)));
      (false && !(typeof this.valuePath === 'string' || typeof this.valuePath === 'function') && Ember.assert('`valuePath` must be a string', typeof this.valuePath === 'string' || typeof this.valuePath === 'function'));
      (false && !(typeof this.labelPath === 'string' || typeof this.labelPath === 'function') && Ember.assert('`labelPath` must be a string', typeof this.labelPath === 'string' || typeof this.labelPath === 'function'));
      (false && !(typeof this.valueByReference === 'boolean') && Ember.assert('`valueByReference` must be an boolean', typeof this.valueByReference === 'boolean'));
    }

    //
    // Methods
    //
    processOption(option) {
      if (!this.valueByReference) {
        return resolveProperty(option, this.actualValuePath);
      }
      return option;
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "valueAsArray", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "valueAsArray"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "actualValuePath", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "actualValuePath"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "actualLabelPath", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "actualLabelPath"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mappedValues", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "mappedValues"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mappedOptions", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "mappedOptions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "valueMap", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "valueMap"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_optionsMap", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "_optionsMap"), _class.prototype)), _class));
  _exports.default = AbstractSelect;
});