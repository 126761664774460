define("cropster-origin-app/components/logs/log/type/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "o5GzVpON",
    "block": "{\"symbols\":[\"id\",\"error\",\"@type\",\"@title\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"bold\"],[8],[1,[23,4,[]],false],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"margin-top-2\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"hasIds\"]]],null,{\"statements\":[[4,\"if\",[[23,0,[\"hasDoneIds\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"display-flex items-center simple-gap-1\"],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"color-success\"],[8],[0,\"\\n          \"],[1,[28,\"svg-icon\",[\"check\"],[[\"class\"],[\"icon--inherit-color\"]]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"color-grey-2\"],[8],[1,[28,\"join-array\",[[23,3,[\"done\"]]],null],false],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,0,[\"hasFailIds\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,3,[\"fail\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\",true],[10,\"class\",\"display-flex items-center simple-gap-1\"],[8],[0,\"\\n            \"],[7,\"span\",true],[10,\"class\",\"color-error\"],[8],[0,\"\\n              \"],[1,[28,\"svg-icon\",[\"close\"],[[\"class\"],[\"icon--inherit-color\"]]],false],[0,\"\\n            \"],[9],[0,\"\\n            \"],[7,\"span\",true],[10,\"class\",\"color-grey-2\"],[8],[0,\"\\n\"],[4,\"let\",[[28,\"get\",[[23,3,[\"errors\"]],[23,1,[]]],null]],null,{\"statements\":[[0,\"                \"],[7,\"div\",true],[10,\"class\",\"bold\"],[8],[1,[23,2,[\"title\"]],false],[0,\" (\"],[1,[23,1,[]],false],[0,\"):\"],[9],[0,\"\\n                \"],[7,\"div\",true],[8],[1,[23,2,[\"message\"]],false],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"            \"],[9],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"    -\\n\"]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-origin-app/components/logs/log/type/template.hbs"
    }
  });
  _exports.default = _default;
});