define("cropster-origin-app/helpers/is-not-none", ["exports", "ember-cropster-common/helpers/is-not-none"], function (_exports, _isNotNone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _isNotNone.default;
    }
  });
  Object.defineProperty(_exports, "isNotNone", {
    enumerable: true,
    get: function () {
      return _isNotNone.isNotNone;
    }
  });
});