define("cropster-origin-app/orbit/coordinator", ["exports", "@orbit/coordinator", "cropster-origin-app/orbit/strategies/sync-change-to-memory", "cropster-origin-app/orbit/strategies/sync-memory-to-backup", "cropster-origin-app/orbit/strategies/sync-remote-to-memory", "cropster-origin-app/orbit/sources/memory", "cropster-origin-app/orbit/sources/backup", "cropster-origin-app/orbit/sources/change", "cropster-origin-app/orbit/sources/remote"], function (_exports, _coordinator, _syncChangeToMemory, _syncMemoryToBackup, _syncRemoteToMemory, _memory, _backup, _change, _remote) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const coordinator = new _coordinator.default();
  coordinator.addSource(_memory.default);
  coordinator.addSource(_backup.default);
  coordinator.addSource(_change.default);
  coordinator.addSource(_remote.default);
  coordinator.addStrategy(_syncMemoryToBackup.default);
  coordinator.addStrategy(_syncChangeToMemory.default);
  coordinator.addStrategy(_syncRemoteToMemory.default);
  coordinator.addStrategy(new _coordinator.LogTruncationStrategy({
    sources: ['memory', 'backup', 'remote'],
    interfaces: ['queryable', 'updatable', 'pushable', 'pullable', 'syncable']
  }));
  var _default = coordinator;
  _exports.default = _default;
});