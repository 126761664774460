define("cropster-origin-app/batches/index/route", ["exports", "cropster-origin-app/objects/authenticated-route", "cropster-origin-app/services/synchronization", "cropster-origin-app/utils/enums", "cropster-origin-app/utils/converter"], function (_exports, _authenticatedRoute, _synchronization, _enums, _converter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let BatchesRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, (_class = class BatchesRoute extends _authenticatedRoute.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "activeUser", _descriptor2, this);
      _initializerDefineProperty(this, "activeGroup", _descriptor3, this);
      _initializerDefineProperty(this, "memorySource", _descriptor4, this);
      _initializerDefineProperty(this, "originSettings", _descriptor5, this);
      _initializerDefineProperty(this, "synchronization", _descriptor6, this);
      _defineProperty(this, "isLoading", true);
      _defineProperty(this, "queryParams", {
        sortField: {
          refreshModel: true
        },
        sortDirection: {
          refreshModel: true
        }
      });
    }
    activate() {
      super.activate(...arguments);
      Ember.addListener(this.synchronization, _synchronization.EVENT_PULLED_GROUP_DATA, this, this.refresh);
    }
    async model() {
      var _this = this;
      const batches = this.activeUser.getBatchesByUser(this.memorySource).filter(_ref => {
        let {
          part
        } = _ref;
        return part === 'A' || part === 1 || !part;
      });
      batches.sort(function () {
        return _this._sortBatches(...arguments);
      });
      return batches;
    }
    async afterModel() {
      // store query parameters automatically  after they have been changed
      const {
        sortField,
        sortDirection
      } = this.paramsFor(this.routeName);
      if (sortDirection !== null && sortField !== null) {
        Ember.set(this, 'originSettings.sortDirection', sortDirection);
        Ember.set(this, 'originSettings.sortField', sortField);
        await this.originSettings.saveUserSettings();
      }
    }
    setupController(controller) {
      super.setupController(...arguments);

      // proxy sort settings to controller for initial setup only
      const {
        originSettings: {
          sortField,
          sortDirection
        }
      } = this;
      Ember.set(controller, 'sortDirection', sortDirection);
      Ember.set(controller, 'sortField', sortField);
    }
    deactivate() {
      super.deactivate(...arguments);
      Ember.removeListener(this.synchronization, _synchronization.EVENT_PULLED_GROUP_DATA, this, this.refresh);
    }
    _sortBatches(batchA, batchB) {
      const {
        sortField: defaultSortField,
        sortDirection: defaultSortDirection
      } = this.originSettings;
      const {
        sortField: currrentSortField,
        sortDirection: currentSortDirection
      } = this.paramsFor(this.routeName);
      const sortDirection = currentSortDirection || defaultSortDirection;
      const sortField = currrentSortField || defaultSortField;
      const compareValues = (valueA, valueB) => {
        if (valueA < valueB) {
          return sortDirection === 'ascending' ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortDirection === 'ascending' ? 1 : -1;
        }
        return 0;
      };
      const normalizeResult = value => Array.isArray(value) ? value.join(',') : value;
      const preprocessValue = value => {
        switch (sortField) {
          case _enums.FIELD_RECEIVED_WEIGHT:
            {
              if (value === null) {
                return 0;
              }
              const {
                amount,
                unit
              } = value;
              return (0, _converter.convertWeight)(amount, unit, 'KG');
            }
          default:
            return value;
        }
      };
      const valueA = normalizeResult(preprocessValue(Ember.get(batchA, sortField)));
      const valueB = normalizeResult(preprocessValue(Ember.get(batchB, sortField)));
      return compareValues(valueA, valueB);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "activeUser", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "activeGroup", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "memorySource", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "originSettings", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "synchronization", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BatchesRoute;
});