define("cropster-origin-app/components/batches/reception-form/select-or-create-contact/component", ["exports", "cropster-origin-app/services/store", "cropster-origin-app/models/contact"], function (_exports, _store, _contact) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ReceptionItemForm = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed('source', 'contactRoleName', 'activeGroup.id'), _dec4 = Ember._action, (_class = class ReceptionItemForm extends Ember.Component {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "activeGroup", _descriptor2, this);
      //
      // Arguments
      //
      _defineProperty(this, "disabled", false);
      _defineProperty(this, "label", null);
      _defineProperty(this, "error", null);
      _defineProperty(this, "source", null);
      _defineProperty(this, "contactRoleName", null);
      _defineProperty(this, "selectedContact", null);
      _defineProperty(this, "filterIds", []);
      //
      // Properties
      //
      _defineProperty(this, "newContact", null);
    }
    //array
    onChange( /* contact */) {}
    get options() {
      let options = this.store.findRecords(this.source, 'contact', {
        filter: [{
          relation: 'contactRoles',
          records: [this._getContactRoleRecord()]
        }, {
          relation: 'group',
          record: {
            type: 'group',
            id: this.activeGroup.id
          }
        }]
      }).filter(_ref => {
        let {
          name
        } = _ref;
        return typeof name === 'string';
      } /* ignore `null` */);
      if (this.filterIds) {
        options = options.filter(o => !this.filterIds.includes(o.id));
      }
      return options;
    }

    //
    // Hooks
    //
    init() {
      super.init(...arguments);
      (false && !((0, _store.isSource)(this.source)) && Ember.assert('`source` must be a source instance!', (0, _store.isSource)(this.source)));
      (false && !(this.selectedContact instanceof _contact.default || this.selectedContact === null) && Ember.assert('`selectedContact` must be a record from type `contact` or null!', this.selectedContact instanceof _contact.default || this.selectedContact === null));
      (false && !(typeof this.contactRoleName === 'string') && Ember.assert('`contactRoleName` must be a string!', typeof this.contactRoleName === 'string'));
    }
    willDestroyElement() {
      super.willDestroyElement(...arguments);
    }

    //
    // Methods
    //
    _destroyNewContact() {
      Ember.set(this, 'newContact', null);
    }
    _getContactRoleRecord() {
      const [contactRole] = this.store.findRecords(this.source, 'contactRole', {
        filter: [{
          attribute: 'name',
          value: this.contactRoleName
        }]
      });
      return contactRole.getRecord();
    }
    _getGroupRecord() {
      const {
        id
      } = this.activeGroup;
      const group = this.store.findRecord(this.source, 'group', id);
      return group.getRecord();
    }

    //
    // Actions
    //
    async changeHandler(idOrName) {
      let model = null;

      // always try to delete a previously created record from current source
      this._destroyNewContact();

      // if input was not deleted, try to retrieve record, otherwise create it
      if (idOrName.length !== 0) {
        try {
          model = this.store.findRecord(this.source, 'contact', idOrName);
        } catch (e) {
          model = await this.store.addRecord(this.source, {
            type: 'contact',
            attributes: {
              name: idOrName,
              companyName: idOrName
            },
            relationships: {
              group: {
                data: this._getGroupRecord()
              },
              contactRoles: {
                data: [this._getContactRoleRecord()]
              }
            }
          });
          Ember.set(this, 'newContact', model);
        }
      }
      this.onChange(model);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "activeGroup", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "options", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "options"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeHandler", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "changeHandler"), _class.prototype)), _class));
  _exports.default = ReceptionItemForm;
});