define("cropster-origin-app/login/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LoginRoute extends Ember.Route {
    beforeModel() {
      super.beforeModel();

      // application route holds current user,
      // verify that the user session is invalid
      let token = this.modelFor('application');
      if (typeof token === 'string') {
        this.transitionTo('index');
      }
    }
  }
  _exports.default = LoginRoute;
});