define("cropster-origin-app/services/reception-factory", ["exports", "cropster-origin-app/services/synchronization", "cropster-origin-app/services/store", "cropster-origin-app/models/reception", "cropster-origin-app/models/batch", "cropster-origin-app/utils/enums"], function (_exports, _synchronization, _store, _reception, _batch, _enums) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.STORAGE_KEY_ID_TAG = _exports.STORAGE_KEY_AUTO_ID = _exports.MIN_BATCHES = _exports.MAX_BATCHES = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const MIN_BATCHES = 1;
  _exports.MIN_BATCHES = MIN_BATCHES;
  const MAX_BATCHES = 9;
  _exports.MAX_BATCHES = MAX_BATCHES;
  const STORAGE_KEY_AUTO_ID = '__coa_aid__';
  _exports.STORAGE_KEY_AUTO_ID = STORAGE_KEY_AUTO_ID;
  const STORAGE_KEY_ID_TAG = '__coa_itm__';
  _exports.STORAGE_KEY_ID_TAG = STORAGE_KEY_ID_TAG;
  const CROP_YEAR_PATTERN = /^\d{2}$/;
  const ACRONYM_PATTERN = /^[A-Z]{3}$/;
  const IDTAG_PATTERN = /^([A-Z]{2,3})-(\d{2})-(\d{4})$/;

  /**
   * Custom factory for creating reception with batch items.
   *
   * It uses current origin settings to gather batch count and
   * will then create appropriate batches. It will clone batch
   * for existing reception with batches on batch count change.
   */
  let ReceptionFactoryService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, (_class = class ReceptionFactoryService extends Ember.Service {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "storage", _descriptor2, this);
      _initializerDefineProperty(this, "activeUser", _descriptor3, this);
      _initializerDefineProperty(this, "activeGroup", _descriptor4, this);
      _initializerDefineProperty(this, "memorySource", _descriptor5, this);
      _initializerDefineProperty(this, "originSettings", _descriptor6, this);
      _initializerDefineProperty(this, "synchronization", _descriptor7, this);
    }
    //
    // Methods
    //
    async activate() {
      Ember.addListener(this.synchronization, _synchronization.EVENT_PULLED_GROUP_DATA, this, this._pulledGroupDataHandler);
    }
    async deactivate() {
      try {
        Ember.removeListener(this.synchronization, _synchronization.EVENT_PULLED_GROUP_DATA, this, this._pulledGroupDataHandler);
      } catch (e) {
        // noop
      }
    }
    async createReception(source) {
      (false && !((0, _store.isSource)(source)) && Ember.assert('`source` must be a source instance!', (0, _store.isSource)(source)));
      const reception = await this._createReception(source);
      await this.createBatches(source, reception);
      return reception;
    }
    async createBatches(source, reception) {
      (false && !((0, _store.isSource)(source)) && Ember.assert('`source` must be a source instance!', (0, _store.isSource)(source)));
      (false && !(reception instanceof _reception.default) && Ember.assert('`reception` needs to be a record of type `reception`!', reception instanceof _reception.default)); // read batch count from origin settings
      const {
        defaultBatchDivisions,
        defaultReceivedUnit
      } = this.originSettings;

      // before creating cleanup potential old batches
      const {
        batches,
        referenceBatch
      } = reception;
      for (const batch of batches) {
        await this.store.removeRecord(source, batch);
      }
      batches.length = 0;

      // clone or create a plain dummy batch record
      const referenceRecord = referenceBatch instanceof _batch.default ? {
        ...Ember.copy(referenceBatch.getRecord(), true),
        ...{
          id: undefined
        }
      } : {};

      /*
       * Batches are now created by default based on the default values stored within
       * the origin group setting defaultBatchDivisions.
       * */
      let index = 0;
      for (let defaultBatchDivision of defaultBatchDivisions) {
        let process = null;
        let project = null;
        const {
          processId,
          projectId
        } = defaultBatchDivision;
        if (processId) {
          process = await this.store.findRecord(source, 'process', processId);
        }
        if (projectId) {
          project = await this.store.findRecord(source, 'project', projectId);
        }
        const record = {
          type: 'batch',
          attributes: {
            ...(referenceRecord.attributes || {}),
            ...{
              partName: defaultBatchDivision.partName,
              part: index + 1,
              receivedWeight: index === 0 ? referenceRecord.receivedWeight : null,
              isPiled: !!defaultBatchDivision.isPiled
            }
          },
          relationships: {
            ...(referenceRecord.relationships || {}),
            ...{
              project,
              process
            },
            reception: {
              data: reception.getRecord()
            }
          }
        };
        const batch = await this._createBatch(source, reception, record);
        batches.push(batch);
        index++;
      }
      if (batches.length === 0) {
        let hash = {
          type: 'batch'
        };
        if (defaultReceivedUnit) {
          hash['attributes'] = {
            receivedWeight: {
              amount: 0,
              unit: defaultReceivedUnit
            }
          };
        }
        const batch = await this._createBatch(source, reception, hash);
        batches.push(batch);
      }
      await this.store.replaceRelatedRecords(source, reception, 'batches', batches);
    }
    async updateIdTag(idTag) {
      return this._saveIdTag(idTag);
    }
    _decodeIdTag(idTag) {
      (false && !(typeof idTag === 'string' && IDTAG_PATTERN.test(idTag)) && Ember.assert('`idTag` must have form XXX-XX-XXXX', typeof idTag === 'string' && IDTAG_PATTERN.test(idTag)));
      const [, acronym, cropYear, autoId] = idTag.match(IDTAG_PATTERN);
      return {
        acronym,
        cropYear,
        autoId: parseInt(autoId)
      };
    }
    _encodeIdTag(_ref) {
      let {
        acronym,
        cropYear,
        autoId
      } = _ref;
      (false && !(typeof acronym === 'string' && ACRONYM_PATTERN.test(acronym)) && Ember.assert('`acronym` must have form XXX', typeof acronym === 'string' && ACRONYM_PATTERN.test(acronym)));
      (false && !(typeof cropYear === 'string' && CROP_YEAR_PATTERN.test(cropYear)) && Ember.assert('`cropYear` must have form XX', typeof cropYear === 'string' && CROP_YEAR_PATTERN.test(cropYear)));
      (false && !(typeof autoId === 'number' && autoId > 0) && Ember.assert('`cropYear` must have form XXXX', typeof autoId === 'number' && autoId > 0));
      autoId = `000${autoId}`.slice(-4);
      return `${acronym}-${cropYear}-${autoId}`;
    }
    async _createReception(source) {
      (false && !((0, _store.isSource)(source)) && Ember.assert('`source` must be a source instance!', (0, _store.isSource)(source)));
      const {
        defaultValues: {
          coffeeForm = _enums.COFFEE_FORM_CHERRY
        }
      } = this.originSettings;
      const {
        facility
      } = this.activeUser;
      const {
        model: group
      } = this.activeGroup;
      const record = {
        type: 'reception',
        attributes: {
          coffeeForm,
          receptionDate: new Date()
        },
        relationships: {
          group: {
            data: group.getRecord()
          },
          facility: {
            data: facility.getRecord()
          }
        }
      };
      return await this.store.addRecord(source, record);
    }
    async _createBatch(source, reception) {
      let record = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      (false && !((0, _store.isSource)(source)) && Ember.assert('`source` must be a source instance!', (0, _store.isSource)(source)));
      (false && !((0, _store.isRecord)(record)) && Ember.assert('`record` must be a record!', (0, _store.isRecord)(record)));
      (false && !((0, _store.isModel)(reception)) && Ember.assert('`reception` must be a model!', (0, _store.isModel)(reception)));
      const date = new Date();
      const {
        facility
      } = this.activeUser;
      const {
        model: group
      } = this.activeGroup;
      const idTag = await this._createIdTag(source);
      const {
        cropYear,
        editFields: {
          [_enums.FIELD_HARVEST_DATE]: hasHarvestDate = false
        }
      } = this.originSettings;
      record.attributes = record.attributes || {};
      record.relationships = record.relationships || {};
      record.attributes.idTag = record.attributes.idTag || idTag;
      record.attributes.cropYear = record.attributes.cropYear || cropYear;
      record.attributes.receptionDate = record.attributes.receptionDate || date;
      record.attributes.harvestDate = hasHarvestDate ? record.attributes.receptionDate : null;
      record.relationships.group = record.relationships.group || {
        data: group.getRecord()
      };
      record.relationships.facility = record.relationships.facility || {
        data: facility.getRecord()
      };
      if (record.relationships.project) {
        record.relationships.project = {
          data: record.relationships.project.getRecord()
        };
      } else {
        delete record.relationships.project;
      }
      if (record.relationships.process) {
        record.relationships.process = {
          data: record.relationships.process.getRecord()
        };
      }
      record.relationships.reception = {
        data: reception.getRecord()
      };
      return await this.store.addRecord(source, record);
    }
    async _createIdTag() {
      // https://cropster.atlassian.net/wiki/spaces/OROA/pages/813531187/Decision+Batch+ID+numbering+system
      let acronym, cropYear, autoId;
      const idTag = await this._loadIdTag();
      if (idTag === null || parseInt(idTag.split('-')[1]) !== parseInt(this.originSettings.cropYear.slice(-2))) {
        ({
          acronym
        } = this.activeUser.facility);
        ({
          cropYear
        } = this.originSettings);
        cropYear = cropYear.slice(-2);
        autoId = 1;
      } else {
        ({
          acronym,
          cropYear,
          autoId
        } = this._decodeIdTag(idTag));
        autoId += 1;
      }
      return this._encodeIdTag({
        acronym,
        cropYear,
        autoId
      });
    }
    async _loadIdTag() {
      const map = (await this.storage.getItem(STORAGE_KEY_ID_TAG)) || {};
      const idTag = map[this.activeGroup.id];
      if (typeof idTag === 'string' && IDTAG_PATTERN.test(idTag)) {
        return idTag;
      }
      return null;
    }
    async _saveIdTag(idTag) {
      (false && !(typeof idTag === 'string' && IDTAG_PATTERN.test(idTag)) && Ember.assert('`idTag` must have form XXX-XX-XXXX', typeof idTag === 'string' && IDTAG_PATTERN.test(idTag)));
      const map = (await this.storage.getItem(STORAGE_KEY_ID_TAG)) || {};
      map[this.activeGroup.id] = idTag;
      return await this.storage.setItem(STORAGE_KEY_ID_TAG, map);
    }
    async _pulledGroupDataHandler() {
      // get latest batch (= sorted by `idTag` descending) from server and
      // set it as reference in case there's no change in crop year visible
      const [batch] = this.activeUser.getBatchesByUser(this.memorySource);
      if (batch instanceof _batch.default) {
        const localeIdTag = await this._loadIdTag();
        const {
          idTag: remoteIdTag
        } = batch;
        let idTag = remoteIdTag;
        if (localeIdTag !== null) {
          const {
            acronym: remoteAcronym,
            cropYear: remoteCropYear
          } = this._decodeIdTag(remoteIdTag);
          const {
            cropYear: localeCropYear
          } = this._decodeIdTag(localeIdTag);
          if (localeCropYear !== remoteCropYear) {
            idTag = this._encodeIdTag({
              autoId: 1,
              acronym: remoteAcronym,
              cropYear: remoteCropYear
            });
          }
        }
        await this._saveIdTag(idTag);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "storage", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "activeUser", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "activeGroup", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "memorySource", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "originSettings", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "synchronization", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ReceptionFactoryService;
});