define("cropster-origin-app/services/model-factory", ["exports", "cropster-origin-app/services/store", "cropster-origin-app/models"], function (_exports, _store, Models) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  /**
   * Model factory creating model instances from Orbit.js records.
   * It uses tries to uses models defined in `/app/models` folder.
   * Non-existing models uses the abstract model (and validator).
   */
  let ModelFactoryService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class ModelFactoryService extends Ember.Service {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "memorySource", _descriptor2, this);
    }
    create(source, record) {
      (false && !((0, _store.isSource)(source)) && Ember.assert('`source` must be a source!', (0, _store.isSource)(source)));
      (false && !((0, _store.isRecord)(record)) && Ember.assert('`record` must be a record!', (0, _store.isRecord)(record)));
      const owner = Ember.getOwner(this);
      const Model = this._getModelClass(record);
      const validator = this._createValidator(record);
      const model = new Model(owner, source, record, validator);
      const get = (object, property, receiver) => {
        const record = object.getRecord();
        const source = (0, _store.isMemorySource)(object.getSource()) ? object.getSource() : this.memorySource;
        switch (true) {
          // access object props like `source`, `record`
          case property in object:
            return Reflect.get(object, property, receiver);

          // access record props like `id`, `type`, `attributes`
          case property in record:
            return Reflect.get(record, property, receiver);

          // access to attributes and relationships via resolver
          case typeof property === 'string':
            try {
              return this.store.resolvePath(source, record, property);
            } catch (error) {
              if (error instanceof _store.UnknownPropertyError) {
                return Reflect.get(object, property, receiver);
              }
              throw error;
            }
          default:
            return Reflect.get(object, property, receiver);
        }
      };
      const set = (object, property, value, receiver) => {
        // @todo(apf): can we use this trap to update record?
        return Reflect.set(object, property, value, receiver);
      };
      const proxy = new Proxy(model, {
        get,
        set
      });

      // set proxied model for validator's proxy as `content`
      // lazyily, it will then be available via `model` prop
      // used to make all validations of `buildValidations()`
      validator.set('content', proxy);
      return proxy;
    }
    _createValidator(record) {
      const owner = Ember.getOwner(this);
      const Mixin = this._getValidationMixin(record);
      return Models.Validator.extend(Mixin).create(owner.ownerInjection());
    }
    _getValidationMixin(_ref) {
      let {
        type
      } = _ref;
      const name = `${Ember.String.classify(type)}Validations`;
      if (name in Models) {
        return Models[name];
      }
      return Models.Validations;
    }
    _getModelClass(_ref2) {
      let {
        type
      } = _ref2;
      const name = Ember.String.classify(type);
      if (name in Models) {
        return Models[name];
      }
      return Models.Model;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "memorySource", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ModelFactoryService;
});