define("cropster-origin-app/batches/create/controller", ["exports", "cropster-origin-app/utils/converter"], function (_exports, _converter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let BatchesCreateController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service('batch'), _dec9 = Ember.computed.readOnly('model.source'), _dec10 = Ember.computed.readOnly('model.reception'), _dec11 = Ember.computed.readOnly('originSettings.defaultValues'), _dec12 = Ember.computed('isAddingSupplier'), _dec13 = Ember.computed('isAddingSupplier'), _dec14 = Ember.computed('focusedReceptionItems.firstObject.contactRole'), _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, _dec22 = Ember._action, (_class = class BatchesCreateController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "l10n", _descriptor, this);
      _initializerDefineProperty(this, "toast", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "synchronization", _descriptor4, this);
      _initializerDefineProperty(this, "receptionFactory", _descriptor5, this);
      _initializerDefineProperty(this, "receptionItemFactory", _descriptor6, this);
      _initializerDefineProperty(this, "originSettings", _descriptor7, this);
      _initializerDefineProperty(this, "batchService", _descriptor8, this);
      //
      // Properties
      //
      _initializerDefineProperty(this, "source", _descriptor9, this);
      _initializerDefineProperty(this, "reception", _descriptor10, this);
      _initializerDefineProperty(this, "defaultValues", _descriptor11, this);
      _defineProperty(this, "isAddingSupplier", false);
      _defineProperty(this, "focusedReceptionItems", []);
    }
    get pageTitle() {
      return this.isAddingSupplier ? this.l10n.t('Coffee supplier') : this.l10n.t('Register coffee');
    }
    get displayAddButton() {
      return !!this.isAddingSupplier;
    }
    get contactRole() {
      return this.focusedReceptionItems.firstObject.contactRole;
    }
    //
    // Actions
    //
    async cancel() {
      if (!this.isAddingSupplier) {
        this.transitionToRoute('batches');
      }
      Ember.set(this, 'isAddingSupplier', false);
      await this._rollBackReceptionItems();
    }
    async save(reception) {
      await this.batchService.handleDivision(reception, this.source);
      await this.synchronization.scheduleChangesForSync(this.source);
      await this.receptionFactory.updateIdTag(this.reception.referenceBatch.idTag);
      this.toast.success(this.l10n.t('Coffee was scheduled for next sync!'));
      this.transitionToRoute('batches');
    }
    async addReceptionItem(selectedContactRole) {
      const receptionItems = await this.receptionItemFactory.buildDefaultReceptionItems(this.source, selectedContactRole ? selectedContactRole : this.contactRole);
      await this.store.replaceRelatedRecords(this.source, this.reception, 'receptionItems', [...this.reception.receptionItems, ...receptionItems]);
      Ember.set(this, 'isAddingSupplier', true);
      Ember.set(this, 'focusedReceptionItems', receptionItems);
    }
    focusReceptionItems(receptionItems) {
      Ember.set(this, 'isAddingSupplier', true);
      Ember.set(this, 'focusedReceptionItems', receptionItems);
    }
    async disableSupplierAdding() {
      Ember.set(this, 'isAddingSupplier', false);
      await this._rollBackReceptionItems();
    }
    async deleteReceptionItems(receptionItemSource) {
      if (receptionItemSource) {
        let receptionItems = Ember.get(this, 'reception.receptionItems');
        const sourceName = Ember.get(receptionItemSource, 'name');
        const deletedReceptionItems = receptionItems.filter(receptionItem => {
          const receptionItemSourceName = Ember.get(receptionItem, 'source.name');
          return sourceName === receptionItemSourceName;
        });
        receptionItems = receptionItems.filter(receptionItem => {
          const receptionItemSourceName = Ember.get(receptionItem, 'source.name');
          return sourceName !== receptionItemSourceName;
        });
        await this.store.replaceRelatedRecords(this.source, this.reception, 'receptionItems', receptionItems);
        for (let deletedReceptionItem of deletedReceptionItems) {
          await this.store.removeRecord(this.source, deletedReceptionItem);
        }
        Ember.set(this, 'isAddingSupplier', false);
      }
    }
    async updateBatchReceivedWeightByFactor(batch, factor) {
      const receivedWeight = this._calculateReceivedWeightForBatch(batch, factor);
      await this.store.replaceAttribute(this.source, batch, 'receivedWeight', receivedWeight);
    }
    async distributeReceptionWeight() {
      const {
        defaultBatchDivisions,
        isBatchDividedPerSupplier
      } = this.originSettings;
      if (isBatchDividedPerSupplier && defaultBatchDivisions && defaultBatchDivisions.length > 0) {
        await this.receptionItemFactory.distributeReceptionWeight(this.source, this.reception);
      } else {
        for (const batch of this.reception.batches) {
          const {
            factor
          } = batch; // use existing factor from batch!
          await this.updateBatchReceivedWeightByFactor(batch, factor);
        }
      }
    }
    async _rollBackReceptionItems() {
      const focusedReceptionItems = Ember.get(this, 'focusedReceptionItems');
      for (let receptionItem of focusedReceptionItems) {
        if (!Ember.get(receptionItem, 'reception')) {
          await this.store.removeRecord(this.source, receptionItem);
        }
      }
    }
    _calculateReceivedWeightForBatch(batch, factor) {
      const {
        reception: {
          totalWeight
        }
      } = batch;
      let receivedWeight = null;
      if (totalWeight !== null && typeof factor === 'number' && typeof totalWeight === 'object') {
        // try to use unit of `inputWeight` from `yieldFactor1`
        // as it's the actual weight unit for `receivedWeight`
        const targetUnit = batch.yieldFactor1 === null ? totalWeight.unit : batch.yieldFactor1.inputWeight.unit;

        // calculate received weight based on total amount and
        // factor, but normalize weight unit to `targetUnit`
        const {
          amount: total,
          unit
        } = totalWeight;
        const amount = total * factor / 100;
        receivedWeight = {
          amount: (0, _converter.convertWeight)(amount, unit, targetUnit),
          unit: targetUnit
        };
      }
      return receivedWeight;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "l10n", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "synchronization", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "receptionFactory", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "receptionItemFactory", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "originSettings", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "batchService", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "source", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "reception", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "defaultValues", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "pageTitle", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "pageTitle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "displayAddButton", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "displayAddButton"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "contactRole", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "contactRole"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addReceptionItem", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "addReceptionItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "focusReceptionItems", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "focusReceptionItems"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "disableSupplierAdding", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "disableSupplierAdding"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteReceptionItems", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "deleteReceptionItems"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateBatchReceivedWeightByFactor", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "updateBatchReceivedWeightByFactor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "distributeReceptionWeight", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "distributeReceptionWeight"), _class.prototype)), _class));
  _exports.default = BatchesCreateController;
});