define("cropster-origin-app/components/form/measure/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "KWXKky8Q",
    "block": "{\"symbols\":[\"@units\",\"@min\",\"@max\",\"@step\",\"@domId\",\"@value\",\"@disabled\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex-grow-1\"],[8],[0,\"\\n  \"],[5,\"form/input\",[],[[\"@min\",\"@max\",\"@step\",\"@type\",\"@domId\",\"@value\",\"@classNames\",\"@disabled\",\"@onChange\"],[[23,2,[]],[23,3,[]],[23,4,[]],\"number\",[23,5,[]],[23,6,[]],[28,\"class-names\",[\"width-100p\",\"border-right-width-0\",\"border-right-radius-0\"],null],[23,7,[]],[28,\"action\",[[23,0,[]],[23,0,[\"changeAmountHandler\"]]],null]]]],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[28,\"class-names\",[\"border-all\",\"flex-shrink-1\",\"display-flex\",\"items-center\",\"bg-color-grey-4\",\"border-left-width-0\",\"border-all-color-grey\",\"border-all-radius-1\",\"border-left-radius-0\",\"padding-horizontal-2\",[28,\"if\",[[23,7,[]],\"opacity-50\"],null]],null]],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"hasUnits\"]]],null,{\"statements\":[[0,\"    \"],[5,\"form/select\",[],[[\"@isFlat\",\"@classNames\",\"@options\",\"@isAutoWidth\",\"@isAutoHeight\",\"@value\",\"@onChange\"],[true,\"bold\",[23,1,[]],true,true,[23,0,[\"currentUnit\"]],[28,\"action\",[[23,0,[]],[23,0,[\"changeUnitHandler\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"bold\"],[8],[1,[23,0,[\"currentUnit\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-origin-app/components/form/measure/template.hbs"
    }
  });
  _exports.default = _default;
});