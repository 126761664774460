define("cropster-origin-app/services/synchronization", ["exports", "@orbit/utils", "@orbit/core", "@orbit/data", "cropster-origin-app/services/store", "cropster-origin-app/models/reception", "cropster-origin-app/utils/enums", "cropster-origin-app/services/active-group"], function (_exports, _utils, _core, _data, _store, _reception, _enums, _activeGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.EVENT_SYNCING_GROUP_DATA = _exports.EVENT_SYNCED_GROUP_DATA = _exports.EVENT_SCHEDULED_CHANGES = _exports.EVENT_SAVED_LOGS = _exports.EVENT_PUSHING_GROUP_DATA_PROGRESS = _exports.EVENT_PUSHING_GROUP_DATA = _exports.EVENT_PUSHED_GROUP_DATA = _exports.EVENT_PULLING_GROUP_DATA = _exports.EVENT_PULLED_GROUP_DATA = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  // const STORAGE_KEY_LOADED_GROUPS_DATA = `__coa_lgd__`;
  const STORAGE_KEY_SYNCHRONIZATION_LOG = `__coa_sl__`;
  const EVENT_SYNCING_GROUP_DATA = 'synchronization:syncing-all-group-data';
  _exports.EVENT_SYNCING_GROUP_DATA = EVENT_SYNCING_GROUP_DATA;
  const EVENT_SYNCED_GROUP_DATA = 'synchronization:synced-all-group-data';
  _exports.EVENT_SYNCED_GROUP_DATA = EVENT_SYNCED_GROUP_DATA;
  const EVENT_PULLING_GROUP_DATA = 'synchronization:pulling-all-group-data';
  _exports.EVENT_PULLING_GROUP_DATA = EVENT_PULLING_GROUP_DATA;
  const EVENT_PULLED_GROUP_DATA = 'synchronization:pulled-all-group-data';
  _exports.EVENT_PULLED_GROUP_DATA = EVENT_PULLED_GROUP_DATA;
  const EVENT_SCHEDULED_CHANGES = 'synchronization:scheduled-changes';
  _exports.EVENT_SCHEDULED_CHANGES = EVENT_SCHEDULED_CHANGES;
  const EVENT_PUSHING_GROUP_DATA = 'synchronization:pushing-all-group-data';
  _exports.EVENT_PUSHING_GROUP_DATA = EVENT_PUSHING_GROUP_DATA;
  const EVENT_PUSHING_GROUP_DATA_PROGRESS = 'synchronization:pushing-all-group-data-progress';
  _exports.EVENT_PUSHING_GROUP_DATA_PROGRESS = EVENT_PUSHING_GROUP_DATA_PROGRESS;
  const EVENT_PUSHED_GROUP_DATA = 'synchronization:pushed-all-group-data';
  _exports.EVENT_PUSHED_GROUP_DATA = EVENT_PUSHED_GROUP_DATA;
  const EVENT_SAVED_LOGS = 'synchronization:saved-local-logs';

  /**
   * Abstraction service for interacting with remote source (API) mainly.
   * It allows to pull (load) and push (save) data to the remote source.
   *
   * The service sends the following events via `sendEvent()` method:
   *
   * - `synchronization:pulling-all-group-data`: if primitive method for reading all remote data started
   * - `synchronization:pulled-all-group-data`: if reading of all remote data has been finished
   * - `synchronization:pushing-all-group-data`: if primitive method for writing all remote data was called
   * - `synchronization:pushed-all-group-data`: if writing of all remote data has been finished
   * - `synchronization:saved-local-logs`: if new synchronization log has been saved
   * - `synchronization:scheduled-changes`: if new data was scheduled for syncing
   *
   * Note: Please take note of strategies in `orbit/strategies` folder!
   */
  _exports.EVENT_SAVED_LOGS = EVENT_SAVED_LOGS;
  let SynchronizationService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember.inject.service, (_class = class SynchronizationService extends Ember.Service {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "auth", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "storage", _descriptor3, this);
      _initializerDefineProperty(this, "activeUser", _descriptor4, this);
      _initializerDefineProperty(this, "activeGroup", _descriptor5, this);
      _initializerDefineProperty(this, "memorySource", _descriptor6, this);
      _initializerDefineProperty(this, "changeSource", _descriptor7, this);
      _initializerDefineProperty(this, "remoteSource", _descriptor8, this);
      _initializerDefineProperty(this, "backupSource", _descriptor9, this);
    }
    //
    // Methods
    //

    // Setup/Teardown
    async activate() {
      Ember.addListener(this.activeGroup, _activeGroup.EVENT_CHANGED_GROUP, this, this._changedGroupHandler);
    }
    async deactivate() {
      try {
        Ember.removeListener(this.activeGroup, _activeGroup.EVENT_CHANGED_GROUP, this, this._changedGroupHandler);
      } catch (e) {
        // noop
      }
    }

    // Sync
    async sync() {
      Ember.sendEvent(this, EVENT_SYNCING_GROUP_DATA);
      await this.pushToRemote();
      await this.pullFromRemote();
      Ember.sendEvent(this, EVENT_SYNCED_GROUP_DATA);
    }
    isModelSynced(modelOrRecord) {
      (false && !((0, _store.isModel)(modelOrRecord) || (0, _store.isRecordIdentity)(modelOrRecord)) && Ember.assert('`model` must be a model instance!', (0, _store.isModel)(modelOrRecord) || (0, _store.isRecordIdentity)(modelOrRecord)));
      return typeof this._getResourceId(modelOrRecord) === 'string';
    }

    // Schedule
    async scheduleChangesForSync(source) {
      (false && !((0, _store.isMemorySource)(source)) && Ember.assert('`source` must be a memory source!', (0, _store.isMemorySource)(source)));
      await this.store.syncFromSourceToTarget(source, this.changeSource);
      Ember.sendEvent(this, EVENT_SCHEDULED_CHANGES);
    }
    async removeReceptionFromSchedule(reception) {
      (false && !(reception instanceof _reception.default) && Ember.assert('`reception` must be a reception model', reception instanceof _reception.default));
      for (const receptionItem of reception.receptionItems) {
        await this.store.removeRecord(this.changeSource, receptionItem);
      }
      for (const batch of reception.batches) {
        await this.store.removeRecord(this.changeSource, batch);
      }
      await this.store.removeRecord(this.changeSource, reception);
    }
    async hasChangesScheduled() {
      const [{
        operations: {
          length: size
        }
      }] = await this.store.findRecords(this.changeSource);
      return size > 0;
    }

    // Push
    async pushToRemote() {
      (false && !(await this.hasChangesScheduled()) && Ember.assert('There are no changes scheduled to be synced!', await this.hasChangesScheduled()));
      Ember.sendEvent(this, EVENT_PUSHING_GROUP_DATA);

      // note: save `receptions` first to be referenced with created remote
      // ID from server by `receptionItem` and `batch` entities in requests
      const contact = await this._pushToRemoteByType('contact');
      const reception = await this._pushToRemoteByType('reception');
      const receptionItem = await this._pushToRemoteByType('receptionItem');
      const batch = await this._pushToRemoteByType('batch');
      const log = await this._pushLogToLocal({
        contact,
        reception,
        receptionItem,
        batch
      });
      await this._cleanByLogFromLocal(log);
      Ember.sendEvent(this, EVENT_PUSHED_GROUP_DATA, [log]);
      return log;
    }
    async _pushToRemoteByType(type) {
      (false && !(this.store.isValidModelType(type)) && Ember.assert('`type` must be a valid model!', this.store.isValidModelType(type)));
      const taskQueue = new _core.TaskQueue(this.remoteSource, {
        name: `sync-${type}`,
        autoProcess: false
      });
      const results = {
        done: [
          /* ids of successful entities */
        ],
        fail: [
          /* ids of errorenous entities */
        ],
        errors: {
          /* id: error object */
        }
      };
      const progress = {
        count: 0,
        total: 0,
        model: _enums.TRANSLATIONS_MAP[type]
      };
      const failListener = (task, error) => {
        const {
          data: {
            errors: [{
              title,
              detail: message,
              code
            }]
          }
        } = error;
        results.errors[task.id] = {
          message,
          title,
          code
        };
        results.fail.push(task.id);
      };
      const taskListener = task => results.done.push(task.id);
      const beforeTaskListener = () => {
        progress.count++;
        Ember.sendEvent(this, EVENT_PUSHING_GROUP_DATA_PROGRESS, [progress]);
      };
      taskQueue.on('fail', failListener);
      taskQueue.on('task', taskListener);
      taskQueue.on('beforeTask', beforeTaskListener);
      const transforms = await this.store.findRecords(this.changeSource, type);
      for (const {
        operations
      } of transforms) {
        for (const {
          record,
          record: {
            id
          }
        } of operations) {
          // note: at the moment only creating new objects is supported
          // therefore, skip all objects which might have been already
          // synchronized, but still in the database, f.e. when one of
          // the previous synchronizations could not be completed fully
          if (!this._getResourceId(record)) {
            const data = (0, _data.buildTransform)({
              op: 'addRecord',
              record
            });
            const task = {
              id,
              data,
              type: 'push'
            };
            taskQueue.push(task).catch(() => {
              // @see: failListener, but keep the console output quite
            });
            progress.total++;
          } else {
            // schedule immediately for cleanup
            // @see: _cleanByLogFromLocal()
            results.done.push(id);
          }
        }
      }
      do {
        try {
          await taskQueue.process();
        } catch (e) {
          // discard the current task
          // and start with next one!
          await taskQueue.skip();
        }
      } while (taskQueue.length > 0);
      taskQueue.off('fail', failListener);
      taskQueue.off('task', taskListener);
      taskQueue.off('beforeTask', beforeTaskListener);
      return results;
    }
    async _cleanByLogFromLocal(log) {
      const taskQueue = new _core.TaskQueue(this.changeSource, {
        autoProcess: false,
        name: 'clean'
      });
      for (const type in log) {
        switch (type) {
          case 'meta':
            break;
          default:
            for (const id of log[type].done) {
              const data = (0, _data.buildTransform)({
                record: {
                  type,
                  id
                },
                op: 'removeRecord'
              });
              taskQueue.push({
                id,
                data,
                type: 'push'
              });
            }
        }
      }
      do {
        try {
          await taskQueue.process();
        } catch (e) {
          // discard the current task
          // and start with next one!
          await taskQueue.skip();
        }
      } while (taskQueue.length > 0);
    }

    // Pull
    async pullFromRemote() {
      (false && !(typeof this.activeGroup.id === 'string') && Ember.assert('`active-group` needs to be set or loaded to pull data from remote!', typeof this.activeGroup.id === 'string'));
      Ember.sendEvent(this, EVENT_PULLING_GROUP_DATA);
      const {
        id: groupId
      } = this.activeGroup;

      // wipe backup source to get rid of obsolete (aka. deleted) entities
      await this.store.resetSource(this.backupSource);
      await this.store.resetSource(this.memorySource);

      // then pull all data for the origin app from remote server at once
      await this._pullAllDataFromRemoteForGroup();

      // finally sync scheduled changes to the memory source again
      await this.store.syncFromSourceToTarget(this.changeSource, this.memorySource);
      Ember.sendEvent(this, EVENT_PULLED_GROUP_DATA, [groupId]);
    }
    async pullUserFromRemote() {
      // first step is to fetch user's id via login info
      let loginInfo;
      try {
        loginInfo = await this.store.findRecord(this.remoteSource, 'loginInfo', 'current');
      } catch (e) {
        this._handleErrorResponse(e);
      }

      // then query actual user payload from API by id
      let user;
      try {
        user = await this.store.findRecord(this.remoteSource, 'user', loginInfo.id, {
          sources: {
            remote: {
              include: ['users=properties', 'users=groupMemberships.group', 'users=groupMemberships.permissions', 'users=groupMemberships.group.projects', 'users=groupMemberships.group.properties']
            }
          }
        });
      } catch (e) {
        this._handleErrorResponse(e);
      }
      return user;
    }
    async _pullAllDataFromRemoteForGroup() {
      try {
        await this.pullUserFromRemote();
        await this._pullBatchesFromRemote();
        await this._pullFacilitiesFromRemote();
        await this._pullVarietiesFromRemote();
        await this._pullProcessesFromRemote();
        await this._pullContactsFromRemote();
        await this._pullContactRolesFromRemote();
      } catch (e) {
        // noop - error thrown by each sub routines
      }
    }
    async _pullVarietiesFromRemote() {
      const {
        id: groupId
      } = this.activeGroup;
      try {
        return await this.remoteSource.query(queryBuilder => queryBuilder.findRecords('variety').filter({
          attribute: 'varieties:group',
          value: groupId
        }, {
          attribute: 'varieties:isVerified',
          value: true
        }));
      } catch (e) {
        this._handleErrorResponse(e);
      }
    }
    async _pullContactsFromRemote() {
      const {
        id: groupId
      } = this.activeGroup;
      try {
        return await this.store.findRecords(this.remoteSource, 'contact', {
          filter: [{
            attribute: 'contacts:group',
            value: groupId
          }],
          page: {
            offset: 0,
            limit: 9999
          }
        });
      } catch (e) {
        this._handleErrorResponse(e);
      }
    }
    async _pullContactRolesFromRemote() {
      const {
        id: groupId
      } = this.activeGroup;
      try {
        return await this.store.findRecords(this.remoteSource, 'contactRole', {
          filter: [{
            attribute: 'contactRoles:group',
            value: groupId
          }],
          page: {
            offset: 0,
            limit: 9999
          }
        });
      } catch (e) {
        this._handleErrorResponse(e);
      }
    }
    async _pullFacilitiesFromRemote() {
      const {
        id: groupId
      } = this.activeGroup;
      try {
        return await this.store.findRecords(this.remoteSource, 'facility', {
          filter: [{
            attribute: 'facilities:group',
            value: groupId
          }],
          page: {
            offset: 0,
            limit: 9999
          }
        }, {
          sources: {
            remote: {
              include: ['facilities=contact']
            }
          }
        });
      } catch (e) {
        this._handleErrorResponse(e);
      }
    }
    async _pullBatchesFromRemote() {
      const {
        id: groupId
      } = this.activeGroup;
      try {
        return await this.store.findRecords(this.remoteSource, 'batch', {
          filter: [{
            attribute: 'batches:group',
            value: groupId
          }],
          sort: [{
            attribute: 'batches:idTag',
            order: 'descending'
          }],
          page: {
            offset: 0,
            limit: 9999
          }
        }, {
          sources: {
            remote: {
              include: ['batches=reception', 'batches=reception.group', 'batches=reception.batches', 'batches=reception.facility', 'batches=reception.receptionItems', 'batches=reception.receptionItems.source', 'batches=reception.receptionItems.sourceRole', 'batches=process', 'batches=project', 'batches=facility', 'batches=facility.contact', 'batches=varieties', 'batches=community', 'batches=farm', 'batches=field', 'batches=group']
            }
          }
        });
      } catch (e) {
        this._handleErrorResponse(e);
      }
    }
    async _pullProcessesFromRemote() {
      const {
        id: groupId
      } = this.activeGroup;
      try {
        return await this.store.findRecords(this.remoteSource, 'process', {
          filter: [{
            attribute: 'processes:group',
            value: groupId
          }],
          page: {
            offset: 0,
            limit: 9999
          }
        });
      } catch (e) {
        this._handleErrorResponse(e);
      }
    }

    // Logs
    async pullLogsFromLocal() {
      const logs = await this.storage.getItem(STORAGE_KEY_SYNCHRONIZATION_LOG);
      return Array.isArray(logs) && logs || [];
    }
    async clearLogsFromLocal() {
      await this.storage.setItem(STORAGE_KEY_SYNCHRONIZATION_LOG, []);
      Ember.sendEvent(this, EVENT_SAVED_LOGS);
    }
    async _pushLogToLocal(data) {
      const logs = (await this.storage.getItem(STORAGE_KEY_SYNCHRONIZATION_LOG)) || [];
      let done = 0;
      let fail = 0;
      for (const type in data) {
        done += data[type].done.length;
        fail += data[type].fail.length;
      }
      const log = {
        ...{
          meta: {
            done,
            fail,
            uuid: (0, _utils.uuid)(),
            date: new Date(),
            count: logs.length + 1
          }
        },
        ...(data || {})
      };
      await this.storage.setItem(STORAGE_KEY_SYNCHRONIZATION_LOG, [...[log], ...logs]);
      Ember.sendEvent(this, EVENT_SAVED_LOGS);
      return log;
    }

    // Misc
    _changedGroupHandler() {
      if (!this.activeUser.hasViewPermissions) {
        return;
      }
      if (this.activeGroup.id === null) {
        return;
      }
      this.pullFromRemote();
    }
    _getResourceId(modelOrRecord) {
      (false && !((0, _store.isModel)(modelOrRecord) || (0, _store.isRecordIdentity)(modelOrRecord)) && Ember.assert('`model` must be a model instance!', (0, _store.isModel)(modelOrRecord) || (0, _store.isRecordIdentity)(modelOrRecord)));
      const {
        type,
        id
      } = modelOrRecord;
      const {
        requestProcessor: {
          serializer
        }
      } = this.remoteSource;
      return serializer.resourceId(type, id);
    }
    _handleErrorResponse(error) {
      if (error instanceof _data.ServerError) {
        const {
          data: {
            errors = []
          } = {}
        } = error;
        const [{
          status,
          detail
        } = {}] = errors;
        throw new Error(`${status}: ${detail}`);
      } else {
        throw error;
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "auth", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "storage", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "activeUser", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "activeGroup", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "memorySource", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "changeSource", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "remoteSource", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "backupSource", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SynchronizationService;
});