define("cropster-origin-app/orbit/schemas/batch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    keys: {
      remoteId: {}
    },
    attributes: {
      idTag: {
        type: 'string'
      },
      part: {
        type: 'string'
      },
      partName: {
        type: 'string'
      },
      price: {
        type: 'currency'
      },
      cropYear: {
        type: 'measure'
      },
      receptionDate: {
        type: 'date'
      },
      storageDate: {
        type: 'date'
      },
      millingDate: {
        type: 'date'
      },
      receivedWeightOverride: {
        type: 'measure'
      },
      intermediateWeightTargetOverride: {
        type: 'measure'
      },
      intermediateWeightSourceOverride: {
        type: 'measure'
      },
      finalWeightOverride: {
        type: 'measure'
      },
      receivedWeight: {
        type: 'measure'
      },
      intermediateWeight: {
        type: 'measure'
      },
      finalWeight: {
        type: 'measure'
      },
      notes: {
        type: 'string'
      },
      isPiled: {
        type: 'boolean'
      }
    },
    relationships: {
      group: {
        type: 'hasOne',
        model: 'group'
      },
      facility: {
        type: 'hasOne',
        model: 'facility'
      },
      project: {
        type: 'hasOne',
        model: 'project'
      },
      process: {
        type: 'hasOne',
        model: 'process'
      },
      community: {
        type: 'hasOne',
        model: 'contact'
      },
      field: {
        type: 'hasOne',
        model: 'contact'
      },
      farm: {
        type: 'hasOne',
        model: 'contact'
      },
      varieties: {
        type: 'hasMany',
        model: 'variety'
      },
      reception: {
        type: 'hasOne',
        model: 'reception',
        inverse: 'batches'
      }
    }
  };
  _exports.default = _default;
});