define("cropster-origin-app/unsupported/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "tkJdJUI3",
    "block": "{\"symbols\":[],\"statements\":[[5,\"layout/header\",[],[[\"@title\"],[[28,\"t\",[\"Unsupported\"],null]]]],[0,\"\\n\\n\"],[5,\"layout/wrapper\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[5,\"info-message\",[],[[\"@type\",\"@title\"],[\"warning\",[28,\"t\",[\"Missing feature support\"],null]]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"t\",[\"Sorry, but the Origin App requires IndexedDB to work properly. This can also happen if you are using the application in a private browser tab.\"],null],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-origin-app/unsupported/template.hbs"
    }
  });
  _exports.default = _default;
});