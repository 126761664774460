define("cropster-origin-app/components/groups/available-group/component", ["exports", "cropster-origin-app/models/group"], function (_exports, _group) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let AvailableGroup = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed('group.id', 'activeGroup.id'), _dec4 = Ember.computed.not('isActive'), _dec5 = Ember.computed.and('networkStatus.isOnline', 'isActive'), _dec6 = Ember.computed.and('networkStatus.isOnline', 'isInactive'), _dec7 = Ember.computed.or('isActive', 'networkStatus.isOffline'), (_class = class AvailableGroup extends Ember.Component {
    constructor() {
      super(...arguments);
      _defineProperty(this, "tagName", 'button');
      _defineProperty(this, "classNames", ['border-all-radius-1', 'padding-all-3', 'display-block', 'box-shadow', 'width-100p', 'bold']);
      _defineProperty(this, "classNameBindings", ['isActive:border-all-color-primary:border-all-color-grey', 'isActive:border-all-width-2px:border-all-width-1px', 'isActive:bg-color-primary-light:bg-color-grey-5', 'isOnlineAndActive:bg-hover-color-primary-light', 'isOnlineAndInactive:bg-hover-color-grey-4', 'isActive:color-primary:color-grey', 'disabled:opacity-50']);
      _defineProperty(this, "attributeBindings", ['disabled']);
      _initializerDefineProperty(this, "activeGroup", _descriptor, this);
      _initializerDefineProperty(this, "networkStatus", _descriptor2, this);
      //
      // Arguments
      //
      _defineProperty(this, "group", null);
      _defineProperty(this, "onSelect", ( /* group */) => {});
      _initializerDefineProperty(this, "isInactive", _descriptor3, this);
      _initializerDefineProperty(this, "isOnlineAndActive", _descriptor4, this);
      _initializerDefineProperty(this, "isOnlineAndInactive", _descriptor5, this);
      _initializerDefineProperty(this, "disabled", _descriptor6, this);
    }
    //
    // Properties
    //
    get isActive() {
      return this.group.id === this.activeGroup.id;
    }
    //
    // Hooks
    //
    init() {
      super.init(...arguments);
      (false && !(this.group instanceof _group.default) && Ember.assert('`group` must be a group model!', this.group instanceof _group.default));
    }

    //
    // Actions
    //
    async click() {
      const {
        group: {
          id
        }
      } = this;
      await this.activeGroup.save(id);
      this.onSelect(this.group);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "activeGroup", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "networkStatus", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isActive", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "isActive"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isInactive", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isOnlineAndActive", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isOnlineAndInactive", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "disabled", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AvailableGroup;
});