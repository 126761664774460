define("cropster-origin-app/components/form/filterable-select/component", ["exports", "cropster-origin-app/components/form/abstract/select/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let FilterableSelect = (_dec = Ember.computed('mappedOptions.[]', 'search'), _dec2 = Ember.computed.or('showAll', 'search'), _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class FilterableSelect extends _component.default {
    constructor() {
      super(...arguments);
      //
      // Arguments
      //
      _defineProperty(this, "multiple", false);
      _defineProperty(this, "disabled", false);
      _initializerDefineProperty(this, "isExpanded", _descriptor, this);
      _defineProperty(this, "showAll", false);
      _defineProperty(this, "search", '');
    }
    onToggle() {}
    onChange() {}
    onSearch() {}

    //
    // Properties
    //
    get filteredOptions() {
      if (this.search.length > 0) {
        const regex = new RegExp(this.search, 'gi');
        return this.mappedOptions.filter(_ref => {
          let {
            label
          } = _ref;
          return label.search(regex) >= 0;
        });
      }
      return this.mappedOptions;
    }
    //
    // Actions
    //
    changeHandler(_ref2) {
      let {
        value
      } = _ref2;
      if (this.multiple) {
        if (value in this.valueMap) {
          // remove selected option
          value = this.mappedValues.filter(_ref3 => {
            let {
              value: currentValue
            } = _ref3;
            return currentValue !== value;
          }).map(_ref4 => {
            let {
              value
            } = _ref4;
            return this.processOption(this._optionsMap[value]);
          });
        } else {
          // add unselected option
          value = [...this.mappedValues.map(_ref5 => {
            let {
              value
            } = _ref5;
            return this.processOption(this._optionsMap[value]);
          }), ...[this.processOption(this._optionsMap[value])]];
        }
      } else {
        value = value in this.valueMap ? this.processOption(this._optionsMap[value]) : null;
      }
      this.onChange(value);
    }
    showAllHandler(checked) {
      this.onToggle(Ember.set(this, 'showAll', checked));
    }
    searchHandler(search) {
      this.onSearch(Ember.set(this, 'search', search));
    }
    resetHandler() {
      Ember.set(this, 'search', '');
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "filteredOptions", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "filteredOptions"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "isExpanded", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "changeHandler", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "changeHandler"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showAllHandler", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "showAllHandler"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchHandler", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "searchHandler"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetHandler", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "resetHandler"), _class.prototype)), _class));
  _exports.default = FilterableSelect;
});