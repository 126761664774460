define("cropster-origin-app/components/form/input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "vNJrVttq",
    "block": "{\"symbols\":[],\"statements\":[],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-origin-app/components/form/input/template.hbs"
    }
  });
  _exports.default = _default;
});