define("cropster-origin-app/components/form/abstract/select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "INVvCYwo",
    "block": "{\"symbols\":[\"current\",\"data\",\"&default\"],\"statements\":[[4,\"each\",[[23,0,[\"mappedOptions\"]]],null,{\"statements\":[[4,\"let\",[[28,\"hash\",null,[[\"option\",\"selected\"],[[23,1,[]],[28,\"if\",[[28,\"get-value\",[[23,0,[\"valueMap\"]],[23,1,[\"value\"]]],null],true,false],null]]]]],null,{\"statements\":[[4,\"if\",[[25,3]],null,{\"statements\":[[0,\"      \"],[14,3,[[23,2,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"option\",true],[11,\"selected\",[23,2,[\"selected\"]]],[11,\"value\",[23,2,[\"option\",\"value\"]]],[8],[0,\"\\n        \"],[1,[23,2,[\"option\",\"label\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-origin-app/components/form/abstract/select/template.hbs"
    }
  });
  _exports.default = _default;
});