define("cropster-origin-app/groups/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "eLNsei9o",
    "block": "{\"symbols\":[\"group\"],\"statements\":[[5,\"layout/header\",[],[[\"@title\",\"@onGoBack\"],[[28,\"t\",[\"Groups\"],null],[28,\"action\",[[23,0,[]],[23,0,[\"goToIndex\"]]],null]]]],[0,\"\\n\\n\"],[5,\"layout/wrapper\",[],[[],[]],{\"statements\":[[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"isOffline\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"margin-bottom-4\"],[8],[0,\"\\n      \"],[5,\"info-message\",[],[[\"@type\",\"@title\"],[\"warning\",\"No network available\"]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"t\",[\"You need network connectivity to select groups.\"],null],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"text-center bold\"],[8],[1,[28,\"t\",[\"Please choose your group:\"],null],false],[9],[0,\"\\n\"],[4,\"each\",[[23,0,[\"groups\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"margin-bottom-2\"],[8],[0,\"\\n      \"],[5,\"groups/available-group\",[],[[\"@group\",\"@onSelect\"],[[23,1,[]],[28,\"action\",[[23,0,[]],[23,0,[\"onSelect\"]],[23,1,[]]],null]]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"    \"],[5,\"info-message\",[],[[\"@type\"],[\"error\"]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"Your user has no active groups, please contact one of your administrator.\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-origin-app/groups/template.hbs"
    }
  });
  _exports.default = _default;
});