define("cropster-origin-app/logs/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let LogsController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed.readOnly('model'), _dec5 = Ember.computed.gt('logs.length', 0), _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class LogsController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "l10n", _descriptor, this);
      _initializerDefineProperty(this, "toast", _descriptor2, this);
      _initializerDefineProperty(this, "synchronization", _descriptor3, this);
      _defineProperty(this, "queryParams", ['uuid']);
      _defineProperty(this, "uuid", null);
      //
      // Properties
      //
      _initializerDefineProperty(this, "logs", _descriptor4, this);
      _initializerDefineProperty(this, "hasLogs", _descriptor5, this);
    }
    //
    // Actions
    //
    goToIndex() {
      this.transitionToRoute('index');
    }
    toggleLog(_ref) {
      let {
        meta: {
          uuid
        }
      } = _ref;
      Ember.set(this, 'uuid', this.uuid === uuid ? null : uuid);
    }
    async clearLogs() {
      await this.synchronization.clearLogsFromLocal();
      const message = this.l10n.t('Successfully cleared all logs.');
      this.toast.success(message);
      Ember.set(this, 'uuid', null);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "l10n", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "synchronization", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "logs", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "hasLogs", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "goToIndex", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "goToIndex"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleLog", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "toggleLog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearLogs", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "clearLogs"), _class.prototype)), _class));
  _exports.default = LogsController;
});