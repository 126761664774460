define("cropster-origin-app/components/form/datalist/component", ["exports", "cropster-origin-app/components/form/abstract/select/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let DataList = (_dec = Ember.computed('elementId'), _dec2 = Ember.computed('mappedOptions.[]'), _dec3 = Ember._action, (_class = class DataList extends _component.default {
    constructor() {
      super(...arguments);
      // tagName = '';
      //
      // Attributes
      //
      _defineProperty(this, "disabled", false);
      _defineProperty(this, "_uniqueMappings", {
        /* unique label: value */
      });
    }
    onChange() {}

    //
    // Properties
    //
    get listId() {
      return `list-${this.elementId}`;
    }
    get uniqueOptions() {
      // data list may have duplicate values because we have to show labels,
      // as there is no way to show only label without actual value (f.e. id)
      // to distinguish between options with same label we suffix them, f.e.
      //
      // 'label' => 'label',
      // 'label' => 'label (2)'
      // 'label' => 'label (3)'
      //
      // when element gets selected we map the patched labels with actual value
      const options = [];
      const visited = {};
      let counter = 1;
      for (let {
        label,
        value
      } of this.mappedOptions) {
        options.push(visited[label] ? `${label} (${counter})` : label);
        this._uniqueMappings[options[options.length - 1]] = value;
        visited[label] = counter++;
      }
      return options;
    }
    //
    // Actions
    //
    changeHandler(value) {
      // a) user selected a known option
      // b) user provided custom option
      if (value in this._uniqueMappings) {
        value = this._uniqueMappings[value];
        value = this.processOption(this._optionsMap[value]);
      }
      this.onChange(value);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "listId", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "listId"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "uniqueOptions", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "uniqueOptions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeHandler", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "changeHandler"), _class.prototype)), _class));
  _exports.default = DataList;
});