define("cropster-origin-app/components/form/field/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TYPE_TOGGLE = _exports.TYPE_TEXTAREA = _exports.TYPE_TEXT = _exports.TYPE_SELECT = _exports.TYPE_RADIO_GROUP = _exports.TYPE_PASSWORD = _exports.TYPE_NUMBER = _exports.TYPE_MEASURE = _exports.TYPE_FILTERABLE_SELECT = _exports.TYPE_DATE = _exports.TYPE_DATALIST = _exports.TYPE_CHECKBOX = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const TYPE_TEXT = 'text';
  _exports.TYPE_TEXT = TYPE_TEXT;
  const TYPE_DATE = 'date';
  _exports.TYPE_DATE = TYPE_DATE;
  const TYPE_SELECT = 'select';
  _exports.TYPE_SELECT = TYPE_SELECT;
  const TYPE_NUMBER = 'number';
  _exports.TYPE_NUMBER = TYPE_NUMBER;
  const TYPE_TOGGLE = 'toggle';
  _exports.TYPE_TOGGLE = TYPE_TOGGLE;
  const TYPE_MEASURE = 'measure';
  _exports.TYPE_MEASURE = TYPE_MEASURE;
  const TYPE_CHECKBOX = 'checkbox';
  _exports.TYPE_CHECKBOX = TYPE_CHECKBOX;
  const TYPE_DATALIST = 'datalist';
  _exports.TYPE_DATALIST = TYPE_DATALIST;
  const TYPE_PASSWORD = 'password';
  _exports.TYPE_PASSWORD = TYPE_PASSWORD;
  const TYPE_TEXTAREA = 'textarea';
  _exports.TYPE_TEXTAREA = TYPE_TEXTAREA;
  const TYPE_RADIO_GROUP = 'radio-group';
  _exports.TYPE_RADIO_GROUP = TYPE_RADIO_GROUP;
  const TYPE_FILTERABLE_SELECT = 'filterable-select';
  _exports.TYPE_FILTERABLE_SELECT = TYPE_FILTERABLE_SELECT;
  let FormField = (_dec = Ember.computed('elementId'), _dec2 = Ember.computed('error', 'warning', 'info'), _dec3 = Ember.computed.or('error', 'warning', 'info', 'hint'), _dec4 = Ember.computed('_isDirty', 'messageIfDirty', 'message'), _dec5 = Ember.computed('type', 'showPassword'), _dec6 = Ember.computed.equal('type', TYPE_TEXT), _dec7 = Ember.computed.equal('type', TYPE_DATE), _dec8 = Ember.computed.equal('type', TYPE_NUMBER), _dec9 = Ember.computed.equal('type', TYPE_PASSWORD), _dec10 = Ember.computed.equal('type', TYPE_SELECT), _dec11 = Ember.computed.equal('type', TYPE_MEASURE), _dec12 = Ember.computed.equal('type', TYPE_DATALIST), _dec13 = Ember.computed.equal('type', TYPE_CHECKBOX), _dec14 = Ember.computed.equal('type', TYPE_RADIO_GROUP), _dec15 = Ember.computed.equal('type', TYPE_TOGGLE), _dec16 = Ember.computed.equal('type', TYPE_TEXTAREA), _dec17 = Ember.computed.equal('type', TYPE_FILTERABLE_SELECT), _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, (_class = class FormField extends Ember.Component {
    constructor() {
      super(...arguments);
      //
      // Arguments
      //
      _defineProperty(this, "type", TYPE_TEXT);
      _defineProperty(this, "label", null);
      _defineProperty(this, "hint", null);
      _defineProperty(this, "info", null);
      _defineProperty(this, "error", null);
      _defineProperty(this, "warning", null);
      _defineProperty(this, "messageIfDirty", true);
      _defineProperty(this, "proxy", {});
      //
      // Properties
      //
      _defineProperty(this, "_isDirty", false);
      _defineProperty(this, "showPassword", false);
      _initializerDefineProperty(this, "message", _descriptor, this);
      _initializerDefineProperty(this, "isText", _descriptor2, this);
      _initializerDefineProperty(this, "isDate", _descriptor3, this);
      _initializerDefineProperty(this, "isNumber", _descriptor4, this);
      _initializerDefineProperty(this, "isPassword", _descriptor5, this);
      _initializerDefineProperty(this, "isSelect", _descriptor6, this);
      _initializerDefineProperty(this, "isMeasure", _descriptor7, this);
      _initializerDefineProperty(this, "isDatalist", _descriptor8, this);
      _initializerDefineProperty(this, "isCheckbox", _descriptor9, this);
      _initializerDefineProperty(this, "isRadioGroup", _descriptor10, this);
      _initializerDefineProperty(this, "isToggle", _descriptor11, this);
      _initializerDefineProperty(this, "isTextarea", _descriptor12, this);
      _initializerDefineProperty(this, "isFilterableSelect", _descriptor13, this);
    }
    get domId() {
      return `field-${this.elementId}`;
    }
    get messageClassNames() {
      return ['margin-top-2', 'padding-all-3', 'border-all-radius-1', this.error && 'bg-color-error-light' || this.warning && 'bg-color-warning-light' || this.info && 'bg-color-info-light' || 'bg-color-grey-5'].join(' ');
    }
    get showMessage() {
      if (typeof this.message !== 'string') {
        return false;
      }
      return this.messageIfDirty ? this._isDirty : true;
    }
    get inputType() {
      switch (this.type) {
        case 'password':
          return !this.showPassword ? TYPE_PASSWORD : TYPE_TEXT;
        default:
          return this.type;
      }
    }
    //
    // Methods
    //
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      Ember.set(this, 'proxy', {
        ...{
          // general
          value: null,
          disabled: false,
          // input / textarea
          min: 0,
          max: Number.POSITIVE_INFINITY,
          step: 'any',
          placeholder: null,
          // abstract select
          options: [],
          multiple: false,
          valuePath: 'value',
          labelPath: 'label',
          valueByReference: false,
          // abstract checkbox
          checked: false,
          // select
          isFlat: false,
          isAutoWidth: false,
          isAutoHeight: false,
          hasEmptyOption: false,
          // radio buttons
          isVertical: false,
          // measure
          units: [],
          unit: null,
          unitKey: 'unit'
        },
        ...(typeof this.proxy === 'object' && this.proxy !== null ? this.proxy : {})
      });
    }

    //
    // Actions
    //
    changeHandler() {
      if (typeof this.proxy.onChange === 'function') {
        this.proxy.onChange(...arguments);
        Ember.set(this, '_isDirty', true);
      }
    }
    inputHandler() {
      if (typeof this.proxy.onInput === 'function') {
        this.proxy.onInput(...arguments);
        Ember.set(this, '_isDirty', true);
      }
    }
    togglePassword() {
      this.toggleProperty('showPassword');
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "domId", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "domId"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "messageClassNames", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "messageClassNames"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "message", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showMessage", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "showMessage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "inputType", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "inputType"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isText", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isDate", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isNumber", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isPassword", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isSelect", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isMeasure", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isDatalist", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isCheckbox", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isRadioGroup", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isToggle", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isTextarea", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "isFilterableSelect", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "changeHandler", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "changeHandler"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "inputHandler", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "inputHandler"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "togglePassword", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "togglePassword"), _class.prototype)), _class));
  _exports.default = FormField;
});