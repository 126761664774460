define("cropster-origin-app/components/form/radio-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "kya2T2tu",
    "block": "{\"symbols\":[\"current\",\"data\",\"&default\"],\"statements\":[[4,\"each\",[[23,0,[\"mappedOptions\"]]],null,{\"statements\":[[4,\"let\",[[28,\"hash\",null,[[\"option\",\"component\"],[[23,1,[]],[28,\"component\",[\"form/radio\"],[[\"name\",\"checked\",\"label\",\"value\",\"onChange\"],[[23,0,[\"name\"]],[28,\"if\",[[28,\"get\",[[23,0,[\"valueMap\"]],[23,1,[\"value\"]]],null],true,false],null],[23,1,[\"label\"]],[23,1,[\"value\"]],[28,\"action\",[[23,0,[]],[23,0,[\"changeHandler\"]],[23,1,[]]],null]]]]]]]],null,{\"statements\":[[4,\"if\",[[25,3]],null,{\"statements\":[[0,\"      \"],[14,3,[[23,2,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[6,[23,2,[\"component\"]],[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-origin-app/components/form/radio-group/template.hbs"
    }
  });
  _exports.default = _default;
});