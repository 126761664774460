define("cropster-origin-app/batches/index/controller", ["exports", "cropster-origin-app/services/store", "cropster-origin-app/utils/enums"], function (_exports, _store, _enums) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let BatchesController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed.readOnly('model'), _dec5 = Ember.computed.readOnly('batches.length'), _dec6 = Ember.computed.gt('batches.length', 0), _dec7 = Ember.computed.readOnly('activeUser.hasEditPermissions'), _dec8 = Ember.computed.readOnly('activeUser.hasViewPermissions'), _dec9 = Ember.computed.readOnly('originSettings.viewFields'), _dec10 = Ember.computed.equal('sortDirection', _store.SORT_DIRECTION_ASC), _dec11 = Ember.computed.equal('sortDirection', _store.SORT_DIRECTION_DESC), _dec12 = Ember.computed, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, (_class = class BatchesController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "activeUser", _descriptor, this);
      _initializerDefineProperty(this, "originSettings", _descriptor2, this);
      _initializerDefineProperty(this, "synchronization", _descriptor3, this);
      _defineProperty(this, "queryParams", ['sortField', 'sortDirection']);
      _defineProperty(this, "sortField", null);
      _defineProperty(this, "sortDirection", null);
      //
      // Properties
      //
      _defineProperty(this, "isLoading", false);
      _defineProperty(this, "isCustomizing", false);
      _defineProperty(this, "sortFieldOptions", _enums.FIELD_OPTIONS);
      _initializerDefineProperty(this, "batches", _descriptor4, this);
      _initializerDefineProperty(this, "totalBatches", _descriptor5, this);
      _initializerDefineProperty(this, "hasBatches", _descriptor6, this);
      _initializerDefineProperty(this, "hasEditPermissions", _descriptor7, this);
      _initializerDefineProperty(this, "hasViewPermissions", _descriptor8, this);
      _initializerDefineProperty(this, "fields", _descriptor9, this);
      _initializerDefineProperty(this, "isSortingAsc", _descriptor10, this);
      _initializerDefineProperty(this, "isSortingDesc", _descriptor11, this);
    }
    get toggleFieldOptions() {
      return _enums.FIELD_OPTIONS.filter(_ref => {
        let {
          value
        } = _ref;
        return value !== _enums.FIELD_IDTAG;
      });
    }

    //
    // Actions
    //
    toggleCustomize() {
      Ember.set(this, 'isCustomizing', !this.isCustomizing);
    }
    hideCustomize() {
      Ember.set(this, 'isCustomizing', false);
    }
    goToCreate() {
      this.transitionToRoute('batches.create');
    }
    async toggleField(field, checked) {
      Ember.set(this, 'originSettings.viewFields', {
        ...this.fields,
        ...{
          [field]: checked
        }
      });
      await this.originSettings.saveUserSettings();
    }
    async changeSortField(value) {
      Ember.set(this, 'sortField', value);
    }
    async toggleSortDirection() {
      switch (this.sortDirection) {
        case _store.SORT_DIRECTION_ASC:
          Ember.set(this, 'sortDirection', _store.SORT_DIRECTION_DESC);
          break;
        case _store.SORT_DIRECTION_DESC:
          Ember.set(this, 'sortDirection', _store.SORT_DIRECTION_ASC);
          break;
        default:
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "activeUser", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "originSettings", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "synchronization", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "batches", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "totalBatches", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "hasBatches", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "hasEditPermissions", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "hasViewPermissions", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "fields", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isSortingAsc", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isSortingDesc", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggleFieldOptions", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "toggleFieldOptions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleCustomize", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "toggleCustomize"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hideCustomize", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "hideCustomize"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goToCreate", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "goToCreate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleField", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "toggleField"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeSortField", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "changeSortField"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleSortDirection", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "toggleSortDirection"), _class.prototype)), _class));
  _exports.default = BatchesController;
});