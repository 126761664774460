define("cropster-origin-app/orbit/sources/remote", ["exports", "@orbit/jsonapi", "cropster-origin-app/orbit/schema", "cropster-origin-app/orbit/sources/remote/json-api-url-builder", "cropster-origin-app/orbit/sources/remote/json-api-serializer", "cropster-origin-app/orbit/sources/remote/remote-host", "cropster-origin-app/orbit/sources/key-map"], function (_exports, _jsonapi, _schema, _jsonApiUrlBuilder, _jsonApiSerializer, _remoteHost, _keyMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const remote = new _jsonapi.default({
    host: _remoteHost.default,
    schema: _schema.default,
    keyMap: _keyMap.default,
    name: 'remote',
    defaultFetchSettings: {
      timeout: 0
    },
    URLBuilderClass: _jsonApiUrlBuilder.default,
    SerializerClass: _jsonApiSerializer.default
  });
  var _default = remote;
  _exports.default = _default;
});