define("cropster-origin-app/services/orbit-facade", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  /**
   * Facade for setting up and tearing down Orbit.js environment in app.
   *
   * Note: Please take note of strategies in `orbit/strategies` folder!
   */
  let OrbitFacadeService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember.inject.service, _dec10 = Ember.inject.service, _dec11 = Ember.inject.service, _dec12 = Ember.inject.service, _dec13 = Ember.inject.service, (_class = class OrbitFacadeService extends Ember.Service {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "auth", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "storage", _descriptor3, this);
      _initializerDefineProperty(this, "activeUser", _descriptor4, this);
      _initializerDefineProperty(this, "activeGroup", _descriptor5, this);
      _initializerDefineProperty(this, "coordinator", _descriptor6, this);
      _initializerDefineProperty(this, "backupSource", _descriptor7, this);
      _initializerDefineProperty(this, "memorySource", _descriptor8, this);
      _initializerDefineProperty(this, "remoteSource", _descriptor9, this);
      _initializerDefineProperty(this, "networkStatus", _descriptor10, this);
      _initializerDefineProperty(this, "originSettings", _descriptor11, this);
      _initializerDefineProperty(this, "synchronization", _descriptor12, this);
      _initializerDefineProperty(this, "receptionFactory", _descriptor13, this);
    }
    async activate() {
      // 1. configure the fetch implementation with correct headers
      const token = await this.auth.getToken();
      const fetchSettings = this.auth.getDefaultFetchOptions(token);
      const {
        requestProcessor: {
          defaultFetchSettings
        }
      } = this.remoteSource;
      const mergedFetchSettings = {
        ...defaultFetchSettings,
        ...fetchSettings
      };
      this.remoteSource.requestProcessor.defaultFetchSettings = {
        ...mergedFetchSettings
      };

      // 2. synchronize all offline data to the memory source before
      // before setting up the coordinator with its sync strategies

      // let's wait for required sources to be booted before using it
      await this.backupSource.activate();
      await this.memorySource.activate();

      // then pull latest data from our backup source (indexeddb/local
      // storage) and sync with memory before spinning up coordinator,
      // so that sync strategies are not applied while doing this task
      await this.store.syncFromSourceToTarget(this.backupSource, this.memorySource);

      // after manually updating memory from offline data, activate
      // the coordinator so that default data flow applies from now
      // remote source -> memory source -> backup source
      // change source -> memory source -> backup source
      await this.coordinator.activate();

      // 3. now activate all internal services implementing interface
      await this.receptionFactory.activate();
      await this.synchronization.activate();
      await this.originSettings.activate();
      await this.networkStatus.activate();
      await this.activeGroup.activate();
      await this.activeUser.activate();

      // 4. fetch or load current user, groups and (origin) settings

      // check if there's a user matching username of current token
      // and store the currently active user via active-user service
      // otherwise just reify the stored user id from local storage
      // @see: _invalidatedHandler()
      const [username] = this.auth.decodeToken(token);
      const users = this.store.findRecords(this.memorySource, 'user', {
        filter: [{
          attribute: 'username',
          value: username
        }]
      });
      if (users.length === 0) {
        try {
          const user = await this.synchronization.pullUserFromRemote();
          await this.activeUser.save(user.id);
        } catch (e) {
          await this.deactivate();
        }
      } else {
        await this.activeUser.load();
      }

      // try to reify the active group from the current user
      await this.activeGroup.load();

      // finally load origin settings (bound to user/group)
      await this.originSettings.load();
    }
    async deactivate() {
      // wipe memory and backup resource too in order to force reload user/groups
      // when activating the facade again - there may be changed user/group data!
      await this.store.resetSource(this.memorySource);
      await this.store.resetSource(this.backupSource);

      // if user has invalidated the session remove the stored id from storage
      await this.activeUser.clear();

      // undo group selection once a user session has been invalidated to force
      // re-selection of groups on next login for the rare case active group isn't
      // linked to the user anymore - easier to let him reselect the group on login
      await this.activeGroup.clear();

      // deactivate all internal services before shutting down orbit.js
      await this.receptionFactory.deactivate();
      await this.synchronization.deactivate();
      await this.originSettings.deactivate();
      await this.networkStatus.deactivate();
      await this.activeGroup.deactivate();
      await this.activeUser.deactivate();

      // finally also shut down orbit's coordinator disabling strategies
      await this.coordinator.deactivate();
    }
    async isSupported() {
      // try to open connection to indexeddb - this could lead to error
      // in private browsing mode when a browser blocks database access
      try {
        await this.storage.openDB();
      } catch (e) {
        return false;
      }
      return true;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "auth", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "storage", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "activeUser", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "activeGroup", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "coordinator", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "backupSource", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "memorySource", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "remoteSource", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "networkStatus", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "originSettings", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "synchronization", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "receptionFactory", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = OrbitFacadeService;
});