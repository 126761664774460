define("cropster-origin-app/services/auth", ["exports", "fetch", "cropster-origin-app/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.EVENT_INVALIDATED = _exports.EVENT_AUTHENTICATED = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const STORAGE_KEY = `__coa_atk__`;
  const SIGNIN_URL = `${_environment.default.apiUrl}/api/login`;
  const CLIENT_VERSION = `cropster-origin_${_environment.default.APP.version}`;
  const EVENT_INVALIDATED = 'auth:invalidated';
  _exports.EVENT_INVALIDATED = EVENT_INVALIDATED;
  const EVENT_AUTHENTICATED = 'auth:authenticated';

  /**
   * Auth service handling active user session.
   *
   * It creates user session via `authenticate()` and destroys it via `invalidate()`.
   * It can do reauthentication based on stored auth token via `reauthenticate()`.
   * It provides user datavia `loadUser()` data of defined `user` schema.
   *
   * Gaining access to user's payload requires the follwoing steps:
   *
   * 1) sending credentials to login route -> `authenticate()`
   * 2) retrieving user's id from login info route  -> `loadUser()`
   * 3) retrieving user's data from user's json api route -> `loadUser()`
   *
   * The service sends the following events via `sendEvent()` method:
   *
   * - `auth:authenticated`: after successful authentication in `authenticate()`
   * - `auth:loaded-user-data`: after successful data retrieval in `loadUser()`
   * - `auth:invalided`: after successful invalidation in `invalidate()`
   */
  _exports.EVENT_AUTHENTICATED = EVENT_AUTHENTICATED;
  let AuthService = (_dec = Ember.inject.service, (_class = class AuthService extends Ember.Service {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "storage", _descriptor, this);
    }
    //
    // Methods
    //
    async authenticate(username, password) {
      // verify if credentials are correct via login route
      // stores the authentication token in local storage
      // sends `auth:auhenticated` event on success
      (false && !(typeof username === 'string' && username.length > 0) && Ember.assert('`username` must be a non-empty string!', typeof username === 'string' && username.length > 0));
      (false && !(typeof password === 'string' && password.length > 0) && Ember.assert('`password` must be a non-empty string!', typeof password === 'string' && password.length > 0));
      const token = this.encodeToken(username, password);
      const options = {
        ...this.getDefaultFetchOptions(token),
        ...{
          method: 'POST'
        }
      };
      const response = await (0, _fetch.default)(SIGNIN_URL, options);
      if (response.ok === false) {
        this._handleErrorResponse(response);
      }
      await this.storage.setItem(STORAGE_KEY, token);
      Ember.sendEvent(this, EVENT_AUTHENTICATED, [token]);
      return true;
    }
    async invalidate() {
      // removes authentication token from storage
      // sends `auth:invalidated` event on success
      await this.storage.removeItem(STORAGE_KEY);
      Ember.sendEvent(this, EVENT_INVALIDATED);
      return true;
    }
    async getToken() {
      return await this.storage.getItem(STORAGE_KEY);
    }
    getDefaultFetchOptions(token) {
      // provides basic settings used for `fetch()` requests
      // contains authentication token and all custom headers
      (false && !(typeof token === 'string' && token.length > 0) && Ember.assert('`token` must be a non-empty string!', typeof token === 'string' && token.length > 0));
      return {
        mode: 'cors',
        headers: {
          Authorization: `Basic ${token}`,
          'Client-Version': `${CLIENT_VERSION}`,
          'Cropster-Authenticate': 'suppress-www-authenticate',
          'Content-Type': 'application/vnd.api+json; charset=utf-8'
        }
      };
    }
    encodeToken(username, password) {
      // encodes token as `username:password` in base64 for
      // `Authorization` header for the Basic Authentication
      (false && !(typeof username === 'string' && username.length > 0) && Ember.assert('`username` must be a non-empty string!', typeof username === 'string' && username.length > 0));
      (false && !(typeof password === 'string' && password.length > 0) && Ember.assert('`password` must be a non-empty string!', typeof password === 'string' && password.length > 0));
      return btoa(`${username}:${password}`);
    }
    decodeToken(token) {
      // decodes token as `username:password` from base64 to
      // an array like the following [`username`, `password`]
      (false && !(typeof token === 'string' && token.length > 0) && Ember.assert('`token` must be a non-empty string!', typeof token === 'string' && token.length > 0));
      return atob(token).split(':');
    }
    _handleErrorResponse(_ref) {
      let {
        status,
        statusText
      } = _ref;
      throw new Error(`${status}: ${statusText}`);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "storage", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AuthService;
});