define("cropster-origin-app/models/contact-role", ["exports", "ember-cp-validations", "cropster-origin-app/models/abstract"], function (_exports, _emberCpValidations, _abstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Validations = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({});
  _exports.Validations = Validations;
  class ContactRole extends _abstract.default {}
  _exports.default = ContactRole;
});