define("cropster-origin-app/helpers/is-odd", ["exports", "ember-cropster-common/helpers/is-odd"], function (_exports, _isOdd) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _isOdd.default;
    }
  });
  Object.defineProperty(_exports, "isOdd", {
    enumerable: true,
    get: function () {
      return _isOdd.isOdd;
    }
  });
});