define("cropster-origin-app/services/l10n", ["exports", "ember-l10n/services/l10n"], function (_exports, _l10n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.allLocales = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  /* eslint-disable camelcase */
  const allLocales = {
    en: 'English',
    es: 'Español - Spanish',
    pt: 'Português - Portuguese',
    fr: 'Français - French',
    am: 'አማርኛ - Amharic',
    sw: 'Kiswahili - Swahili'
  };
  /* eslint-enable camelcase */
  _exports.allLocales = allLocales;
  let L10n = (_dec = Ember.computed(''), (_class = class L10n extends _l10n.default {
    get availableLocales() {
      return allLocales;
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "availableLocales", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "availableLocales"), _class.prototype)), _class));
  _exports.default = L10n;
});