define("cropster-origin-app/components/form/measure/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let Measure = (_dec = Ember.computed.or('unit', '_cachedUnit'), _dec2 = Ember.computed.gt('units.length', 0), _dec3 = Ember._action, _dec4 = Ember._action, (_class = class Measure extends Ember.Component {
    constructor() {
      super(...arguments);
      _defineProperty(this, "classNames", ['display-flex', 'width-100p']);
      //
      // Arguments
      //
      _defineProperty(this, "min", 0);
      _defineProperty(this, "step", 'any');
      _defineProperty(this, "max", Number.POSITIVE_INFINITY);
      _defineProperty(this, "units", []);
      _defineProperty(this, "unit", null);
      _defineProperty(this, "value", null);
      _defineProperty(this, "unitKey", 'unit');
      _defineProperty(this, "disabled", false);
      //
      // Properties
      //
      _defineProperty(this, "_cachedUnit", null);
      _initializerDefineProperty(this, "currentUnit", _descriptor, this);
      _initializerDefineProperty(this, "hasUnits", _descriptor2, this);
    }
    onChangeUnit( /* unit */) {}
    onChangeAmount( /* amount */) {}
    onChange( /* measure */) {}
    //
    // Hooks
    //
    init() {
      super.init(...arguments);
      Ember.set(this, '_cachedUnit', this.unit);
    }

    //
    // Methods
    //
    createMeasure_(amount, unit) {
      if (amount === undefined) {
        return null;
      }
      return {
        amount,
        [this.unitKey]: unit
      };
    }

    //
    // Actions
    //
    changeAmountHandler(amount) {
      const {
        currentUnit: unit
      } = this;
      const measure = this.createMeasure_(amount, unit);
      this.onChange(measure);
      this.onChangeAmount(amount);
    }
    changeUnitHandler(unit) {
      Ember.set(this, '_cachedUnit', unit);
      const {
        value: amount
      } = this;
      const measure = this.createMeasure_(amount, unit);
      this.onChange(measure);
      this.onChangeUnit(unit);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUnit", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "hasUnits", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "changeAmountHandler", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "changeAmountHandler"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeUnitHandler", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "changeUnitHandler"), _class.prototype)), _class));
  _exports.default = Measure;
});