define("cropster-origin-app/services/reception-item-factory", ["exports", "cropster-origin-app/services/store", "cropster-origin-app/models/reception", "cropster-origin-app/utils/type-guards"], function (_exports, _store, _reception, _typeGuards) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ReceptionItemFactory = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed.readOnly('originSettings.defaultValues'), (_class = class ReceptionItemFactory extends Ember.Service {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "originSettings", _descriptor2, this);
      _initializerDefineProperty(this, "defaultValues", _descriptor3, this);
    }
    async distributeReceptionWeight(source, reception) {
      (false && !((0, _store.isSource)(source)) && Ember.assert('`source` must be a source instance!', (0, _store.isSource)(source)));
      (false && !(reception instanceof _reception.default) && Ember.assert('`reception` needs to be a record of type `reception`!', reception instanceof _reception.default));
      const {
        defaultBatchDivisions,
        isBatchDividedPerSupplier
      } = this.originSettings;
      if (isBatchDividedPerSupplier && defaultBatchDivisions && defaultBatchDivisions.length > 0) {
        for (let defaultBatchDivision of defaultBatchDivisions) {
          if (defaultBatchDivision.isDividedBySupplier) {
            const part = defaultBatchDivisions.indexOf(defaultBatchDivision) + 1;
            const receptionItems = reception.receptionItems.filter(receptionItem => part === receptionItem.part);
            let batch = reception.batches.find(batch => part === batch.part);
            let totalReceptionWeightAmount = 0;
            let unit = '';
            receptionItems.forEach(receptionItem => {
              if (receptionItem.weight && receptionItem.weight.amount) {
                totalReceptionWeightAmount += receptionItem.weight.amount;
                unit = receptionItem.weight.unit;
              }
            });
            await this.store.replaceAttribute(source, batch, 'receivedWeight', {
              amount: totalReceptionWeightAmount,
              unit: unit
            });
          }
        }
      }
    }
    async buildDefaultReceptionItems(source, selectedContactRole) {
      (false && !((0, _store.isSource)(source)) && Ember.assert('`source` must be a source instance!', (0, _store.isSource)(source)));
      let receptionItems = [];
      const {
        defaultBatchDivisions,
        isBatchDividedPerSupplier,
        defaultCurrencyUnit
      } = this.originSettings;
      if (isBatchDividedPerSupplier && defaultBatchDivisions && defaultBatchDivisions.length > 0) {
        let index = 0;
        for (let defaultBatchDivision of defaultBatchDivisions) {
          if (defaultBatchDivision.isDividedBySupplier) {
            let price = (0, _typeGuards.isMeasure)(defaultBatchDivision.pricePerUnit) ? {
              ...defaultBatchDivision.pricePerUnit
            } : null;
            let receptionItem = await this._buildReceptionItem(source, selectedContactRole, {
              part: index + 1,
              partName: defaultBatchDivision.partName,
              price: price
            });
            receptionItems.push(receptionItem);
          }
          index++;
        }
      }
      let priceAmount = 0;
      if (defaultBatchDivisions && defaultBatchDivisions.length && defaultBatchDivisions[0].pricePerUnit) {
        priceAmount = defaultBatchDivisions[0].pricePerUnit.amount ? defaultBatchDivisions[0].pricePerUnit.amount : 0;
      }
      if (receptionItems.length === 0) {
        let hash = {
          part: 1,
          partName: ''
        };
        if (defaultCurrencyUnit) {
          hash['price'] = {
            amount: priceAmount,
            currency: defaultCurrencyUnit
          };
        }
        let receptionItem = await this._buildReceptionItem(source, selectedContactRole, hash);
        receptionItems.push(receptionItem);
      }
      return receptionItems;
    }
    async _buildReceptionItem(source, selectedContactRole) {
      let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      const {
        defaultReceivedUnit
      } = this.originSettings;
      const {
        part,
        partName,
        price
      } = options;
      return await this.store.addRecord(source, {
        type: 'receptionItem',
        attributes: {
          weight: {
            unit: defaultReceivedUnit
          },
          priceBase: {
            amount: 1,
            unit: defaultReceivedUnit
          },
          part,
          partName,
          price
        },
        relationships: {
          sourceRole: {
            data: selectedContactRole
          }
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "originSettings", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "defaultValues", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ReceptionItemFactory;
});