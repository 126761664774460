define("cropster-origin-app/services/batch", ["exports", "cropster-origin-app/services/origin-settings", "@orbit/memory"], function (_exports, _originSettings, _memory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let BatchService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed.readOnly('originSettings.defaultBatchDivisionType'), _dec4 = Ember.computed.readOnly('originSettings.isBatchDividedPerSupplier'), _dec5 = Ember.computed.equal('defaultBatchDivisionType', _originSettings.DEFAULT_BATCH_DIVISION_AUTO), _dec6 = Ember.computed.equal('defaultBatchDivisionType', _originSettings.DEFAULT_BATCH_DIVISION_PERCENTAGE), (_class = class BatchService extends Ember.Service {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "originSettings", _descriptor2, this);
      _initializerDefineProperty(this, "defaultBatchDivisionType", _descriptor3, this);
      _initializerDefineProperty(this, "isBatchDividedPerSupplier", _descriptor4, this);
      _initializerDefineProperty(this, "isAutomaticDivision", _descriptor5, this);
      _initializerDefineProperty(this, "isPercentDivision", _descriptor6, this);
    }
    /**
     * handleDivision()
     * this code handles the various batch division types upon saving
     * in the case of percent division, the received weight is a percentage with a unit "%"
     * in the case of automatic division, the received weight is not set at all
     * the above rules apply only when the batch is not divided by supplier.
     * @param reception {Model.Reception}
     * @param source {MemorySource}
     * @returns {Promise<void>}
     */
    async handleDivision(reception, source) {
      const {
        batches
      } = reception;
      if (batches.length <= 1) return;
      const {
        isAutomaticDivision,
        isPercentDivision,
        isBatchDividedPerSupplier
      } = this;
      if (!(isAutomaticDivision || isPercentDivision) || isBatchDividedPerSupplier) return;
      const totalReceptionWeight = Ember.get(reception, 'totalWeight');
      const totalReceptionWeightAmount = Ember.get(totalReceptionWeight, 'amount') || 0;
      await Promise.allSettled(batches.map((batch, index) => {
        if (isAutomaticDivision) {
          // in automatic division, the first batch gets all the weight, the rest get 0
          return this._setBatchReceivedWeight(source, batch, index === 0 ? totalReceptionWeightAmount : 0, totalReceptionWeight.unit);
        } else {
          //in percent division, we manually calculate the received weight of a batch based on it's % of the total reception weight
          const batchReceivedWeightAmount = Ember.get(batch, 'receivedWeight.amount');
          const calculatedWeightAmount = totalReceptionWeightAmount * batchReceivedWeightAmount / 100;
          return this._setBatchReceivedWeight(source, batch, calculatedWeightAmount, Ember.get(totalReceptionWeight, 'unit'));
        }
      }));
    }

    /**
     * @param source {MemorySource}
     * @param batch {Model.Batch}
     * @param amount {number}
     * @param unit {string}
     * @private
     */
    _setBatchReceivedWeight(source, batch, amount, unit) {
      return this.store.replaceAttribute(source, batch, 'receivedWeight', {
        amount,
        unit
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "originSettings", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "defaultBatchDivisionType", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isBatchDividedPerSupplier", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isAutomaticDivision", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isPercentDivision", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BatchService;
});