define("cropster-origin-app/orbit/schemas/reception-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    keys: {
      remoteId: {}
    },
    attributes: {
      weight: {
        type: 'measure'
      },
      price: {
        type: 'currency'
      },
      priceBase: {
        type: 'measure'
      },
      part: {
        type: 'string'
      },
      partName: {
        type: 'string'
      }
    },
    relationships: {
      reception: {
        type: 'hasOne',
        model: 'reception',
        inverse: 'receptionItems'
      },
      sourceRole: {
        type: 'hasOne',
        model: 'contactRole'
      },
      source: {
        type: 'hasOne',
        model: 'contact'
      }
    }
  };
  _exports.default = _default;
});