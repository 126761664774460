define("cropster-origin-app/orbit/schemas/permission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    attributes: {
      name: {
        type: 'string'
      }
    },
    relationships: {
      // skipped
    }
  };
  _exports.default = _default;
});