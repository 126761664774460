define("cropster-origin-app/services/active-group", ["exports", "cropster-origin-app/objects/active-entity-service"], function (_exports, _activeEntityService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.EVENT_CHANGED_GROUP = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  const STORAGE_KEY = `__coa_agk__`;
  const EVENT_CHANGED_GROUP = 'active-group:changed';

  /**
   * Stateful service holding information about active group.
   *
   * It perists selected group's ID in local storage / indexed DB.
   *
   * The service sends the following events via `sendEvent()` method:
   *
   * - `active-group:changed`: after setting `selected` property via setter
   */
  _exports.EVENT_CHANGED_GROUP = EVENT_CHANGED_GROUP;
  class ActiveGroupService extends _activeEntityService.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "modelName_", 'group');
      _defineProperty(this, "storageKey_", STORAGE_KEY);
      _defineProperty(this, "eventName_", EVENT_CHANGED_GROUP);
    }
  }
  _exports.default = ActiveGroupService;
});