define("cropster-origin-app/models/process", ["exports", "ember-cp-validations", "cropster-origin-app/models/abstract"], function (_exports, _emberCpValidations, _abstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Validations = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const Validations = (0, _emberCpValidations.buildValidations)({});
  _exports.Validations = Validations;
  let Process = (_dec = Ember.computed.and('receivedForm', 'receivedWeight'), _dec2 = Ember.computed.and('intermediateForm', 'intermediateWeightTarget', 'intermediateWeightSource'), _dec3 = Ember.computed.and('finalForm', 'finalWeight'), _dec4 = Ember.computed('hasReceived', 'receivedForm', 'receivedWeight', 'hasIntermediate', 'intermediateForm', 'intermediateWeightTarget', 'hasFinal', 'finalForm', 'finalWeight'), _dec5 = Ember.computed('hasIntermediate', 'intermediateForm', 'intermediateWeightSource', 'hasFinal', 'finalForm', 'finalWeight'), (_class = class Process extends _abstract.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "hasReceived", _descriptor, this);
      _initializerDefineProperty(this, "hasIntermediate", _descriptor2, this);
      _initializerDefineProperty(this, "hasFinal", _descriptor3, this);
    }
    //
    // will represent one of the following:
    // - received <-> final (2 weighing stages)
    // - received <-> intermediate (3 weighing stages)
    //
    get yieldFactor1() {
      if (this.hasReceived && (this.hasIntermediate || this.hasFinal)) {
        return {
          inputValue: this.receivedForm,
          inputWeight: this.receivedWeight,
          outputValue: this.hasIntermediate ? this.intermediateForm : this.finalForm,
          outputWeight: this.hasIntermediate ? this.intermediateWeightTarget : this.finalWeight
        };
      }
      return null;
    }

    //
    // will represent one of the following:
    // - `null` aka. undefined (2 weighing stages)
    // - intermediate <-> final (3 weighing stages)
    //
    get yieldFactor2() {
      if (this.hasIntermediate && this.hasFinal) {
        return {
          inputValue: this.intermediateForm,
          inputWeight: this.intermediateWeightSource,
          outputValue: this.finalForm,
          outputWeight: this.finalWeight
        };
      }
      return null;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "hasReceived", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "hasIntermediate", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "hasFinal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "yieldFactor1", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "yieldFactor1"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "yieldFactor2", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "yieldFactor2"), _class.prototype)), _class));
  _exports.default = Process;
});